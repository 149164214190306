import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import { addVendorJsonList, setLoading } from "../redux/vendorsJsonSlice";

export const GetVendorJsonList= () => async (dispatch) => {
    dispatch(setLoading(true)); // Start the loader
    let resObj = { error: true, message: "unable to get Vendors Json List" }
    try {
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
        );
        if (refreshToken) {
            await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ALL_VENDORS}`,
                {}
            )
                .then((resChart) => {
                    let data = resChart.data.data;
                    dispatch(addVendorJsonList(data)); // Update Redux state
                    resObj = { error: false, message: null, data };
                })
                .catch((errChart) => {
                    resObj = { error: true, message: errChart.data?.message || errChart.message }
                })
        }
    } catch (error) {
        console.error("Error refreshing token or fetching vendors:", error);
    } finally {
        dispatch(setLoading(false)); // Stop the loader
    }
    return resObj;
}





