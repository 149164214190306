import React from 'react'
import {
  Button,
  Box,
  Modal,
} from '@mui/material';
import './css/questionnaire.css';

function QuestionnairePreview({ onClose, sections, title, description, submit, showSubmit, open, handleClose }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="tabs-bg preview-popup" sx={{ bgcolor: 'background.paper' }} >
        <div className='relative-popup'>
          <div className='preview-page'>Preview</div>
          {showSubmit ?    <div className='preview-submission'>
            <h2>This is a preview of your submission. It has not been submit yet</h2>
            <p>Please take a moment to verify your information. You can also go back to make changes</p>
          </div> :null}
          <div className="title-preview">
            <h2>Title:</h2>
            <p>{title}</p>
          </div>
          <div className="title-preview">
            <h2>Description:</h2>
            <p>{description}</p>
          </div>
          {Array.isArray(sections) ? sections.map((item, i) => {
            return (
              <>
                <div className="title-preview">
                  <h2>Section Name:</h2>
                  {item.category ? <p>{item.category}</p>:<p>{item.title}</p>}
                </div>
                {item.questions ? item.questions?.map((question, j) => {
                  return (
                    <div className="title-preview">
                      <h2>Question no {j + 1}</h2>
                      <div>
                        <p>{question.text}</p>
                        {/* <QuestionCategoryInfo 
                           data={question}
                            /> */}
                        {question.options ? question.options?.map((option, k) => {
                          return (
                            <>
                              <div className="inline-form-check checkbox-outline preview">
                                <label className="custom-checkbox">
                                  {question.optionType === "MCQ" ? <input type="checkbox" id={`${j + 1}-flexCheckDefault${k + 1}`} checked={false} /> :
                                    <input type="radio" id={`${j + 1}-flexRadioDefault${k + 1}`} checked={false} />}
                                  <span className="checkmark"></span>
                                  <span className="assessment-datacheck">{option.title}</span>
                                </label>
                              </div>
                            </>
                          )
                        }) : null}
                      </div>
                    </div>
                  )
                }) : null}
              </>
            )
          }) : null}


          <div className='previous-submit'>
            <Button variant="contained" className='addsection back-to-previous' onClick={handleClose}>
              Back to Previous
            </Button>
            {showSubmit ? <Button variant="contained" className='addsection' onClick={submit}>
              Submit
            </Button> : null}
          </div>
        </div>
      </Box>
    </Modal>

  )
}

export default QuestionnairePreview