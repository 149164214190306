import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Vendors.css";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chart from "react-apexcharts";
import { useSnackbar } from "notistack";
import UpIcon from '../assets/up-icon.svg';
import DownIcon from '../assets/down-arrow.svg';
import DatagridVendor from "./DatagridVendor.js";
import Approval from "./VenderApproval.js";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import GetRequest from "../components/apiConnections/getRequest.js";
import colorsNew from "../utils/colorcode.js";
import { useSelector } from "react-redux";

export default function UserPage() {
  const [value, setValue] = useState(0);  // Tabs sCorrect usage inside the component
  const [thirdPartyRiskLen, setthirdPartyRiskLen] = useState(0);
  const [thirdPartyRiskLoader, setThirdPartyRiskLoader] = useState(false);
  const [thirdPartyRisk, setThirdPartyRisk] = useState([]);
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0]);
  const [assessment25, setAssessment25] = useState(0);
  const [assessment50, setAssessment50] = useState(0);
  const [assessment75, setAssessment75] = useState(0);
  const [assessment100, setAssessment100] = useState(0);
  const [assessmentLoader, setAssessmentLoader] = useState(false);
  const [totalAssessment, setTotalAssessments] = useState(0);
  const [failedControl, setTotalFailedControl] = useState(0);
  const [prevFailedControl, setPrevFailedControl] = useState(0);
  const [totalPendingRemediation, setTotalPendingRemediation] = useState(0);
  const [totalFailedControlLoader, setTotalFailedControlLoader] = useState(false);
  const [totalPendingRemediationLoader, setTotalPendingRemediationLoader] = useState(false);
  let selector = useSelector((state) => state.permissionList.permissionList);

  useEffect(() => {

    if (checkPermission("Ecosystem List") && checkPermission("Approval List")) return setValue(0);
    if (checkPermission("Approval List")) return setValue(1);

  }, [selector])


  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  const handleChange = (event, newValue) => { // Tabs
    console.log("come into this", newValue)
    if (checkPermission("Ecosystem List") && checkPermission("Approval List")) return setValue(newValue);
    if (checkPermission("Approval List")) return setValue(1);
  };

  useEffect(() => {
    getThirdPartyAtRiskData();
    getScoreBasedAssessmentCount();
    getTotalFailedControl();
    getTotalPendingRemediation();
  }, []);

  useEffect(() => {
    getThirdPartyAtRiskData();
  }, []);
  /*First chart*/
  const chartOptions = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: ['Critical', 'High', 'Medium', 'Low'],
    colors: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 30,  // Move the name (category) label down
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff',
              offsetY: -15,

            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => {
                return thirdPartyRiskLen;
              },
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 50, // Also ensure the total label is moved below
            },
          },

        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };

  const options = {
    chart: {
      type: "bar",
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 0,
        left: 0,
        blur: 4,
        opacity: 0.25,
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '15.60px',
        colors: {
          hover: {
            shade: 'light',
            shadeIntensity: 0.5
          }
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [""],
      labels: {
        show: false,
        style: {
          colors: '#000' // Font color for x-axis labels
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
    legend: {
      offsetX: -28,
      position: 'right',
      offsetY: -10,
      height: 155,
      markers: {
        width: 11,
        height: 12,
        shape: 'square',
        radius: 0,
        offsetX: -5,
      },
      labels: {
        colors: '#000' // Font color for legend labels
      }
    },
    tooltip: {
      theme: 'dark', // Use 'dark' theme to start with
      style: {
        fontSize: '10px',
        colors: ['#000'] // Set tooltip text color to black
      }
    }
  };

  const series = [
    {
      name: "0-25",
      data: [assessment25],
    },
    {
      name: "25-50",
      data: [assessment50],
    },
    {
      name: "50-75",
      data: [assessment75],
    },
    {
      name: "75-100",
      data: [assessment100],
    },
  ];
  const dummySeries = [
    {
      name: "0-25",
      data: [10],
    },
    {
      name: "25-50",
      data: [3],
    },
    {
      name: "50-75",
      data: [5],
    },
    {
      name: "75-100",
      data: [7],
    },
  ];
  const nullDataOptions = {
    chart: {
      type: "bar",
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 2,
        left: 0,
        blur: 3,
        opacity: 0.25
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '15.60px',
        colors: {
          hover: {
            shade: '#292154',
            shadeIntensity: 0.5
          }
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [""],
      labels: {
        show: false,
        style: {
          colors: '#292154' // Font color for x-axis labels
        }
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: ["#292154"],
    legend: {
      offsetX: -28,
      position: 'right',
      offsetY: -10,
      height: 155,
      markers: {
        width: 11,
        height: 12,
        shape: 'square',
        radius: 0,
        offsetX: -5,
      },
      labels: {
        colors: '#fff' // Font color for legend labels
      }
    },
    tooltip: {
      theme: '#fff', // Use 'dark' theme to start with
      style: {
        fontSize: '10px',
        colors: ['#000'] // Set tooltip text color to black
      }
    }
  };
  const isChartSeriesNullDonut = chartSeries.every(value => value === 0);
  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut

    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            total: {
              show: true,
              label: 'No Data',
              fontSize: '16px',
              color: '#fff', // Muted color for "No Data" text
              fontFamily: 'Mona-Sans, sans-serif',
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff', // Muted color for total value
              },
              formatter: () => '0', // Show "0" for total when no data
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    }
  });




  
  const getThirdPartyAtRiskData = async () => {
    setThirdPartyRiskLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setThirdPartyRisk(response);
          setthirdPartyRiskLen(response?.length);
          let arr = [
            { vendor: "Critical", score: 0 },
            { vendor: "High", score: 0 },
            { vendor: "Medium", score: 0 },
            { vendor: "Low", score: 0 },
          ];
          if (response.length) {
            for (let item of response) {
              switch (true) {
                case item.score <= 25: arr[0].score++;
                  break;
                case item.score <= 50: arr[1].score++;
                  break;
                case item.score <= 75: arr[2].score++;
                  break;
                case item.score <= 100: arr[3].score++;
                  break;
                default:
                  break;
              }
            }
          }
          console.log("response:",response)
          setChartSeries(arr.map(a => a.score));
          setThirdPartyRiskLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setThirdPartyRiskLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setThirdPartyRiskLoader(false);
    }

  };

  const getScoreBasedAssessmentCount = async () => {
    setAssessmentLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_SCORE_BASED_ASSESSMENT_COUNT;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard?.data?.data;
          setTotalAssessments(response?.total || 0);
          setAssessment25(response?.assessment25 || 0);
          setAssessment50(response?.assessment50 || 0);
          setAssessment75(response?.assessment75 || 0);
          setAssessment100(response?.assessment100 || 0);
          setAssessmentLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setAssessmentLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setAssessmentLoader(false);
    }
  };
  const getTotalFailedControl = async () => {
    setTotalFailedControlLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_TOTAL_FAILED_CONTROLS;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setTotalFailedControl(response?.failedControls ?? 0);
          setPrevFailedControl(response?.prevFailedControl ?? 0)
          setTotalFailedControlLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setTotalFailedControlLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setTotalFailedControlLoader(false);
    }

  };

  const getTotalPendingRemediation = async () => {
    setTotalPendingRemediationLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_TOTAL_PENDING_REMEDIATION;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setTotalPendingRemediation(response);
          
          setTotalPendingRemediationLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setTotalPendingRemediationLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setTotalPendingRemediationLoader(false);
    }
  };

  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Helmet>
        <title> Ecosystem | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Ecosystems" />
        <Grid container spacing={3} sx={{ mb: 3 }} className="ipad-space">
          <Grid item xs={12} md={4} className="left-fit-contain">

            {checkPermission("Total Third Parties") ?
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard donut-dashboard">
                  <h2>Total Third Parties</h2>
                  {!thirdPartyRiskLoader ? (<div className="total-issues-chart">
                    <Chart
                      options={isChartSeriesNullDonut ? getEmptyDonutOptions(chartOptions) : chartOptions}
                      series={isChartSeriesNullDonut ? [0,1,0,0] : chartSeries}
                      type="donut"
                      width="320"
                    />
                  </div>) : (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "23%" }}>
                    <CircularProgress className="loader-custom-class-new" />
                  </Box>
                  )}
                </div>
              </Card>
              :
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard donut-dashboard blur-box-cover-area">
                  <h2>Total Third Parties</h2>
                  <div className="total-issues-chart ">
                    <Chart
                      options={chartOptions}
                      series={[1, 1, 1, 1]}
                      type="donut"
                      width="320"
                    />
                  </div>
                </div>
              </Card>
            }


          </Grid>
          <Grid item xs={12} md={5} className="left-fit-contain">
            {checkPermission("Assessments") ?
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard bar-ecosystem-chart">
                <h2>Assessments</h2>
                  {(!assessmentLoader ? (<div className="total-issues-chart bar-chart-small">
                    <h3>{totalAssessment} <span>Assessments</span></h3>
                    <Chart className="barchart-vendor" options={totalAssessment > 0 ? options : nullDataOptions} series={series} type="bar" width={400} height={175} />
                  </div>) : (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "20%" }}>
                    <CircularProgress className="loader-custom-class-new" />
                  </Box>
                  ))}
                </div>
              </Card>
              :
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard bar-ecosystem-chart blur-box-cover-area">
                  <h2>Assessments</h2>
                  <div className="total-issues-chart bar-chart-small">
                    <h3>{50} <span>Assessments</span></h3>
                    <Chart className="barchart-vendor" options={options} series={dummySeries} type="bar" width={400} height={175} />
                  </div>
                </div>
              </Card>
            }

          </Grid>
          
          <Grid item xs={12} md={3}>
            {checkPermission("Pending Remediation") ?
              <Card sx={{ mt: 0, mb: 3 }}>
                <div className="top-heading-dashboard pending-remediation-h2">
                  <h2>Pending Remediation</h2>
                  {console.log("totalPendingRemediation?.total",(Math.abs(Number(totalPendingRemediation?.total) - Number(totalPendingRemediation.previousTotal)) * 100) / Number(totalPendingRemediation.previousTotal))?.toFixed(2)}
                  <div className="pending-remediation">
                    <p>{totalPendingRemediation?.total || 0}</p>
                    {totalPendingRemediation.previousTotal > 0 &&
                      (totalPendingRemediation?.total !== totalPendingRemediation.previousTotal) ? <span className={totalPendingRemediation?.total < totalPendingRemediation.previousTotal ?"number-down": "number-up"}>
                      <img src={totalPendingRemediation?.total < totalPendingRemediation.previousTotal ? DownIcon : UpIcon} alt="icon" /> {
                        ((Math.abs(Number(totalPendingRemediation?.total) - Number(totalPendingRemediation.previousTotal)) * 100) / Number(totalPendingRemediation.previousTotal))?.toFixed(2)
                      }%
                    </span> : null}
                  </div>
                </div>
              </Card>
              :
              <Card sx={{ mt: 0, mb: 3 }}>
                <div className="top-heading-dashboard pending-remediation-h2 blur-box-cover-area assessments-blur-images">
                  <h2>Pending Remediation</h2>
                  <div className="pending-remediation">
                    <p>{20}</p>
                    <span className="number-down">
                      <img src={DownIcon} alt="icon" /> {
                        ((Math.abs(10) * 100) / Number(50))?.toFixed(2)
                      }%
                    </span>
                  </div>
                </div>
              </Card>
            }

            {checkPermission("Failed Controls") ?
              <Card>
                <div className="top-heading-dashboard pending-remediation-h2">
                  <h2>Failed Controls</h2>
                  <div className="pending-remediation">
                    <p>{failedControl || 0}</p>
                    {typeof failedControl == "number" && prevFailedControl > 0 && (failedControl !== prevFailedControl) ? <span className={failedControl > prevFailedControl ? "number-up" : "number-down"}>
                      <img src={failedControl > prevFailedControl ? UpIcon : DownIcon} alt="icon" />{
                        ((Math.abs(Number(failedControl) - Number(prevFailedControl)) * 100) / Number(prevFailedControl))?.toFixed(2)
                      }%
                    </span> : null}
                  </div>
                </div>
              </Card>
              :
              <Card>
                <div className="top-heading-dashboard pending-remediation-h2 blur-box-cover-area assessments-blur-images">
                  <h2>Failed Controls</h2>
                  <div className="pending-remediation ">
                    <p>{20}</p>
                    <span className="number-up">
                      <img src={UpIcon} alt="icon" /> {
                        ((Math.abs(10) * 100) / Number(50))?.toFixed(2)
                      }%
                    </span>
                  </div>
                </div>
              </Card>
            }

          </Grid>
          {(checkPermission("Ecosystem List") || checkPermission("Approval List")) ?
            <Grid item xs={12} md={12} lg={12}>
              <Card className="full-coverdesign vendor-table-card margin-ecosystem">
                {/*New Table with tabs*/}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} className="tab-main-heading" aria-label="basic tabs example">
                    {checkPermission("Ecosystem List") && <Tab className="active-color-remove" label="Ecosystem" />}
                    {checkPermission("Approval List") && <Tab className="active-color-remove" label="Approval" />}

                  </Tabs>
                  <Box>

                    {checkPermission("Ecosystem List") && value === 0 &&
                      <div>
                        <DatagridVendor />
                      </div>
                    }

                    {checkPermission("Approval List") && value === 1 &&
                      <div>
                        <Approval />
                      </div>
                    }

                  </Box>
                </Box>

              </Card>
            </Grid>
            : null}
        </Grid>
      </Container >
    </>
  );
}
