import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import Breadcrumb from "../pages/VendorDetail/Breadcrumb.js";
import {
  Grid,
  Container,
  Card,
  Box,
} from "@mui/material";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import moment from "moment";
import Loader from "../Loader";
import "./css/Vendors.css";
// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const data = useParams();
  const [domainName, setDomainName] = useState("");
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);

  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data?.data?.attackInfo);
            setDomainName(res.data.data?.attackInfo?.domain || "");
            // setDomainIssues(res.data.data?.attackInfo.Issues);
            setshowLoader(false);

            let tempIssues = res.data.data?.attackInfo?.Issues;
            for (let index = 0; index < tempIssues.length; index++) {
              const element = tempIssues[index];
              if (element.Target === data.subdomain) {
                setDomainIssues(element?.Issue);
              }
            }
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const breadcrumbs = [
    { label: "Attack Surface", path: `/${roleId == 2 ? "admin" : "dashboard"}/attacks` },
    { label: "Assets", path: `/${roleId == 2 ? "admin" : "dashboard"}/attack-detail/${data.url}`,mainTabValue:2},
    { label: "Issues" },

  ];
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Issues" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
              </div>
            </div>
          </Grid>
          {showLoader === true ? (
            <Loader show={showLoader} />
          ) : domainName !== "" && domainName ? (
            <Grid item xs={12} md={12} lg={12} sx={{p:0}}>
              <Box sx={{ width: "100%",}}>
                {domainIssues?.map((item, index) => (
                  <a
                    href={`/${
                      roleId == 2 ? "admin" : "dashboard"
                    }/sub-domain-issues/${data.url}/${data.subdomain}/${
                      domainIssues[index].Name
                    }`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: "grey",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="Assessment-box-attack subdomain">
                      <div className="box-Assessment-attack">
                        <div className="domain-head">
                          <h4 className="right-line-middle">{domainIssues[index].Name}</h4>
                          <p className="flex-aligns right-line-middle">
                            {domainIssues[index].Severity === "CRITICAL" ? (
                              <span className="alert-Critical critical-color right-line-middle">
                                {/* <WarningAmberIcon fontSize="small" /> */}
                                {domainIssues[index].Severity}
                              </span>
                            ) : domainIssues[index].Severity === "HIGH" ? (
                              <span className="alert-Critical high-color right-line-middle">
                                {/* <ShowChartIcon fontSize="small" /> */}
                                {domainIssues[index].Severity}
                              </span>
                            ) : domainIssues[index].Severity === "MEDIUM" ? (
                              <span className="alert-Critical medium-color right-line-middle">
                                {/* <TrackChangesIcon fontSize="small" /> */}
                                {domainIssues[index].Severity}
                              </span>
                            ) : (
                              <span className="alert-Critical success-critical right-line-middle">
                                {/* <ThumbDownOffAltIcon IconfontSize="small" /> */}
                                {domainIssues[index].Severity}
                              </span>
                            )}
                            {/* <span className="link-l">{domainIssues[index].Reference[0]}</span> */}
                            <span className="link-l">{data.subdomain}</span>
                          </p>
                          <p>
                            Discovered{" "}
                            {moment(
                              new Date(domainIssues[index].time * 1000)
                            ).format("MM/DD/YYYY")}
                          </p>
                        </div>
                        <Grid item xs={12}>
                          <p className="labm0">{domainIssues[index].Info}</p>
                        </Grid>
                      </div>
                    </div>
                  </a>
                ))}
              </Box>
            </Grid>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Grid>
                <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                  <h4>No Record for this url</h4>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}
