import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import CircularProgressSimple from "./CircularProgressSimple.js";
import CircularProgressDividedIcon from "./CircularProgressDividedIcon.js";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
// import CircularProgress from '@mui/material/CircularProgress';
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
  AppOverallAttackScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Attack.css";
import "./css/Vendors.css";
import SubDomain from "../assets/total-subdomain.svg";
import ipv4 from "../assets/total-pv.svg";
import jwt_decode from "jwt-decode";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import { Route, Link, BrowserRouter } from "react-router-dom";

// ----------------------------------------------------------------------
export default function ExportDetail() {
  let theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [uploadTime, setUploadTime] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);
  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");
  const [attackScoreInfo, setAttackScoreInfo] = useState("");
  const [attackSeverityInfo, setAttackSeverityInfo] = useState("");
  const [attackCountInfo, setAttackCountInfoInfo] = useState("");
  const [securityGrade, setSecurityGrade] = useState(0);
  const [securityGradeTitle, setSecurityGradeTitle] = useState("");

  useEffect(() => {
    getVendorDetails();
    setTimeout(() => {
      pdfCreate();
    }, 1500);
  }, []);

  let token = !localStorage.getItem("R1")
    ? localStorage.getItem("R2")
    : localStorage.getItem("R1");
  let tokenData = JSON.parse(token).accessToken;
  let userData = jwt_decode(tokenData);
  let clientName = userData.firstName + " " + userData.lastName;
  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
        roleId: roleId,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            setUploadTime(res.data.data?.attackInfo.time);
            setDomainIssues(res.data.data?.attackInfo.Issues);
            console.log("res.data.data?.attackInfo.Issues" , res.data.data?.attackInfo.Issues)
            setAttackScoreInfo(res.data.data?.attackInfo.score);
            setAttackSeverityInfo(res.data.data?.attackInfo.severity);
            setAttackCountInfoInfo(res.data.data?.attackInfo.count[0]);
            setSecurityGrade(res.data.data.attackInfo.score[0].Overall_score);
            setSecurityGradeTitle(res.data.data.attackInfo.score[0].Grade);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });

        let endpoint = "attack-details-day-wise";
        const daysPayload = {
          selectedDays: 30,
          domainName: data.url,
        };
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          daysPayload
        )
          .then((res) => {
            let date = [];
            let issuesData = [];
            res.data.data.map((item, i) => {
              let element = item.attackInfo;

              date.push(
                moment(new Date(element.time * 1000)).format("MM/DD/YYYY")
              );
              issuesData.push(element.count.total_issue);
            });
            setGraphDates(date);
            setGraphValues(issuesData);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const [value, setValue] = React.useState("1");
  const [graphDates, setGraphDates] = useState(["02/28/2023"]);
  const [graphValues, setGraphValues] = useState([36]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pdfCreate = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      document.body.appendChild(canvas);
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 195;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 5, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("genesis.pdf");
      window.close();
    });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} sx={{ p: 5 }} className="bgprint">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Assets</span>
        </Typography> */}
        {/* <Grid
          item
          xs={6}
          sm={3}
          md={3}
          lg={3}
          sx={{ mt: 2 }}
          className="btn-right"
        >
          <Button
            variant="contained"
            className="addnew send-assessmen"
            onClick={() => pdfCreate()}
          >
            Download
          </Button>
        </Grid> */}
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : domainName !== "" && domainName ? (
          <div id="capture">
            <h1 className="print-text heading-main">
              Detail Report for Attack Surface
            </h1>
            <div className="line-hr"></div>
            <Grid item xs={12} md={12} lg={12} sx={{ p: "5px" }}>
              <div className="d-flexprint-text">
                <h6 className="print-text">
                  Domain : <span>{domainName}</span>
                </h6>
                <h6 className="print-text">
                  Name : <span> {clientName}</span>
                </h6>
                <h6 className="print-text">
                  Date :{" "}
                  <span>
                    {moment(new Date(uploadTime * 1000)).format("MMMM Do YYYY")}
                  </span>
                </h6>
                
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <h3 className="mt-24 security-posture">Security Posture</h3>
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                className="flx-property AttackScore export-ex"
              >
                <AppOverallAttackScore
                  title="Issue over time"
                  style={{}}
                  riskScore="50"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                  className="flx-property export-ex">
                   <Card sx={{ mb: 1 }}>
                    <div className="top-heading-dashboard fullcoverheight">
                     <h2>Security Grade</h2>
                     <div className="total-issues-chart box-remove-background">
                     <CircularProgressSimple
                    percent={
                      securityGradeTitle == "D"
                        ? 22
                        : securityGradeTitle == "C"
                        ? 44
                        : securityGradeTitle == "B"
                        ? 70
                        : 100
                    }
                    title={securityGradeTitle}
                    />
                    </div>
                    </div>
                    </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                className="flx-property export-ex"
              >
                <div className="SubDomains">
                  <Card style={{ marginBottom: "22px" }}>
                    <div className="top-heading-dashboard">
                      <h2>Total Subdomains</h2>
                    </div>
                    <div className="sub-saoce-between">
                      <p>
                        <img
                          src={SubDomain}
                          width={35}
                          height={35}
                          alt="icon"
                        />
                        {attackCountInfo?.total_subdomains}
                      </p>
                    </div>
                  </Card>
                  <Card>
                    <div className="top-heading-dashboard">
                      <h2>Total IPv4</h2>
                    </div>
                    <div className="sub-saoce-between">
                      <p>
                        <img src={ipv4} width={35} height={35} alt="icon" />
                        <span>{attackCountInfo?.total_ip}</span>
                      </p>
                    </div>
                  </Card>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <h2 className="mt-24 security-posture">Security Monitor</h2>
            </Grid>
            <Grid container spacing={3}  className="export-ex">
              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Dns}
                  title={`DNS`}
                />
              </Grid>
                  {console.log("attackScoreInfo" , attackScoreInfo)}
              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Application}
                  title={`Misconfiguration`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Encryption}
                  title={`Encryption`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Breaches}
                  title={`Breach`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Network}
                  title={`Network`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Leak}
                  title={`Leak`}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <h2 className="mt-24 security-posture">
                Details
              </h2>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className="flx-property export-ex">
              {/* <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_issue}</h3>
                      <p>Issues</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box">
                    <div>
                      <h3>{attackCountInfo.total_cve}</h3>
                      <p>CVE</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_ip}</h3>
                      <p>Open Ports</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_breaches}</h3>
                      <p>Breaches</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_subdomains}</h3>
                      <p> Total SubDomains</p>
                    </div>
                  </div>
                </Grid>
              </Grid> */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                  <div className="attack-surface-detail-issue">
                    <div>
                      <h3>Issues</h3>
                      <p>{attackCountInfo.total_issue}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className="attack-surface-detail-issue">
                    <div>
                      <h3>CVE</h3>
                      <p>{attackCountInfo.total_cve}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className="attack-surface-detail-issue">
                    <div>
                      <h3>Open Ports</h3>
                      <p>{attackCountInfo.total_ip}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <div className="attack-surface-detail-issue">
                    <div>
                      <h3>Breaches</h3>
                      <p>{attackCountInfo.total_breaches}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              {/* evidence start */}
              {Object.entries(domainIssues[0].Issue).map((key, val) => (
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >

                 <Grid item xs={12} md={12} lg={12} className="affecteds-part-pdf">
                       <div className="assets-flex">
                        <h3>Affected Assets:</h3>
                        <span className="target-color">
                        {domainIssues[0].Target}
                        </span>
                      </div>
                      <div className="assets-flex">
                       <h3>Company:</h3>
                       <span className="target-color">{data.url}</span>
                      </div>
                      <div>
                      {key[1].Severity === "CRITICAL" ? (
                        <span className="alert-Critical">
                          {key[1].Severity}
                        </span>
                      ) : key[1].Severity === "HIGH" ? (
                        <span className="alert-Critical high-color">
                          {key[1].Severity}
                        </span>
                      ) : key[1].Severity === "MEDIUM" ? (
                        <span className="alert-Critical medium-color">
                          {key[1].Severity}
                        </span>
                      ) : (
                        <span className="alert-Critical success-critical">
                          {key[1].Severity}
                        </span>
                      )}
                      </div>
                  </Grid>
                  
                    <Grid item xs={12} className="issueskey">
                      <Grid item xs={12}>
                        <h4 className="cve-number-first">{`Issue ${val + 1} : ` + key[1].Name}</h4>
                      </Grid>
                      <Grid item xs={12} className="discription-detailexport">
                        <h4>Description</h4>
                        <p className="issues-peraa">{key[1].Info}</p>
                      </Grid>
                      <Grid item xs={12} className="discription-detailexport">
                        <h4>Reference</h4>
                        {key[1].Reference.map((item, index) => (
                          <p className="dark-change-bg">
                            <a
                              href={`${key[1].Reference[index]}`}
                              style={{
                                color: "#000",
                                display: "block",
                                marginBottom: "0px",
                                paddingLeft:"20px",
                                width: "fit-content",
                              }}
                              target="_blank"
                            >
                              {key[1].Reference[index]}
                            </a>
                          </p>
                        ))}
                      </Grid>
                      <Grid item xs={12} className="discription-detailexport">
                        <h4>Evidence</h4>
                        <pre data-lang="scss" className="code-left-mid-manage">
                          <code>{key[1].Evidence.join("\n")} </code>
                        </pre>
                      </Grid>
    
                  </Grid>
                </div>
                ))}
             </Grid>
            </div>
        ) : null}
      </Container>
    </>
  );
}
