import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import defaultLogo from '../assets/companyLogo.jpg';
import {
  Grid,
  Container,
  Card,
  Stack,
  Box,
  Button,
  IconButton, FormControl
} from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import CircularProgress from "./CircularProgress.js";
import CircularProgressSimple from "./CircularProgressSimple.js";
import CircularProgressDividedIcon from "./CircularProgressDividedIcon.js";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import Breadcrumb from "../pages/VendorDetail/Breadcrumb.js";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import EyeMenu from "../assets/eye-menu.svg";
// sections
import {
  AppWebsiteVisits,
  AppOverallAttackScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Attack.css";
import "./css/Vendors.css";
import SubDomain from "../assets/total-subdomain.svg";
import ipv4 from "../assets/total-pv.svg";
import jwt_decode from "jwt-decode";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { CustomNoRowsOverlay } from './Assessment.js';
function DataGridLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const data = useParams();
  const navigate = useNavigate();
   const [domainName, setDomainName] = useState("");
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);

  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");
  const [attackScoreInfo, setAttackScoreInfo] = useState("");
  const [attackSeverityInfo, setAttackSeverityInfo] = useState("");
  // const [attackSeverityLength, setAttackSeverityLength] = useState(0);

  const [attackCountInfo, setAttackCountInfoInfo] = useState("");
  const [securityGrade, setSecurityGrade] = useState(0);
  const [securityGradeTitle, setSecurityGradeTitle] = useState("");
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [valueForBreadcrub, setValueForBreadcrub] = useState("1");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [mainTabValue, setValue] = React.useState("1");
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([36]);
  const [option, setOption] = useState(365);
  const [graphLoader, setGraphLoader] = useState(false);
  
  useEffect(() => {
    getVendorDetails();
    getGraphData();
  }, []);

  useEffect(() => {
    getGraphData();
  }, [option]);

  let token = !localStorage.getItem("R1")
    ? localStorage.getItem("R2")
    : localStorage.getItem("R1");
  let tokenData = JSON.parse(token).accessToken;
  let userData = jwt_decode(tokenData);
  let clientName = userData.firstName + " " + userData.lastName;
  let attackSeverityLength = 0;
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
        roleId: roleId,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            setDomainIssues(res.data.data?.attackInfo?.Issues);
            setAttackScoreInfo(res.data.data?.attackInfo.score);
            setAttackSeverityInfo(res.data.data?.attackInfo.severity);
            setAttackCountInfoInfo(res.data.data?.attackInfo.count[0]);
            setSecurityGrade(res.data.data.attackInfo.score[0].Overall_score);
            setSecurityGradeTitle(res.data.data.attackInfo.score[0].Grade);
            setLogo(
              res.data.data.attackInfo.logo !== "" ||
                res.data.data.attackInfo.logo != null
                ? res.data.data.attackInfo.logo
                : logo
            );
            setRows(res.data.data?.attackInfo?.Issues?.map((d, i) => ({
              ...d,
              id: i + 1,
              Target: d.Target,
              Issue: d.Issue?.length || 0,
            })));
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getGraphData = () => {
    setGraphLoader(true);
    let endpoint = "attack-details-day-wise";
    const daysPayload = {
      selectedDays: option,
      domainName: data.url,
    };
    PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
      daysPayload
    )
      .then((res) => {
        let date = [];
        let issuesData = [];
        res.data.data.map((item, i) => {
          let element = item.attackInfo;
          date.push(
            moment(new Date(element.time * 1000)).format("MMM-DD")
          );
          issuesData.push(element.count[0].total_issue);
        });        
        setGraphDates(date.reverse());
        setGraphValues(issuesData.reverse());
        setGraphLoader(false);
      })
      .catch((err) => {
        // no data
        enqueueSnackbar("Requested Url not find", { variant: "error" });
        setGraphLoader(false);
        return false;
        
      });
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValueForBreadcrub(newValue)
    console.log(valueForBreadcrub)
  };

  const breadcrumbs = [
    { label: "Attack Surface", path: `/${roleId == 2 ? "admin" : "dashboard"}/attacks` },
    { label: "Attack Surface Details" },
  ];
  const breadcrumbsForAsset = [
    { label: "Attack Surface", path: `/${roleId == 2 ? "admin" : "dashboard"}/attacks` },
    { label: "Assets" },
  ];

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "Target",
      headerName: "Domain Name",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => <>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <span className="logo-image-vendor">
            <img className="attack-surface-list-logo" src={`${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${params?.value}`} alt="Img" onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultLogo;
            }} /></span>
          <span>{params.value}</span>
        </div>
      </>,
    },
    {
      field: "Issue",
      headerName: "Issue",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: "",
      disableColumnMenu: true,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return <RowMenu
          data={params.row}
          handleView={() => {
            handleRowClick(params)
          }}
        />
      }
    },
  ];


  const handleRowClick = (params) => {
    if(params?.row?.Target){
      navigate(`/${roleId == 2 ? "admin" : "dashboard"}/sub-domain-issues/${data?.url}/${params?.row?.Target}`)
    }
  };

  const totalIssuesChange = async (event) => {
    setOption(event.target.value);
  };
  function RowMenu({ data, handleRemove, handleView, handleFillDetails }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };
    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
        </Menu >
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title> Attack Surface | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Attack Surface Detail" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                {valueForBreadcrub === "1" ? <Breadcrumb crumbs={breadcrumbs} />
                  : <Breadcrumb crumbs={breadcrumbsForAsset} />}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {showLoader === true ? (
              <Loader show={showLoader} />
            ) : domainName !== "" && domainName ? (
              <TabContext value={mainTabValue}>
                <Box className="first_tab">
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    className="tab-main-heading margin-mui-tabfix"
                    aria-label="lab API tabs example"
                    sx={{ mt: 0 }}
                  >
                    <Tab
                      label="Attack Surface"
                      value="1"
                      className="active-color-remove second-mui"
                    />
                    <Tab
                      label="Assets"
                      value="2"
                      className="active-color-remove second-mui"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="vendor-info-text-left">
                    <h3>Vendor Info</h3>
                    <div className="inbutton">
                      <Link
                        to={`../export-executive/${data.url}`}
                        className="right-15"
                      >
                        <Button
                          variant="contained"
                          className="addnew send-assessment Executive"
                        >
                          Executive Report
                        </Button>
                      </Link>

                      <Link to={`../export-detail/${data.url}`}>
                        <Button
                          variant="contained"
                          className="addnew send-assessment Executive"
                        >
                          Detailed Report
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <Grid
                    container
                    spacing={1}
                    pb={3}
                    className="bg-attack-surface-cover"
                  >
                    <Grid item xs={12} sm={1} md={1} lg={1}>
                      <div
                        className="infor-vendor-detail-box"
                        style={{ padding: "0px" }}
                      >
                        <div className="logo-sign">
                          <img src={logo} alt="login" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={11} md={11} lg={11}>
                      <div className="domain-part">
                        <h3 className="domain-name">{domainName}</h3>
                        <p className="add-top">{attackInfoData.IP}</p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      className="flx-property AttackScore"
                    >
                      <AppOverallAttackScore
                        title="Score over time"
                      />
                      {/* <NewOverall /> */}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="flx-property">
                      <Card sx={{ mb: 1 }}>
                        <div className="top-heading-dashboard fullcoverheight">
                          <h2>Security Grade</h2>
                          <div className="total-issues-chart box-remove-background">
                            <CircularProgressSimple
                              percent={
                                securityGradeTitle == "D"
                                  ? 22
                                  : securityGradeTitle == "C"
                                    ? 44
                                    : securityGradeTitle == "B"
                                      ? 70
                                      : 100
                              }
                              title={securityGradeTitle}
                            />
                          </div>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="flx-property">
                      <div className="SubDomains">
                        <Card style={{ marginBottom: "22px" }}>
                          <div className="top-heading-dashboard">
                            <h2>Total Subdomains</h2>
                          </div>
                          <div className="sub-saoce-between">
                            <p>
                              <img src={SubDomain} alt="icon" />
                              {attackCountInfo?.total_subdomains}
                            </p>
                          </div>
                        </Card>
                        <Card>
                          <div className="top-heading-dashboard">
                            <h2>Total IPv4</h2>
                          </div>
                          <div className="sub-saoce-between">
                            <p>
                              <img src={ipv4} alt="icon" />
                              <span>{attackCountInfo?.total_ip}</span>
                            </p>
                          </div>
                        </Card>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} className="flx-property">
                      <Card sx={{ mb: 1 }}>
                        <div className="top-heading-dashboard">
                          <h2>Issue Severity</h2>
                        </div>
                        {Object.entries(attackSeverityInfo[0]).map(
                          (key, val) => {
                            attackSeverityLength =
                              attackSeverityLength + key[1];
                          }
                        )}
                        <Stack
                          sx={{ width: "90.5%" }}
                          spacing={2}
                          className="linearprocess"
                        >
                          <p className="linearprocess-peragraph">
                            Critical{" "}
                            <span>
                              {" "}
                              ( {attackSeverityInfo[0].Critical}/
                              {attackSeverityLength} )
                            </span>
                          </p>
                          <LinearProgress
                            sx={{
                              backgroundColor: "#D9D9D9",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#FF0000",
                              },
                            }}
                            variant="determinate"
                            className="VolumeBar"
                            value={
                              (attackSeverityInfo[0].Critical /
                                attackSeverityLength) *
                              100
                            }
                          />
                          <p className="linearprocess-peragraph">
                            High{" "}
                            <span>
                              ( {attackSeverityInfo[0].High}/
                              {attackSeverityLength} )
                            </span>
                          </p>
                          <LinearProgress
                            sx={{
                              backgroundColor: "#D9D9D9",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#FFA500",
                              },
                            }}
                            variant="determinate"
                            className="VolumeBar"
                            value={
                              (attackSeverityInfo[0].High /
                                attackSeverityLength) *
                              100
                            }
                          />
                          <p className="linearprocess-peragraph">
                            Medium{" "}
                            <span>
                              ( {attackSeverityInfo[0].Medium}/
                              {attackSeverityLength} )
                            </span>
                          </p>
                          <LinearProgress
                            sx={{
                              backgroundColor: "#D9D9D9",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#FFD700",
                              },
                            }}
                            variant="determinate"
                            className="VolumeBar"
                            value={
                              (attackSeverityInfo[0].Medium /
                                attackSeverityLength) *
                              100
                            }
                          />
                          <p className="linearprocess-peragraph">
                            Low{" "}
                            <span>
                              ( {attackSeverityInfo[0].Low}/
                              {attackSeverityLength} )
                            </span>
                          </p>
                          <LinearProgress
                            sx={{
                              backgroundColor: "#D9D9D9",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#008000",
                              },
                            }}
                            variant="determinate"
                            className="VolumeBar"
                            value={
                              (attackSeverityInfo[0].Low /
                                attackSeverityLength) *
                              100
                            }
                          />
                        </Stack>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                      <Card>
                        <div className="top-heading-dashboard">
                          <h2>Total Issue</h2>
                        </div>
                        <AppWebsiteVisits
                          title="Total Issues"
                          // subheader="Last 30 Days"
                          className="attack-surface-issue-total"
                          chartLabels={graphDates}
                          loading={graphLoader}
                          chartData={[
                            {
                              name: "Total Issues",
                              type: "area",
                              fill: "gradient",
                              data: graphValues,
                            },
                          ]}
                          totalIssuesChange={totalIssuesChange}
                          option={option}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className="flx-property">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={3}>
                          <div className="attack-surface-detail-issue">
                            <div>
                              <h3>Issues</h3>
                              <p>{attackCountInfo.total_issue}</p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <div className="attack-surface-detail-issue">
                            <div>
                              <h3>CVE</h3>
                              <p>{attackCountInfo.total_cve}</p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <div className="attack-surface-detail-issue">
                            <div>
                              <h3>Open Ports</h3>
                              <p>{attackCountInfo.total_ip}</p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                          <div className="attack-surface-detail-issue">
                            <div>
                              <h3>Breaches</h3>
                              <p>{attackCountInfo.total_breaches}</p>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <h2 className="security-text Security-Monitor">
                        Security Monitor
                      </h2>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Dns}
                            title={`DNS`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Application}
                            title={`Misconfiguration`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Encryption}
                            title={`Encryption`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Breaches}
                            title={`Breach`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Network}
                            title={`Network`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} className="flex-align">
                          <CircularProgressDividedIcon
                            percent={attackScoreInfo[0].Leak}
                            title={`Leak`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="2">
                  {/* <Box
                    sx={{ width: "100%", typography: "body1" }}
                    className="ipad-space"
                  >
                    <h2 className="heading-main" style={{ textAlign: "left" }}>
                      Sub-Domain
                    </h2>
                    <TableContainer
                      component={Paper}
                      sx={{ p: 1 }}
                      className="vendordetail"
                    >
                      <Table
                        sx={{ minWidth: 100 }}
                        aria-label="simple table"
                        className="table-attacks"
                      >
                        <TableHead>
                          <CardHeader title={""} subheader={""} />
                          <TableRow>
                            <TableCell>Domain Name</TableCell>
                            <TableCell>Issues</TableCell>
                            <TableCell>View</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {domainIssues.map((row) => (
                            <TableRow
                              key={row.category}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row.Target}</TableCell>
                              <TableCell>{row.Issue.length}</TableCell>
                              <TableCell>
                                <a
                                  href={`/${
                                    roleId == 2 ? "admin" : "dashboard"
                                  }/sub-domain-issues/${data.url}/${
                                    row.Target
                                  }`}
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    backgroundColor: "grey",
                                    padding: "3px 5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  View
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box> */}
                  <h2 className="subdomain-heading">Sub Domain</h2>
                  <div className="cover-table">
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "6px" }}>
                      <FormControl
                        className="select-entries"
                        variant="outlined"
                        size="small"
                      >
                        <Select
                          value={paginationModel.pageSize}
                          onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                        </Select>
                      </FormControl>
                      <span className="entries-per">entries per page</span>
                    </div>
                    <DataGrid
                      loading={showLoader}
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      rowHeight={70}
                      paginationMode="client"
                      pageSizeOptions={[5, 10, 25]}
                      onPaginationModelChange={handlePaginationModelChange}
                      paginationModel={paginationModel}
                      slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: DataGridLoader,
                      }}
                      disableColumnMenu
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableSelectionOnClick
                      className="datagrid-table click-hover-datagrid"
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          minWidth: "100%", // Column headers take up full width
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          minWidth: "100%", // Rows take up full width
                        },
                      }}
                      onRowClick={handleRowClick}
                    />
                  </div>
                </TabPanel>
              </TabContext>
            ) : (
              <Box sx={{ display: "flex" }}>
                <Grid>
                  <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                    <h4>No Record for this url</h4>
                  </Card>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
