import { TextareaAutosize } from "@mui/base";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Drawer,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SendPlane from "../../src/assets/send-plane-line.svg";
import ChatIcon from "../../src/assets/chat-icon.svg";
import ViewEvidence from "../../src/assets/view-evidence.svg.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import "./css/questionnaire.css";
import "./css/viewAnswerPage.css";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import BackArrow from '../assets/back-arrow.svg';
import MappedIssue from "./MappedIssue.js";
import FilterIco from '../assets/filter-icon.svg';
import GetRequest from "../components/apiConnections/getRequest.js";
import IconAlert from '../assets/icon-alert.svg';
import AiEvidence from '../pages/GenerateAIEvidence.js'
import ViewAnswerSidebar from "./ViewAnswerSidebar.js";
import AILoader from "../AILoader.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  height: "80vh",
  overflowY: "auto",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ViewAnswerPage({parentValue , categoryValue} ) {
  const [value, setValue] = React.useState(categoryValue || 0 );
  const data = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [receiverEmail , setReceiverEmail] = useState() ; 
  // const{locationFrom:locationFrom}=location.state;
  function checkLocalStorage(){
    if(location.state){
      const { email: receiverEmail} = location.state;
      setReceiverEmail(receiverEmail)
    }else{
      navigate("/dashboard/app");
    }
  }
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);											   
  const [showLoader, setshowLoader] = useState(false);
  const [AIshowLoader, setshowAILoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([]);
  const [assessmentId, setAssessmentId] = useState(data.id);
  // const [questionnaire, setQuestionnaire] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [answered, setAnswered] = useState(0);
  // const [selectCategory, setSelectCategory] = useState(0);
  const [answersToBeSubmit, setAnswersToBeSubmit] = useState();
  const [ques, setQues] = useState({});
  // const [questionnaireSet, setQuestionnaireSet] = React.useState("");
  const [lastClickedIndex, setLastClickedIndex] = useState(null);
  
  
  const theme = useTheme();
  const [domain, setDomain] = useState("");
  const [modelOpen, setModelOpen] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({index:-1});
  const [issues, setIssues] = useState([]);
  const toggleDrawer = ({index}) => () => {
    setOpenDrawer({index});
  };
  
  const [mappedIssue, setMappedIssue] = useState(null);
  const [evidence, setEvidence]=  useState(null);
  const [mappingData, setMappingData] = useState({
    iso27001Control: [],
    gdpr: [],
    nist: [],
  })
  useEffect(() => {
    getQuestionnaire(); 
    checkLocalStorage();
    getMappingDataAPI();
  }, []);

  useEffect(() => {
    if(domain != "")
    getVendorIssue(); 
  }, [domain]);

  const handleSendMessage = async (content, index) => {
    const payload = {
      receiver: receiverEmail,
      message: content,
      time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_MESSAGES}`,
          payload
        )
          .then((res) => {
            setLastClickedIndex(index);
            enqueueSnackbar(`Message Sent!! Redirecting To Chat`, {
              variant: "success",
            });
            setshowLoader(true);
            setTimeout(() => {
              setshowLoader(false);
              setLastClickedIndex(null);
              navigate("/dashboard/clientChat", { state: { receiverEmail } });
            }, 1000);
            // const response = res.data.data;
            // setDomain(response.domain); // Extract and set the domain
          })
          .catch((err) => {
            console.error("Error in Post API call", err);
          });
      } else {
        console.log("Refresh token is not available");
        window.location.href = "/";
      }
    } catch (error) {
      console.error("General error", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const readRadioButton = (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].ans = ans;
    setAnswersToBeSubmit(answersData);
  };

  const questionnaireAnswerConfig = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    if (answersData[category][index].ans == "")
      answersData[category][index].ans = [ans];
    else {
      let answerArr = [];
      if (answersData[category][index].ans?.includes(ans)) {
        answerArr = answersData[category][index].ans?.filter((d) => d != ans);
        answersData[category][index].ans = answerArr;
      } else {
        answersData[category][index].ans.push(ans);
      }
    }
    setAnswersToBeSubmit({ ...answersData });
  };

  const readRemark = (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].remark = ans;
    setAnswersToBeSubmit(answersData);
  };

  const do_saved = async (type) => {
    setshowLoader(true);
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: type,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        if (resultData && resultData.status === 200) {
          enqueueSnackbar("Answer submitted successfully.", {
            variant: "success",
          });
          setshowLoader(false);
          navigate("/vendor/vendorAssessment", { replace: true });
        } else {
          enqueueSnackbar("Answer not submitted.", { variant: "error" });
          setshowLoader(false);
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Answer not submitted.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const getQuestionnaire = async () => {
    setshowLoader(true);
    const payload = {
      assessment_id: assessmentId,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpoint = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_VENDOR;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            setDomain(res.data.data.domain);
            if (
              response.setType == "custom" ||
              response.setType == "customAdmin"
            ) {
              response.data?.forEach((item, i) => {
                item?.question?.forEach((question, j) => {
                  question.category = item?.category; 
                  question.set = response?.set ; 
                  question.passed = 0;
                  if (question.que?.options) {
                    for (let [
                      index,
                      option,
                    ] of question.que?.options?.entries()) {
                      if (question.que?.optionType == "MCQ") {
                        if (
                          question.ans?.includes(`${index + 1}`) &&
                          option.score > 0
                        ) {
                          question.passed = 1;
                          break;
                        }
                      } else {
                        if (question.ans == option.title && option.score > 0) {
                          question.passed = 1;
                          break;
                        }
                      }
                    }
                  }
                });
              });
              setQuestions(response.data);
            } else {
              setQuestions(response.data);
            }
            setQues(response);
            setAnswered(response.answered);
            setSubmitted(response.submitted);
            let finalObject = {};

            if (parseInt(response.answered) === 0) {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  let objecttoAdd = {
                    que: itemque,
                    ans: "",
                    remark: "",
                  };
                  ansarray.push(objecttoAdd);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            } else {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  ansarray.push(itemque);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            }
            // setTimeout(()=>{
            setshowLoader(false);
            // }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", {
              variant: "error",
            });
            setshowLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Questionnaire details not find.", { variant: "error" });
      setshowLoader(false);
    }
  };
  
  const getVendorIssue = async () => {
    if(domain){
      setshowLoader(true);
      try {
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        if (refreshToken) {
          const endpointIssue = process.env.REACT_APP_GET_VENDOR_ISSUES;
          await GetRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}?domain=${domain}`, 
          )
            .then((res) => {
              let response = res?.data?.data;
              setIssues(response[0])
              setshowLoader(false);
            })
            .catch((err) => {
              setshowLoader(false);
            })
        } else {
          window.location.href = "/";
        }
      }
      catch (error) {
        // enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
        setshowLoader(false);
      }
    }
  }

  const getMappingDataAPI = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setshowLoader(false);
            setMappingData(data);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }

  const getMappedIssueWithQuestionnaire = (key) => {
    const issueName = key?.que?.issueName?.trim().toLowerCase();
    const categories = ['dns', 'breaches', 'encryption', 'misconfiguration']; // Add all potential categories
    // const issueName = "Invalid SSL/TLS Subject "?.trim().toLowerCase();

    if (!issueName || !issues?.vendorInfo) {
      return []; // Early exit if issueName or vendorInfo is missing
    }
     
    const matchingIssues = categories.reduce((acc, category) => {
    const issuesInCategory = issues?.vendorInfo[category]; // Get issues for the current category

    // Check if the category has issues and filter for matches
    if (Array.isArray(issuesInCategory) && issuesInCategory.length > 0) {
      const matches = issuesInCategory.filter(data => 
        data?.Issue?.trim().toLowerCase() === issueName
      );

       // Add the category to each matched issue and accumulate
      matches.forEach(issue => {
        acc.push({
          ...issue,  // Spread existing issue properties
          category // Add the category
        });
      });
    }
    return acc;
    }, []); // Initialize accumulator as an empty array
    return matchingIssues; // Return the array of matching issues
  }
  
  // const ModalContent = () => {
  //   return "Save Assessment";
  // };

  const handleCloseButton = () => {
    setModelOpen(null);
  };

  function convertData(data) {
    return {
      text: data.que?.text,
      optionType: data.que?.optionType,
      issueName: data.que?.issueName,
      vulnerabilityType: data.que?.vulnerabilityType,
      answer: data?.ans,
      document_name: data?.document_name,
      documentName: data?.documentName,
      selectedOptions: data?.selectedOptions,  
      iso27001Control: data.que?.iso27001Control,
      gdpr: data.que?.gdpr,
      nist: data.que?.nist,
      impactOnClient: data.que?.impactOnClient,
      impactOnVendor: data.que?.impactOnVendor,
      options: data.que?.options,
      page_number: data.page_number,
      reference: data?.reference,
      iso27001ControlDetails: Array.isArray(mappingData.iso27001Control) ? mappingData.iso27001Control?.find(item => item?.controlId === data.que?.iso27001Control) : null , 
      nistDetails:Array.isArray(mappingData.nist) ? mappingData.nist?.find(item => item?.controlIdentifier ===  data?.que?.nist) : null ,
      gdprDetails: Array.isArray(mappingData.gdpr) ? mappingData.gdpr?.find(item => item?.article ===  data.que?.gdpr) : null ,      remark: data?.remark,
      riskMapping: data.que?.riskMapping,
      aiEvidence: data?.aiEvidence, 
      passed: data?.passed
      // selectedOptions: data.que?.selectedOptions,
    };
  }

  const renderMappedIssue = (key) => {
    const issue = getMappedIssueWithQuestionnaire(key); // Call the function to get the mapped issue

    if (issue && issue.length > 0) {
      return (
        <div className="mapping-issue">
          <img src={IconAlert} alt="icon" /> Issue Mapped
        </div>
      );
    }
    return null; // Return null if no issue
  };

   const toggleLoader = (isLoading) => {
    setshowAILoader(isLoading);
  };
  
  return (
    <>
      <Loader show={showLoader} />
      {AIshowLoader ? (
      <AILoader show={AIshowLoader} />
      ) : null}
      <Container maxWidth={false} className="max-w-left">
        <Grid container spacing={3} className="ipad-space">
          <div className="category-three-tabs-left">
            <h3 className="category-score-text-heading">Answer</h3>
              {submitted === 1 ? (
                          <AiEvidence
                            getQuestionnaire={getQuestionnaire}
                            domain={domain}
                            assessmentId={assessmentId}
                            setLoader={toggleLoader}
                          ></AiEvidence>
            ) : null} 
          </div>
          <Grid item xs={12} md={12} lg={12} style={{paddingTop:"3px", paddingLeft:"0", paddingRight:"0"}}>
            <div className="space-questionnaire">
              <div className="tabs-bg top-demo-space w-100-160">
              {/* {questions?.map((element, index, array) => {
                  <Tab label={"test"} {...a11yProps(`tab11`)} />;
                })} */}
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {questions?.map((tab, index) => (
                    <Tab
                      key={tab.category.toString() + index}
                      label={tab.category}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </div>
              <div className="tabs-bg top-demo-space w-100-cover">
              {questions?.map((tab, index) => (
                  <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    className="tabpanel-full-size left-space-tab map-isssues-manage"
                  >
                    <div className="question-part mt-questionpart">
                      {/* <div className="inline-parts">
                        <h3>{ques?.set}</h3>
                      </div> */}

                      {answered === 1
                      //answered
                        ? tab?.question?.map((key, i) => (
                          // answered
                            <>
                              {key.que?.text ? (
                                <div>
                                  {renderMappedIssue(key)}
                                  <Grid container columns={0} columnSpacing={3} className="padding-aling-view-answer">
                                    <Grid item xs={12} md={10} lg={10} columns={0}>
                                      <div style={{display:'flex'}}>
                                        <p className="preview-question-heading">Q. {i + 1}</p>
                                        <span className="data-text-preview">{key?.que?.text}</span>
                                      </div>
                                      {/* <p>{key?.que?.text}</p> */}
                                      {/* <QuestionCategoryInfo className="questions-categories" data={key?.que} /> */}
                                     </Grid>
                                    <Grid item xs={12} md={2} lg={2}>
                                      <div
                                        style={{
                                          // display: "flex",
                                          // alignItems: "center",
                                        }}
                                      >
                                        <div className="icons">
                                          {/* chat icon  */}
                                          <IconButton
                                            variant="outlined"
                                            onClick={() =>
                                              handleSendMessage(key?.que?.text, i)
                                            }
                                            color={
                                              lastClickedIndex === i
                                                ? "success"
                                                : "primary"
                                            }
                                            title={
                                              !lastClickedIndex &&
                                              "Click To Send Message"
                                            }
                                          >
                                            {lastClickedIndex === i ? (
                                              <CheckCircleOutlineIcon />
                                            ) : (
                                              <Tooltip
                                                title="Send as Message to Chat"
                                                arrow
                                              >
                                                <img src={ChatIcon} style={{position:'relative',top:'-4px'}} width={34} alt="icon" />
                                              </Tooltip>
                                            )}
                                          </IconButton>
                                            
                                          {/* Mapped Issue  */}
                                          <span 
                                            style={{ cursor: 'pointer' }} 
                                            onClick={async () => {
                                              toggleDrawer({index:i})(); // Open the drawer only when clicked
                                              const mappedIssue = getMappedIssueWithQuestionnaire(key); // Only fetch the mapped issue for the clicked question
                                              setMappedIssue(mappedIssue); 
                                              const data = convertData(key)
                                              setEvidence(data)
                                            }}
                                          >
                                            <img src={FilterIco} alt="icon" />
                                          </span>
                                           
                                          {(submitted===1) ? 
                                            <Drawer anchor="right" open={( openDrawer.index === i)} onClose={toggleDrawer({index:-1})}>
                                              <ViewAnswerSidebar
                                                data =  {evidence}
                                                issueMapped = {mappedIssue}
                                                detailedMapping = {key}
                                              />
                                            </Drawer>
                                            :
                                            <Drawer anchor="right" open={( openDrawer.index === i)} onClose={toggleDrawer({index:-1})}>
                                              <MappedIssue  
                                                data =  {evidence}
                                                issueMapped = {mappedIssue}
                                              />
                                            </Drawer>
                                          }
                                            
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {key.que?.optionType === "MCQ" ? (
                                    <div className="checkbox-view-answer">
                                      <div className="padding-leftform-control">
                                       <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                      >
                                      
                                      <div>
                                        {key.fileName !== ""  ? (
                                          <Link
                                          to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${ key?.fileName}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Button 
                                            className="view-evidence-button"
                                            >
                                          <img src={ViewEvidence} alt="icon" />
                                          </Button>
                                        </Link>
                                        ) : null}
                                        </div>
                                        <div>
                                         {/* passed icon  */}
                                         {submitted === 1 && 
                                        key.ans !== "" && (
                                          <Button
                                            id={
                                              key.passed === 1
                                                ? "passed-color"
                                                : "failed-color"
                                            }
                                            variant="contained"
                                            size="small"
                                            className="passed-failed-button"
                                          >
                                            {key.passed === 1 ? "Passed" : "Failed"}
                                          </Button>
                                        )}
                                      </div>
                                        </Box>
                                        <div className="checkbox-view-answer">
                                      <FormControl>
                                        {key.que?.options?.map((option, j) => {
                                          return (
                                            <FormControlLabel
                                              value={j + 1}
                                              control={
                                                <Checkbox
                                                  checked={key.ans?.includes(
                                                    `${j + 1}`
                                                  )}
                                                />
                                              }
                                              label={option.title}
                                              onChange={(e) =>
                                                questionnaireAnswerConfig(e, i)
                                              }
                                              disabled = {true}
                                              // disabled={submitted ? true : false}
                                            />
                                          );
                                        })}
                                      </FormControl>
                                      </div>
                                      
                                      </div>
                                    </div>
                                  ) : (
                                    // Single Choice Question 
                                    <div className="padding-leftform-control">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                      >
                                      {/* <p className="view-file">Write here</p> */}
                                      <div>
                                        {key.fileName !== ""  ? (
                                          <Link
                                          to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${ key?.fileName}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Button 
                                            className="view-evidence-button"
                                            >
                                          <img src={ViewEvidence} alt="icon" />
                                          </Button>
                                        </Link>
                                        ) : null}
                                        </div>
                                        <div>
                                         {/* passed icon  */}
                                         {submitted === 1 && 
                                        key.ans !== "" && (
                                          <Button
                                            id={
                                              key.passed === 1
                                                ? "passed-color"
                                                : "failed-color"
                                            }
                                            variant="contained"
                                            size="small"
                                            className="passed-failed-button"
                                          >
                                            {key.passed === 1 ? "Passed" : "Failed"}
                                          </Button>
                                        )}
                                      </div>
                                    </Box>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          defaultValue={key.ans}
                                        >
                                          {key.que?.options?.map((option, j) => {
                                            return (
                                              <FormControlLabel
                                                value={option.title}
                                                control={<Radio />}
                                                label={option.title}
                                                // onChange={(e) =>
                                                //   readRadioButton(e, i)
                                                // }
                                                disabled={true}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  )}
                              
                                  <TextareaAutosize
                                    aria-label="Write here"
                                    minRows={3}
                                    className="w100-textarea"
                                    defaultValue={key.remark ? key.remark.split("Document")[0].trim():""}
                                    readOnly={true}
                                    onChange={(e) => readRemark(e, i)}
                                  />
                                </div>
                              ) : (
                                <div>
                                   {renderMappedIssue(key)}
																				 
														   
                                  <Grid container columns={0} columnSpacing={3}>
                                    <Grid item xs={12} md={10} lg={10} columns={0}>
                                      <p>{key?.que?.text}</p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="d-flexalign"
                                    >
                                      <IconButton
                                        variant="outlined"
                                        onClick={() =>
                                          handleSendMessage(key?.que, i)
                                        }
                                        color={
                                          lastClickedIndex === i
                                            ? "success"
                                            : "primary"
                                        }
                                        title={
                                          !lastClickedIndex &&
                                          "Click To Send Message"
                                        }
                                      >
                                        {lastClickedIndex === i ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <Tooltip
                                            title="Send as Message to Chat"
                                            arrow
                                          >
                                            <button className="send-icon">
                                              <img src={SendPlane} alt="icon" />
                                            </button>
                                          </Tooltip>
                                        )}
                                      </IconButton>
                                      {key?.ans !== "" && (
                                        <Button
                                          id={
                                            key?.ans === "Yes"
                                              ? "passed-color"
                                              : "failed-color"
                                          }
                                          variant="contained"
                                          size="small"
                                          // className="passed-failed-button"
																			 
                                        >
                                          {key?.ans === "Yes" ? "Passed" : "Failed"}
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      defaultValue={key.ans}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled={true}
														  
                                        // disabled={submitted ? true : false}
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled={true}
														  
                                        // disabled={submitted ? true : false}
                                      />
                                      <FormControlLabel
                                        value="N/A"
                                        control={<Radio />}
                                        label="N/A"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled={true}
														  
                                        // disabled={submitted ? true : false}
                                      />
                                    </RadioGroup>

                                    <div className="icons">
                                      {key?.aiEvidence ? (
                                        <Button
                                          className="view-evidence-button"
                                          onClick={() =>
                                            setModelOpen(key.aiEvidence)
                                          }
                                          style={{
                                            color: !key.aiEvidence?.identifiedEvidence ? "red" : "#ffffff",
                                          }}
                                        >
                                          <img src={ViewEvidence} alt="icon" />
                                          View-Avidence
                                        </Button>
                                      ) : null}
                                    </div>

                                    {answered === 1 ? (
                                      key.fileName !== "" ? (
                                        <a
                                          href={
                                            process.env.REACT_APP_BACKEND_BASE_URL +
                                            "/evidence/" +
                                            key.fileName
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                          className="right-view"
                                        >
                                          <PreviewIcon
                                            style={{
                                              color:
                                                theme.palette.mode === "dark"
                                                  ? "white"
                                                  : "",
                                            }}
                                          />
                                        </a>
                                      ) : null
                                    ) : null}
                                  </FormControl>
                                  <p className="view-file">Write here</p>
                                  <TextareaAutosize
                                    aria-label="Write here"
                                    minRows={3}
                                    className="w100-textarea"
                                    defaultValue={key.remark || ""}
                                    readOnly={submitted}
                                    onChange={(e) => readRemark(e, i)}
                                  />
                                </div>
                              )}
                            </>
                          ))
                        : tab.question.map((key, i) => (
                          // Not answered
										 
                            <>
                              {ques.setType === "custom" ||
                              ques.setType === "customAdmin" ? (
                                // customAdmin and custom questionnaire 
																		
                                <div>
                                  {renderMappedIssue(key)}
														  
                                  <Grid container columns={0} columnSpacing={3}>
                                    <Grid item xs={12} md={10} lg={10} columns={0}>
                                      <p>{key?.text}</p>
                                    </Grid>
                                    {/* <QuestionCategoryInfo data={key} /> */}
                                    <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="d-flexalign"
                                    >
                                      <IconButton
                                        variant="outlined"
                                        onClick={() =>
                                          handleSendMessage(key.text, i)
                                        }
                                        color={
                                          lastClickedIndex === i
                                            ? "success"
                                            : "primary"
                                        }
                                        title={
                                          !lastClickedIndex &&
                                          "Click To Send Message"
                                        }
                                      >
                                        {lastClickedIndex === i ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <Tooltip
                                            title="Send as Message to Chat"
                                            arrow
                                          >
                                            <button className="send-icon">
                                              <img src={SendPlane} alt="icon" />
                                            </button>
                                          </Tooltip>
                                        )}
                                      </IconButton>

                                       {/* Mapped Issue  */}
                                       <span 
                                            style={{ cursor: 'pointer' }} 
                                            onClick={ async() => {
                                              toggleDrawer({index:i})();
                                              const mappedIssue = getMappedIssueWithQuestionnaire(key); // Await the result
                                              setMappedIssue(mappedIssue); // Set the resolved value to state
                                            }}
                                          >
                                            <img src={FilterIco} alt="icon" />
                                          </span>
                                          <Drawer anchor="right" open={(openDrawer.index === i)} onClose={toggleDrawer({index:-1})}>
                                            {/* {DrawerList} */}
                                            <MappedIssue  
                                            data =  {key}
                                            issueMapped = {mappedIssue}
                                            />
                                          </Drawer>
                                    </Grid>
                                  </Grid>
                                  
                                  {key.optionType === "MCQ" ? (
                                    <div>
                                      <FormControl>
                                        {key.options?.map((option, j) => {
                                          return (
                                            <FormControlLabel
                                              value={j + 1}
                                              control={<Checkbox />}
                                              label={option.title}
                                              onChange={(e) =>
                                                questionnaireAnswerConfig(e, i)
                                              }
                                              disabled={true}
                                            />
                                          );
                                        })}
                                      </FormControl>
                                    </div>
                                  ) : (
                                    <div>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                        >
                                          {key.options?.map((option, j) => {
                                            return (
                                              <FormControlLabel
                                                value={option.title}
                                                control={<Radio />}
                                                label={option.title}
                                                onChange={(e) =>
                                                  readRadioButton(e, i)
                                                }
                                                disabled
                                                readOnly
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                // Default questionnaire 
														 
                                <div>
                                  {renderMappedIssue(key)}
														  
                                  <Grid container columns={0} columnSpacing={3}>
                                    <Grid item xs={12} md={10} lg={10} columns={0}>
                                      <p>{key}</p>
                                    </Grid>
                                    {/* <QuestionCategoryInfo data={key} /> */}
                                    <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      lg={2}
                                      className="d-flexalign"
                                    >
                                      <IconButton
                                        variant="outlined"
                                        onClick={() => handleSendMessage(key, i)}
                                        color={
                                          lastClickedIndex === i
                                            ? "success"
                                            : "primary"
                                        }
                                        title={
                                          !lastClickedIndex &&
                                          "Click To Send Message"
                                        }
                                      >
                                        {lastClickedIndex === i ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <Tooltip
                                            title="Send as Message to Chat"
                                            arrow
                                          >
                                            <button className="send-icon">
                                              <img src={SendPlane} alt="icon" />
                                            </button>
                                          </Tooltip>
                                        )}
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled
                                        readOnly
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled
                                        readOnly
                                      />
                                      <FormControlLabel
                                        value="N/A"
                                        control={<Radio />}
                                        label="N/A"
                                        onChange={(e) => readRadioButton(e, i)}
                                        disabled
                                        readOnly
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              )}

                              <p>Write here</p>
                              <TextareaAutosize
                                aria-label="Write here"
                                minRows={3}
                                className="w100-textarea"
                                onChange={(e) => readRemark(e, i)}
                                disabled
                                readOnly
                              />
                            </>
                          ))}
                    </div>
                  </TabPanel>
                ))}
               </div>
             </div>
          </Grid>
          </Grid>
        {/* <ModalDialog
          handleClose={handleClose}
          open={open}
          onSubmit={() => do_saved(1)}
          contentData={ModalContent}
        /> */}

        {/* AI Evidence Model  */}
								  
        {/* <Modal
          open={modelOpen !== null}
          onClose={() => setModelOpen(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={
            theme === "Dark"
              ? "dark-attack-modal modal-popup"
              : "light-attack-modal modal-popup"
          }
        >
          <Box sx={style} className="black-bg openai-response">
            <CloseIcon
              onClick={handleCloseButton}
              style={{
                position: "absolute",
                right: 15,
                top: 15,
                cursor: "pointer",
              }}
            />
            <h2>Evidence by Ai</h2>
            <div>
              <div variant="body2">
                <b>Vendor Response:</b>
                <br />
                {modelOpen?.vendorResponse ? modelOpen.vendorResponse : "N/A"}
              </div>
              <div variant="body2" style={{ marginTop: "1rem" }}>
                <b>Identified Evidence:</b>
                <br />
                {modelOpen?.identifiedEvidence ? modelOpen.identifiedEvidence : "N/A"}
              </div>
              <div variant="body2" style={{ marginTop: "1rem" }}>
                <b>Recommended Action:</b>
                <br />
                {modelOpen?.recommendedAction ? modelOpen.recommendedAction : "N/A"}
              </div>
              <div variant="body2" style={{ marginTop: "1rem" }}>
                <b>Document Referenced:</b>
                <br />
                {modelOpen?.documentReferenced ? modelOpen.documentReferenced : "N/A"}
              </div>
            </div>
          </Box>
        </Modal> */}
      </Container>
    </>
  );
}
