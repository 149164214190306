import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import CrossImg from "../assets/cross-select.svg";
import "./css/Vendors.css";
import DeleteIcon from "../assets/delete-bin.svg";
// ----------------------------------------------------------------------

export function VendorRequestDocuments({
    handleModelCloseFunction,
    vendorUrl,
    vendorNotAvailableDocuments,
    getVendorDocuments,
    contactDetails,
}) {

    const { enqueueSnackbar } = useSnackbar();
    const [userId, setUserId] = useState(localStorage.getItem("userId"));
    const [showLoader, setshowLoader] = useState(false);
    const [contactPerson, setcontactPerson] = useState('');
    const [formData, setFormData] = useState({
        documents: [{ documentType: '' }],
    });
    const [errors, setErrors] = useState({});
    let availableDocumentTypes;
    const handleRequestDocuments = async () => {
        setshowLoader(true);
        if (validateFields()) {
            const requestDocument = formData.documents
                .map(doc => ({
                    clientId: userId,
                    // vendorId: doc.vendorId,
                    vendorDomain: vendorUrl,
                    documentType: doc.documentType.type
                }));
            const payload = {
                contactPersonEmail: contactPerson,
                requestDocument: requestDocument,
                vendorDomain: vendorUrl
            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    {},
                    "refreshToken"
                );
                if (refreshToken) {
                    PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_DOCUMENT_TO_VENDOR}`,
                        payload)
                        .then((response) => {
                            enqueueSnackbar(response.data.message, { variant: "success" });
                            setshowLoader(false);
                            handleModelCloseFunction();
                            getVendorDocuments();
                        })
                        .catch((errApi) => {
                            enqueueSnackbar(errApi.data.message, { variant: "error" });
                            setshowLoader(false);
                            handleModelCloseFunction();
                        })
                }
            } catch (error) {
                // no data
                enqueueSnackbar("Requested Url not find", { variant: "error" });
                setshowLoader(false);
                handleModelCloseFunction();
                return false;
            }

        }
        else {
            enqueueSnackbar("Please provide valid details", { variant: 'error' });
            setshowLoader(false);
        }
    }

    const validateFields = () => {
        const newErrors = {};
        console.log("contactPerson", !contactPerson)
        if (!contactPerson) newErrors.contactPerson = '*please select Contact Person';

        formData.documents.forEach((document, index) => {
            if (!document.documentType) {
                newErrors[`document-${index}-type`] = '*Please select a document type';
            }
            if (document.isUpload && !document.documentFile) {
                newErrors[`document-${index}-file`] = '*Please upload a document file';
            }
        });
        setErrors(newErrors);
        console.log(Object.keys(newErrors).length === 0);
        return Object.keys(newErrors).length === 0;
    };

    const handleContactPersonChange = (event) => {
        // console.log("event.target.value", event.target.value)
        setcontactPerson(event.target.value);
        if (event.target.value) {
            setErrors((prevErrors) => {
                const { contactPerson, ...restErrors } = prevErrors;
                return restErrors;
            });
        }
    };

    const handleDeletedOC = (index) => {
        const updatedDOC = formData.documents.filter((_, i) => i !== index);
        setFormData({ ...formData, documents: updatedDOC });
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 601,
        boxShadow: 24,
        overflowY: "auto",  // Enable vertical scrolling
    };
    return (
        <>
            <Loader show={showLoader} />
            <Box sx={style} className="scroll-design">
                <div className="popup-remiadation controls-popup">
                    <div className="pop-flex-remiadation">
                        <h3>Request Document </h3>
                        <img src={CrossImg} style={{ cursor: "pointer" }} alt="icon" onClick={handleModelCloseFunction} />
                    </div>
                    <div className="popup-form-remidaition">
                        <p className="please-provide-text">Please provide the contact person and select the document type to request a document.</p>
                        <Grid item xs={12} md={11}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    {!contactPerson ? "Select Person" : ""}
                                </InputLabel>
                                <TextField
                                    labelId="demo-simple-select-label"
                                    select
                                    defaultValue=""
                                    value={contactPerson || ""}
                                    onChange={handleContactPersonChange}
                                    sx={{ mb: 3 }}
                                    error={!!errors.contactPerson}
                                    helperText={errors.contactPerson}
                                >
                                    {contactDetails && contactDetails.length >= 0 && contactDetails.map((item) => (
                                        <MenuItem key={item.email} value={item.email}>
                                            {item.firstName} {item.lastName} {`(${item.email})`}
                                        </MenuItem>
                                    ))}
                                    {!!errors.contactPerson && (
                                        <FormHelperText sx={{ color: 'red' }}>{errors.contactPerson}</FormHelperText>
                                    )}
                                </TextField>
                            </FormControl></Grid>
                        {formData.documents.map((document, index) => {
                            // Filter available document types that are not already selected
                            const filteredDocumentTypes = vendorNotAvailableDocuments.filter(
                                (type) => !formData.documents.some((doc, i) => doc.documentType?._id === type._id && i !== index)
                            );

                            return (
                                <Grid container spacing={2} key={index} alignItems="center">
                                    {/* Document Type Selection */}
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <InputLabel
                                                id={`doc-type-label-${index}`}
                                                shrink={!!document.documentType}
                                                sx={{
                                                    '&.Mui-focused': {
                                                        color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                                    },
                                                }}
                                            >
                                                Select Document Type
                                            </InputLabel>
                                            <Select
                                                labelId={`doc-type-label-${index}`}
                                                id={`doc-type-${index}`}
                                                name="documentType"
                                                value={document.documentType || ''}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const updatedDocuments = [...formData.documents];
                                                    updatedDocuments[index].documentType = selectedValue;

                                                    // Clear the error for this document when a valid selection is made
                                                    const updatedErrors = { ...errors };
                                                    if (selectedValue) {
                                                        delete updatedErrors[`document-${index}-type`];
                                                    } else {
                                                        updatedErrors[`document-${index}-type`] = 'Document type is required';
                                                    }

                                                    setErrors(updatedErrors); // Update error state
                                                    setFormData((prev) => ({ ...prev, documents: updatedDocuments })); // Update form data
                                                }}
                                                required
                                                label="Upload Document Type"
                                                error={!!errors[`document-${index}-type`]}
                                            >
                                                {/* Render filtered document types */}
                                                {filteredDocumentTypes.length > 0 ? (
                                                    filteredDocumentTypes.map((type) => (
                                                        <MenuItem key={type._id} value={type}>
                                                            {type.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value="" disabled>
                                                        Not Available
                                                    </MenuItem>
                                                )}
                                            </Select>
                                            {!!errors[`document-${index}-type`] && (
                                                <FormHelperText sx={{ color: 'red' }}>
                                                    {errors[`document-${index}-type`]}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Delete Icon */}
                                    <Grid item xs={12} md={1}>
                                        {index > 0 && (
                                            <img style={{ cursor: 'pointer' }} src={DeleteIcon} onClick={() => handleDeletedOC(index)}
                                                sx={{ color: 'red' }} />
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}

                        {/* Button to Add Another Document */}
                        {vendorNotAvailableDocuments.length > formData.documents.length && (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className="add-another-button extend-width-doc"
                                onClick={() => {
                                    const updatedDocuments = [
                                        ...formData.documents,
                                        { documentType: '', isUpload: false, documentFile: null, documentName: null },
                                    ];
                                    setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                }}
                                sx={{ mt: 2 }}
                            >
                                Add Another Document
                            </Button>
                        )}

                        <div className="button-alin-remaidation" style={{ textAlign: 'center', marginTop: "50px" }}>
                            <Button onClick={handleModelCloseFunction} variant="contained" className="cancel-popup-btns">
                                Cancel
                            </Button>
                            <Button type="submit" onClick={() => handleRequestDocuments()} variant="contained" className="cancel-popup-btns save-direct">
                                Request Document
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}



export default VendorRequestDocuments