import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import PropTypes from "prop-types";
import "./css/questionnaire.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize } from "@mui/base";
import Dialog from '@mui/material/Dialog';
import MailSend from "../assets/mailsend.svg";
import { useSnackbar } from 'notistack';
import { Link, useLocation, useParams } from "react-router-dom";
import ModalDialog from "./ModalDialog.js";
import FilterIco from '../assets/filter-icon.svg';
import PostRequest from "../components/apiConnections/postRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import BackArrow from '../assets/back-arrow.svg';
import AiFill from '../assets/ai-fill-btn.svg';
import IconAlert from '../assets/icon-alert.svg';
import DefaultQuestionnaireLogo from '../assets/questionnaire-image.png';

import {
  MenuItem,
  Grid,
  Tabs,
  Tab,
  Container,
  Typography,
  Card,
  Box,
  TextField,
  Button,
  Tooltip,
  Drawer,
  CardMedia,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MappedIssue from "./MappedIssue.js";
import SendArrow from '../assets/top-arrow-btn.svg';
import AnimateIcond from '../assets/animate-send.gif';

import GetRequest from "../components/apiConnections/getRequest.js";
import AILoader from "../AILoader.js";
import { updateSubscriptionDetails } from "../redux/subscriptionDetailsSlice.js";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Questionnaire({ theme }) {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const dispatch = useDispatch();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [logoLink, setLogoLink] = useState();
  const handleOpen = () => {
    if (filteredQuestions.length === 0) {
      enqueueSnackbar("No questions selected. Please add questions before proceeding.", { variant: 'warning' });
      return;
    }
    setOpen(true);
  }

  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const [AIFillLoader, setshowAILoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [assessmentData, setAssessmentData] = useState(window.localStorage.getItem("assessmentData"));
  const [questions, setQuestions] = useState([]);
  const [queData, setQueData] = useState(JSON.parse(window.localStorage.getItem("assessmentData")));
  const [ques, setQues] = useState({});
  const [openPop, setOpenPop] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [mappingData, setMappingData] = useState({
    iso27001Control: [],
    gdpr: [],
    nist: [],
  })
  const [filter, setFilter] = useState({
    iso27001Control: [],
    gdpr: [],
    nist: [],
  });
  const [aiFilledquestions, setAiFilledQuestions] = useState([]);
  const [aiFilled, setAiFilled] = useState(false);
  const [questionnaireSubmittedId, setQuestionnaireSubmittedId] = useState('');
  const [issues, setIssues] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(-1);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };
  const [mappedIssue, setMappedIssue] = useState(null); // State to store the mapped issue

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [successData, setSuccessData] = useState({})

  const handleClickOpen = () => {
    setShowConfirmModal(true);
    ;
  };

  const handleCloseNew = () => {
    setOpenPop(false);
  };

  useEffect(() => {
    getMappingDataAPI();
    getQuestionnaire();
    setAiFilled(false);
    getVendorIssue();
  }, [])

  useEffect(() => {
    if (aiFilled) {
      handleClearAiFill();
      setAiFilled(false);
    }
    const filteredData = applyFilters()
    setFilteredQuestions(filteredData);
  }, [filter])

  const applyFilters = () => {
    const { iso27001Control, gdpr, nist } = filter;
    return (iso27001Control.length == 0 && gdpr.length == 0 && nist.length == 0) ?
      questions :
      questions?.map(category => {

        const filteredQuestions = category.question?.filter(question => {
          const matchesISO = iso27001Control?.length > 0 ? iso27001Control.includes(question.iso27001Control) : false;
          const matchesGDPR = gdpr?.length > 0 ? gdpr.includes(question.gdpr) : false;
          const matchesNIST = nist?.length > 0 ? nist.includes(question.nist) : false;

          return matchesISO || matchesGDPR || matchesNIST;
        });

        return {
          ...category,
          question: filteredQuestions
        };
      }).filter(category => category.question.length > 0);
  };

  useEffect(() => {
    if (assessmentData?.setId) {
      getQuestionairelogoLink(assessmentData?.setId);
    }
  }, [assessmentData]);

  const getQuestionairelogoLink = async (setId) => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"get-questionnaire-logo-link-by-set-id"}?id=${setId}`,
          {}
        )
          .then((data) => {
            setLogoLink(data?.data?.data?.logo)
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" })
            return false;
          })
      }
    } catch (error) {
      return false;
    }
  };

  const getVendorIssue = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ISSUES}?domain=${vendorUrl}`,
        )
          .then((res) => {
            let response = res?.data?.data;
            setIssues(response[0])
          })
          .catch((err) => {
            setshowLoader(false);
          })
      } else {
        window.location.href = "/";
      }
    }
    catch (error) {
      // enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
      setshowLoader(false);
    }
  }

  const aiFillAndSend = async () => {
    if (filteredQuestions.length === 0) {
      enqueueSnackbar("No questions selected. Please add questions before proceeding.", { variant: 'warning' });
      return;
    }
    const selectedQuestions = getFilteredQuestions(filteredQuestions);
    let payload = {
      set: queData.set,
      url: vendorUrl,
      selectedQuestions
    };
    setshowAILoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"ai-fill-questionnaire"}`,
          payload,
        )
          .then((resultData) => {
            enqueueSnackbar(resultData.data.message, { variant: 'success' })
            setAiFilled(true);
            const data = resultData.data.data;
            setAiFilledQuestions(resultData?.data?.data?.questionnaires)
            setQuestionnaireSubmittedId(resultData?.data?.data?._id)
            const updatedQuestions = updateAnsweredQuestions(filteredQuestions, resultData?.data);
            setFilteredQuestions(updatedQuestions); // Update state with filtered questions
            setshowAILoader(false);
          })
          .catch((errChart) => {
            console.log('errChart', errChart)
            enqueueSnackbar(errChart.message, { variant: 'error' })
            setshowAILoader(false);
          })
      }
    }
    catch (error) {
      enqueueSnackbar("Assessment not saved.", { variant: 'error' })
      setshowAILoader(false);
    }
  }

  const do_saved = async () => {
    const selectedQuestions = (!aiFilled) ? getFilteredQuestions(filteredQuestions) : [];
    let payload = {
      firstName: queData.firstName,
      lastName: queData.lastName,
      email: queData.email,
      data_classification: queData.data_classification,
      assess_level: queData.assess_level,
      regulatory_compliance: queData.regulatory_compliance,
      set: queData.set,
      setId: queData.setId,
      setType: queData.setType,
      deadline: queData.deadline,
      reassessmentDates: queData.reassessmentDates,
      createdBy: queData.createdBy,
      url: vendorUrl,
      aiFill: aiFilled,
      questionnaireSubmittedId,
      selectedQuestions
    };

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_ASSESSMENT_BY_USER}`,
          payload,
        )
          .then((resultData) => {
            enqueueSnackbar("Assessment Sent successfully.", { variant: 'success' })
            window.localStorage.removeItem('assessmentData');
            const data = resultData.data.data;
            setOpenPop(true);
            setShowConfirmModal(false)
            setSuccessData({ aiFill: data.aiFill, email: data.email, assessmentId: data._id })
            dispatch(updateSubscriptionDetails({id : "assessments" }));
          })
          .catch((errChart) => {
            setOpenPop(false)
            enqueueSnackbar(errChart.message, { variant: 'error' })
          })
      }
      else {
        setshowLoader(false);
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar("Assessment not saved.", { variant: 'error' })
    }
  }

  const getQuestionnaire = async () => {
    setAssessmentData(JSON.parse(assessmentData));
    const checkSet = JSON.parse(assessmentData);
    let questionnaireSet = '';
    Object.entries(checkSet)?.map((key, val) => {
      if (key[0] == "set") {
        questionnaireSet = key[1];
      }
    })
    setshowLoader(true);
    const payload = {
      set: questionnaireSet,
      setId: checkSet?.setId,
      setType: checkSet?.setType
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_USER;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((res) => {
            let response = res?.data?.data;
            setQuestions(response?.data)
            setQues(response);
            setFilteredQuestions(response?.data)
            setTimeout(() => {
              setshowLoader(false);
            }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
      setshowLoader(false);
    }
  }

  const getMappingDataAPI = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setshowLoader(false);
            setMappingData(data);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }

  const clearAiFillAPI = async () => {
    setshowLoader(true);
    const payload = {
      questionnaireId: questionnaireSubmittedId,
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_AIFILL_QUESTIONNAIRE}`,
          payload
        )
          .then((res) => {
            setshowLoader(false);
          })
          .catch((err) => {
            console.log('errChart2', err)
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }

  const getMappedIssueWithQuestionnaire = (key) => {
    const issueName = key?.issueName?.trim().toLowerCase();
    const categories = ['dns', 'breaches', 'encryption', 'misconfiguration']; // Add all potential categories

    if (!issueName || !issues?.vendorInfo) {
      return []; // Early exit if issueName or vendorInfo is missing
    }
    const matchingIssues = categories.reduce((acc, category) => {
      const issuesInCategory = issues?.vendorInfo[category]; // Get issues for the current category

      // Check if the category has issues and filter for matches
      if (Array.isArray(issuesInCategory) && issuesInCategory.length > 0) {
        const matches = issuesInCategory.filter(data =>
          data?.Issue?.trim().toLowerCase() === issueName
        );

        // Add the category to each matched issue and accumulate
        matches.forEach(issue => {
          acc.push({
            ...issue,  // Spread existing issue properties
            category // Add the category
          });
        });
      }
      return acc;
    }, []); // Initialize accumulator as an empty array
    return matchingIssues; // Return the array of matching issues
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h2 className="send-assessments">Send Assessment to the vendor?</h2>
      </Typography>
    );
  }

  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to send this assessment ?
        </h2>
      </Typography>
    );
  };

  const handleClearFilter = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      iso27001Control: [],
      gdpr: [],
      nist: [],
    }));
  }

  const handleQuestionPropertyChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  function getFilteredQuestions(filteredQuestions) {
    let selectedQuestions = [];
    filteredQuestions.forEach((categoryItem, index) => {
      const category = categoryItem.category;
      categoryItem.question.forEach((questionItem, item) => {
        const selectedQuestion = {
          category: category,
          question: {
            uniqueId: uniqueIdGenerator(index, item),
            text: questionItem.text ? questionItem.text : (assessmentData.setType == "default") ? questionItem : "",
            gdpr: questionItem.gdpr,
            impactOnClient: questionItem.impactOnClient,
            impactOnVendor: questionItem.impactOnVendor,
            iso27001Control: questionItem.iso27001Control,
            issueName: questionItem.issueName,
            nist: questionItem.nist,
            optionType: questionItem.optionType ? questionItem.optionType :  (assessmentData.setType == "default") ? "SCQ":"",
            options: questionItem.options ? questionItem.options : (assessmentData.setType == "default") ? [{title:"Yes",score:5},{title:"No",score:0},{title:"N/A",score:2}]:[],
            riskMapping: questionItem.riskMapping,
            riskCategory: questionItem?.riskCategory,
            vulnerabilityType: questionItem.vulnerabilityType,
          }
        };

        selectedQuestions.push(selectedQuestion);
      });
    });

    return selectedQuestions;
  }

  const uniqueIdGenerator = (index, item) => {

    // let firstName = ((queData.firstName).slice(0, 3)).toUpperCase();
    // let createdBy = (queData.createdBy).slice(0, 3).toUpperCase();
    // let timeStamp = new Date().getTime();
    // let uniqueId = `GEN-${createdBy}-${firstName}-${set}-C${questionNo}-${timeStamp}`;

    let questionNo = `${index}${item}`;
    let set = (queData.set).slice(0, 2).toUpperCase();

    function getTwoRandomAlphanumerics() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#';
      return (
        chars.charAt(Math.floor(Math.random() * chars.length)) +
        chars.charAt(Math.floor(Math.random() * chars.length))
      );
    }

    let alphaNumericId = set + getTwoRandomAlphanumerics();
    let uniqueId = `FC-${questionNo}${alphaNumericId}`;

    return uniqueId;
  }

  const updateAnsweredQuestions = (questions, aiResponse) => {
    const aiQuestions = aiResponse.data.questionnaires;
    return questions?.map((categoryObj) => {

      const category = categoryObj.category;
      const aiCategory = aiQuestions[category];
      if (aiCategory) {
        const updatedQuestions = categoryObj.question?.map((question) => {
          const aiQuestion = aiCategory.find((aiQueObj) => aiQueObj.que.text === question.text);
          if (aiQuestion) {
            return {
              ...question,
              answer: aiQuestion.ans || "",
              remark: aiQuestion.remark || "",
              selectedOptions: aiQuestion.selectedOptions || [],
              document_name: aiQuestion.document_name || "",
              documentName: aiQuestion.documentName || "",
              page_number: aiQuestion.page_number || "",
              reference: aiQuestion.reference || "",
            };
          }
          return question;
        });
        return {
          ...categoryObj,
          question: updatedQuestions,
        };
      }

      return categoryObj;
    });
  };

  const handleClearAiFill = () => {
    clearAiFillAPI()
    const questions =  filteredQuestions.map((questionSet) => ({
      ...questionSet,
      question: questionSet.question.map((q) => {
        const { documentName, document_name, page_number, reference, remark,answer ,selectedOptions, ...filteredQuestion } = q;
        return filteredQuestion;
      })
    }));
    setFilteredQuestions(questions); 
    setAiFilled(false);
    
  }

  const renderMappedIssue = (key) => {
    const issue = getMappedIssueWithQuestionnaire(key); // Call the function to get the mapped issue

    if (issue && issue.length > 0) {
      return (
        <div className="mapping-issue">
          <img src={IconAlert} alt="icon" /> Issue Mapped
        </div>
      );
    }
    return null; // Return null if no issue
  };

  const handleFinalClick = () => {
    if (location?.state==='../assessment/') {
      navigate(`/dashboard/assessment`);
    } else {
      navigate(`../${location?.state}`, { state: { mainTabValue: 2 } });
    }
  }
  return (
    <>
      <Loader show={showLoader} />
      {AIFillLoader ? (
      <AILoader show={AIFillLoader} />
       ):null}
      <Container maxWidth={false}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="flex-qustionnire-property">
            <Typography variant="h4" sx={{ mb: 0 }}>
              <span className='backlink'>
                {/* <Link to={``} className='none-line'> */}
                <img src={BackArrow} alt='icon' onClick={(e) => { aiFilled && handleClearAiFill(); navigate(`../vendor-information/${vendorUrl}`, { state: location?.state }) }} />
                {/* </Link> */}
                Questionnaire
              </span>
            </Typography>
            <Button
              variant="contained"
              className="questionnnire-send-assessment"
              onClick={handleClickOpen}
            >
              <img src={SendArrow} className="send-arrow-icon" /> Send Assessment
            </Button>
          </div>
        </Grid>

        {/* Filter  */}
        <Grid item xs={12} md={12} lg={12}>
          <div className="flex-from-group">
            <div style={{ display: 'flex', width: "80%", gap: "20px" }}>
              {/* Filter  */}
              <FormControl>
                {/* issue name  */}
                <TextField
                  select
                  fullWidth
                  name='iso27001Control'
                  label="ISO 27001 Control"
                  value={filter?.iso27001Control}
                  onChange={handleQuestionPropertyChange}
                  SelectProps={{
                    multiple: true, // Enable multiple selections
                    renderValue: (selected) => {
                      return selected?.map((value) => {
                        const selectedItem = mappingData.iso27001Control.find(item => item.controlId === value);
                        return selectedItem ? `${selectedItem.controlId} ` : value;
                      }).join(', ');
                    }, // Custom render for selected values
                  }}
                  inputProps={{ 'aria-label': 'Select ISO 27001 Control' }}
                  InputLabelProps={{
                    shrink: Boolean(filter?.iso27001Control?.length !== 0),
                  }}
                >
                  <MenuItem value="" disabled>
                    Select ISO 27001 Control
                  </MenuItem>
                  {Array.isArray(mappingData.iso27001Control) ? mappingData.iso27001Control?.map((item, index) => (
                    <MenuItem key={index} value={item.controlId}>
                      <Tooltip title={item.description} arrow>
                        <span>
                          {item.controlId} ({item.description?.length > 40 ? `${item.description?.substring(0, 40)}...` : item.description})
                        </span>
                      </Tooltip>
                      {/* {item.controlId} ({item.description}) */}
                    </MenuItem>
                  )) : null}
                </TextField>
              </FormControl>

              {/* GDPR */}
              <FormControl>
                <TextField
                  select
                  fullWidth
                  name='gdpr'
                  label="GDPR"
                  value={filter?.gdpr || ""}
                  onChange={handleQuestionPropertyChange}
                  SelectProps={{
                    multiple: true, // Enable multiple selections
                    renderValue: (selected) => {
                      return selected?.map((value) => {
                        const selectedItem = mappingData.gdpr.find(item => item.controlIdentifier === value);
                        return selectedItem ? `${selectedItem.article}` : value;
                      }).join(', ');
                    }, // Custom render for selected values
                  }}
                  inputProps={{ 'aria-label': 'Select GDPR' }}
                  InputLabelProps={{
                    shrink: Boolean(filter?.gdpr?.length !== 0),
                  }}
                >
                  <MenuItem value="" disabled>
                    Select GDPR
                  </MenuItem>
                  {Array.isArray(mappingData.gdpr) ? mappingData.gdpr?.map((item, index) => (
                    <MenuItem key={index} value={item.article}>
                      <Tooltip title={item.articleTitle} arrow>
                        <span>
                          {item.article} ({item.articleTitle?.length > 40 ? `${item.articleTitle?.substring(0, 40)}...` : item.articleTitle})
                        </span>
                      </Tooltip>
                      {/* {item.article} {item.articleTitle} */}
                    </MenuItem>

                  )) : null}
                </TextField>
              </FormControl>

              {/* NIST   */}
              <FormControl>
                <TextField
                  select
                  fullWidth
                  name='nist'
                  label="NIST"
                  value={filter?.nist}
                  onChange={handleQuestionPropertyChange}
                  SelectProps={{
                    multiple: true, // Enable multiple selections
                    renderValue: (selected) => {
                      return selected?.map((value) => {
                        const selectedItem = mappingData.nist.find(item => item.controlIdentifier === value);
                        return selectedItem ? `${selectedItem.controlIdentifier}` : value;
                      }).join(', ');
                    }, // Custom render for selected values
                  }}
                  inputProps={{ 'aria-label': 'Select NIST' }}
                  InputLabelProps={{
                    shrink: Boolean(filter?.nist?.length !== 0),
                  }}
                >
                  <MenuItem value="" disabled>
                    Select NIST
                  </MenuItem>
                  {Array.isArray(mappingData.nist) ? mappingData.nist?.map((item, index) => (
                    <MenuItem key={index} value={item.controlIdentifier}>
                      <Tooltip title={item.controlName} arrow>
                        <span>
                          {item.controlIdentifier} ({item.controlName?.length > 40 ? `${item.controlName?.substring(0, 40)}...` : item.controlName})
                        </span>
                      </Tooltip>
                      {/* {item.controlIdentifier} {item.controlName} */}
                    </MenuItem>
                  )) : null}
                </TextField>
              </FormControl>
            </div>
            <Button
              variant="contained"
              className="clear-filter"
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
          </div>
        </Grid>
        <div className="cybersecurity-assessment-box">
          <p>
            <span className="dpcyber">
              {logoLink ?
                <img className="logo-image-vendor attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logoLink}`} alt='logo' />
                :
                <CardMedia
                  component="img"
                  sx={{
                    objectFit: "cover",
                    mx: 'auto',
                    borderRadius: "50%"
                  }}
                  image={DefaultQuestionnaireLogo}
                  alt='logo'
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                />
              }
            </span>
            {ques?.set}
          </p>
          {((!aiFilled) ?
            <img src={AiFill} alt="icon" className="ai-fill" onClick={aiFillAndSend} />
            :
            <Button
              className="clear-ai-fill"
              style={{
                backgroundColor: "rgba(239, 21, 21, 0.3)",
                borderRadius: "20px",
                color: "white",
                width: "12%",
              }}
              onClick={handleClearAiFill}
            >
              Clear AI Fill
            </Button>
          )}

        </div>
        <div className="space-questionnaire">
          <div className="tabs-bg top-demo-space w-100-160">
            {questions?.map((element, index, array) => {
              <Tab label={'test'} key={index}  {...a11yProps(`tab11`)} />
            })}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {
                filteredQuestions?.map((tab, index) => (
                  <Tab
                    key={tab.category.toString() + index}
                    label={tab.category}
                    {...a11yProps(index)}
                  />
                ))
              }
            </Tabs>
          </div>
          <div className="tabs-bg top-demo-space w-100-cover">
            {
              (filteredQuestions) ? (
                <div>
                  <Box>
                    {filteredQuestions?.map((tab, index) => (
                      <TabPanel key={index} value={value} index={index}>
                        <div className="question-part alignciq-question">

                          {/* setType = default */}
                          {assessmentData?.setType == 'default' ? <div className="ciq-space">
                            {tab.question?.map((key, val) => (
                              <div key={val}>
                                <p>{key}</p>
                                {/* <QuestionCategoryInfo data={key} marginLeftTrue={true}/> */}
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="Yes"
                                      disabled
                                    />
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="No"
                                      disabled

                                    />
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="N/A"
                                      disabled
                                    />
                                  </RadioGroup>
                                </FormControl>
                                <p>Write here</p>
                                <TextareaAutosize
                                  aria-label="Write here"
                                  minRows={3}
                                  className="w100-textarea"
                                  disabled
                                  defaultValue={key?.remark || ""}
                                />
                              </div>
                            ))}
                            {/* assessment  Type == custom and customAdmin */}
                          </div> : (assessmentData?.setType == 'custom' || assessmentData?.setType == "customAdmin") ? <div>
                            {tab.question?.map((key, val) => (
                              <div key={val} className="multiple-questionniareloop">
                                {renderMappedIssue(key)}
                                <div className="flex-preview-tab que-indexing">
                                  <div style={{ display: 'flex' }}>
                                    <p className="preview-question-heading">Q. {val + 1}</p>
                                    <span className="data-text-preview">{key.text}</span>
                                  </div>
                                  {/* Mapped Issue  */}
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      toggleDrawer(val)();
                                      const mappedIssue = getMappedIssueWithQuestionnaire(key); // Await the result
                                      setMappedIssue(mappedIssue); // Set the resolved value to state
                                    }}
                                  >
                                    <img src={FilterIco} alt="icon" />
                                  </span>
                                  <Drawer anchor="right" open={(openDrawer === val)} onClose={toggleDrawer(-1)}>
                                    <MappedIssue
                                      data={key}
                                      issueMapped={mappedIssue}
                                    />
                                  </Drawer>

                                </div>
                                {/* <QuestionCategoryInfo data={key} marginLeftTrue={true}/> */}
                                <div className="options-flex">
                                  {((!aiFilled) ? (
                                    <>
                                      {/* Not AI Filled  */}
                                      {key.options?.map((option, i4) => (
                                        <div className="flex-radio-check">
                                          <p>
                                            <FormControlLabel
                                              value="#"
                                              control={key.optionType == 'MCQ' ? <Checkbox /> : <Radio />}
                                              label=""
                                              disabled
                                            />
                                          </p>
                                          <span className="data-text-preview">{option.title}</span>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <div>
                                      {/*  AI filled MCQ  */}
                                      {key?.optionType === "MCQ" ? (
                                        <div className="checkbox-view-answer">
                                          <FormControl>
                                            {key?.options?.map((option, j) => {
                                              return (
                                                <FormControlLabel
                                                  value={j + 1}
                                                  control={
                                                    <Checkbox
                                                      checked={key.answer?.includes(
                                                        `${j + 1}`
                                                      )}
                                                    />
                                                  }
                                                  disabled={true}
                                                  label={option.title}
                                                // onChange={(e) =>
                                                //   questionnaireAnswerConfig(e, i)
                                                // }
                                                // disabled={submitted ? true : false}
                                                />
                                              );
                                            })}
                                          </FormControl>
                                        </div>
                                      ) : (
                                        //  AI Filled SCQ 
                                        <div>
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            defaultValue={key?.answer}
                                          >
                                            {key?.options?.map((option, j) => (
                                              <FormControlLabel
                                                key={j}
                                                value={option.title}
                                                control={<Radio />}
                                                label={option.title}
                                                disabled={true}
                                              />
                                            ))}
                                          </RadioGroup>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                  }
                                </div>
                                <div className="flex-preview-tab">
                                  {(aiFilled ? (
                                    <>
                                      <TextareaAutosize
                                        aria-label="Write here  "
                                        minRows={3}
                                        className="textarea-auto-size-answered"
                                        key={aiFilled ? "aiFilled" : "notAiFilled"} // Changing key forces re-render
                                        //  defaultValue={key.remark || ""}
                                        defaultValue={key.remark ? key.remark.split("Document")[0].trim() : ""}
                                        readOnly
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <TextareaAutosize
                                        aria-label="Write here"
                                        placeholder="Type your message here..."
                                        minRows={3}
                                        className="textarea-auto-size"
                                        disabled
                                        key={aiFilled ? "aiFilled" : "notAiFilled"} // Changing key forces re-render
                                      />
                                    </>
                                  ))}
                                  {/* <AttachFileIcon className="space-file" /> */}
                                </div>
                              </div>
                            ))}
                          </div> : null
                          }
                        </div>
                      </TabPanel>
                    ))}
                  </Box>
                </div>
              ) : (
                <div>
                  {/* <Box sx={{ flexGrow: 1, display: "flex" }}> */}
                  <Card sx={{ padding: "10px", "margin": "10px" }}>
                    <p>Questionnaire Data Not Found !</p>
                    {/* </Box>                       */}
                    <Grid item xs={12} md={12} lg={12}>
                      <Link to={`../vendor-information/${vendorUrl}`}>
                        <Button variant="contained" className="click-to-begin m-l cancel-btn">Previous </Button>
                      </Link>
                    </Grid>
                  </Card>

                </div>
              )
            }
          </div>
        </div>
        <ModalDialog handleClose={handleClose} open={open} onSubmit={do_saved} contentData={ModalContent} />
      </Container>

      <Dialog open={openPop} onClose={handleCloseNew} className="fullpopup-send-assessment">
        <div className="inner-popup-design">
          <div>
            <img src={AnimateIcond} alt="gif" />
            <h2>Assessment sent!</h2>
            <p>Your assessment has been sent to the vendor. We'll keep you updated on their progress</p>
            <Button onClick={handleFinalClick} className="color-ok-pop">Ok</Button>
          </div>
        </div>
      </Dialog>

      <ModalDialog
        handleClose={() => setShowConfirmModal(false)}
        open={showConfirmModal}
        onSubmit={() => do_saved()}
        contentData={ModalContentAssessment()}
      />
    </>
  );
}
