import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Container,
  // FormControlLabel,
  Grid,
  // InputAdornment,
  // MenuItem,
  // Radio,
  // RadioGroup,
  // Select,
  Stack,
  Card,
  TextField,
  Typography,
  Checkbox
} from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import UserProfile from "../assets/user-profile.svg";
import Upload from "../assets/Vectorplus.svg";
import MuiPhoneNumber from "material-ui-phone-number";
import Loader from "../Loader";
import { useSnackbar } from 'notistack';
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import { LoadingButton } from "@mui/lab";
import { updateSubscriptionDetails } from "../redux/subscriptionDetailsSlice";
import { useDispatch } from "react-redux";

const AddEditUserPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  var clientDetail = location.state;
  const { id } = useParams();
  const [showLoader, setShowLoader] = useState(false)
  const [formData, setFormData] = useState({
    logo: null,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
    twoFAMethod: "email",
    permissions: [],
  });
  const [permissionList, setPermissionList] = useState({})
  useEffect(() => {

    // console.log('location', location)
    getUserPermissionData()
    if (id)
      getClientUserDetail()
  }, []);

  const getClientUserDetail = async () => {
    await GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_USER_DETAIL}?userId=${id}`,
      {}
    )
      .then((resChart) => {
        let data = resChart.data.data;
        if (!data) return;
        setFormData({ ...formData, ...data, email: data.emailId, logo: data.profilePic })
        console.log('userdetail', data)
        setShowLoader(false);
      })
      .catch((errChart) => {
        enqueueSnackbar(errChart.message, { variant: 'error' });
        setShowLoader(false);
      })
  }

  const getUserPermissionData = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_PERMISSION_MAPPING_DATA}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data;
            if (!data) return;
            setPermissionList(data)
            setShowLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
  const handleImageChange = async (e) => {
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/JPEG",
      "image/JPG",
      "image/PNG",
    ];
    const file = e.target.files[0];
    if (!allowedFileTypes.includes(file.type)) {
      enqueueSnackbar("Please upload only supported formats: JPEG, PNG", {
        variant: "error",
      });
      return;
    }
    if (file) setFormData({ ...formData, logo: file })
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobile: value })
  };

  const handleCheckboxChange = (e, type) => {
    const { name, checked } = e.target;
    let mappedString = name?.toLowerCase()?.split(' ')?.map((word, i) => i === 0 ? word : word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join('')
    if (
      ["Dashboard", "Ecosystem", "Attack Surface", "Questionnaire", "Assessment", "Chat"].includes(name) &&
      Array.isArray(permissionList[mappedString])
    ) {
      if (checked) {
        setFormData({ ...formData, permissions: [...new Set([...formData.permissions, ...permissionList[mappedString], name])] })
      } else {
        setFormData({ ...formData, permissions: [...formData.permissions?.filter((item) => !permissionList[mappedString]?.includes(item))] })
      }
    }
    else if (checked && !formData.permissions?.includes(name)) setFormData({ ...formData, permissions: [...new Set([...formData.permissions, name, type])] })
    else if (!checked && formData.permissions?.includes(name)) setFormData({ ...formData, permissions: [...formData.permissions?.filter((item) => item !== name)] })
  };

  const checkValidation = () => {
    if (!formData.firstName || formData.firstName?.length < 3) {
      enqueueSnackbar("Please provide valid first name", { variant: "error" });
      return false;
    }
    if (!formData.lastName || formData.lastName?.length < 3) {
      enqueueSnackbar("Please provide valid last name", { variant: "error" });
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      enqueueSnackbar("Please provide valid email", { variant: "error" });
      return false;
    }
    if (!formData.mobile) {
      enqueueSnackbar("Please provide phone number", { variant: "error" });
      return false;
    }
    console.log(formData.permissions.length,"formData.permissions.length")
    if (formData.permissions.length===0) {
      enqueueSnackbar("Please Select any Permission for User", { variant: "error" });
      return false;
    }
    return true;
  }
  const handleSubmit = async () => {
    if (checkValidation()) {
      console.log('clientDetail', clientDetail)
      setShowLoader(true);
      let payload = new FormData();
      if (id) payload.append("clientUserId", id);
      payload.append("clientId", clientDetail.clientId ? clientDetail.clientId : clientDetail.clientIdString)
      payload.append("firstName", formData.firstName);
      payload.append("lastName", formData.lastName);
      payload.append("email", formData.email);
      payload.append("mobile", formData.mobile);
      payload.append("twoFAMethod", formData.twoFAMethod);
      payload.append("role", formData.role);
      payload.append("logo", formData.logo);
      payload.append("permissions", JSON.stringify(formData.permissions));

      try {
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        if (refreshToken) {
          await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_MODIFY_CLIENT_USER}`,
            payload
          )
            .then((resChart) => {
              enqueueSnackbar(resChart.data?.message, { variant: "success" });
              setShowLoader(false);
              setFormData({});
              if(!id)dispatch(updateSubscriptionDetails({id : "users" }));
              navigate(-1);
            })
            .catch((errChart) => {
              enqueueSnackbar(errChart.message, { variant: "error" });
              setShowLoader(false);
            });
        } else {
          setShowLoader(false);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
        setShowLoader(false);
      }
    }
  }
  return (
    <>
      <Loader show={showLoader} />
      <Container maxWidth={false}>
        <Grid container spacing={0.5} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={12}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                src={
                  typeof clientDetail?.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${clientDetail?.logo}`
                    : UserProfile
                }
                alt="login"
                onError={(e) => e.target.src = UserProfile}
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              <h3>{clientDetail?.company}</h3>
              <p>
                <PermIdentityIcon />
                {clientDetail?.name}
              </p>
            </div>
          </div>
        </Grid>
          <Grid item xs={12} sm={12} md={6} lg={12} sx={{ mt: 2 }}>
          <Typography variant="h4">
            <span className="heading-main">
              {id ? "Edit User" : "Add User"}
            </span>
              </Typography>
            </Grid>
      </Grid>
      <Card sx={{ p: 3, pt:4, }}>
        <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} lg={2} className="center-upload">
            <div className="add-client-logo">
              <Box
                component="img"
                alt="Profile Image"
                src={
                  typeof formData.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                    : formData.logo
                      ? URL.createObjectURL(formData.logo)
                      : UserProfile
                }
                onError={(e) => e.target.src = UserProfile}
              />
            </div>
            <Button
              htmlFor="file-upload"
              size="small"
              variant="contained"
              color="primary"
              component="label"
              className="upload-new-plus"
              title="Select Image"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img src={Upload} alt="icon" />
                <span>Upload Profile</span>
              </div>
            </Button>
            <input
              id="file-upload"
              type="file"
              accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Stack spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <TextField
                    name="firstName"
                    value={formData.firstName || ""}
                    label="First Name"
                    required
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    required
                    value={formData.lastName || ""}
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={id ? true : false}
                      name="email"
                      value={formData.email || ""}
                      label="Email"
                      required
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <MuiPhoneNumber
                    InputComponent={(params) => <TextField {...params} />}
                    variant="outlined"
                    label="Phone Number"
                    required
                    defaultCountry={"us"}
                    value={formData.mobile}
                    onChange={(value) => {
                      handlePhoneChange(value);
                    }}
                    disableAreaCodes={true}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <TextField
                    name="role"
                    label="Role"
                    // required
                    value={formData.role || ""}
                    inputProps={{ maxLength: 40 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} pb={1} className="align-justifys">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      className="gender"
                      sx={{
                        // color: "#242526",
                        fontFamily: "Poppins",
                      }}
                    >
                      2FA Method :

                    </Box>
                    <div className="switch-flex">
                      <span>Email</span>
                      <label className="switch">
                        <input type="checkbox" onChange={(e) => setFormData({ ...formData, twoFAMethod: !e.target.checked ? "email" : "sms" })} />
                        <span className="slider"></span>
                      </label>
                      <span>SMS</span>                    
                    </div>                   
                  </Box>
                </Grid>
              </Grid>
            </Stack>           
          </Grid>
        </Grid>
        </Box>
      </Card>

      <Grid container spacing={0.5} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={12} sx={{ mt: 2 }} >
          <Typography variant="h4">
            <span className="heading-main">
              Permissions
            </span>
          </Typography>
          <p>
            Define the permission for this user.
          </p>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box>
                {permissionList.dashboard?.map((item) => (
                  <Box className="d-flex-checkbox" sx={item === "Dashboard" ? { fontWeight: "bold" } : { ml: 2 }}>
                    <Checkbox
                      checked={formData.permissions?.includes(item)}
                      onChange={(e) => handleCheckboxChange(e, "Dashboard")}
                      name={item}
                      color="primary"
                      sx={item === "Dashboard" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26,
                        },
                      } : {}}
                    />
                    <Box >
                      {item}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box>
                {permissionList.ecosystem?.map((item) => (
                  <Box className="d-flex-checkbox" sx={item === "Ecosystem" ? { fontWeight: "bold", } : { ml: 2 }}>
                    <Checkbox
                      checked={formData.permissions?.includes(item)}
                      onChange={(e) => handleCheckboxChange(e, "Ecosystem")}
                      name={item}
                      color="primary"
                      sx={item === "Ecosystem" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26, // larger checkmark icon                        
                        },
                      } : {}}
                    />
                    <Box >
                      {item}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {permissionList.attackSurface?.map((item) => (
                <Box className="d-flex-checkbox" sx={item === "Attack Surface" ? { fontWeight: "bold" } : { ml: 2 }}>
                  <Checkbox
                    checked={formData.permissions?.includes(item)}
                    onChange={(e) => handleCheckboxChange(e, "Attack Surface")}
                    name={item}
                    color="primary"
                    sx={item === "Attack Surface" ? {
                      '& .MuiSvgIcon-root': {
                        fontSize: 26, // larger checkmark icon
                        fontWeight: "bold"
                      },
                    } : {}}
                  />
                  <Box >
                    {item}
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box>
                <Box sx={{ marginBottom: "45px" }}></Box>
                {permissionList.questionnaire?.map((item) => (
                  <Box className="d-flex-checkbox" sx={item === "Questionnaire" ? { fontWeight: "bold" } : { ml: 2 }}>
                    <Checkbox
                      checked={formData.permissions?.includes(item)}
                      onChange={(e) => handleCheckboxChange(e, "Questionnaire")}
                      name={item}
                      color="primary"
                      sx={item === "Questionnaire" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26, // larger checkmark icon                        
                        },
                      } : {}}
                    />
                    <Box >
                      {item}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box sx={{ marginBottom: "45px" }}></Box>
              {permissionList.assessment?.map((item) => (
                <Box className="d-flex-checkbox" sx={item === "Assessment" ? { fontWeight: "bold" } : { ml: 2 }}>
                  <Checkbox
                    checked={formData.permissions?.includes(item)}
                    onChange={(e) => handleCheckboxChange(e, "Assessment")}
                    name={item}
                    color="primary"
                    sx={item === "Assessment" ? {
                      '& .MuiSvgIcon-root': {
                        fontSize: 26, // larger checkmark icon
                        fontWeight: "bold"
                      },
                    } : {}}
                  />
                  <Box>
                    {item}
                  </Box>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box sx={{ marginBottom: "45px" }}></Box>
              {permissionList.chat?.map((item) => (
                <div className="d-flex-checkbox">
                  <Checkbox
                    checked={formData.permissions?.includes(item)}
                    onChange={handleCheckboxChange}
                    name={item}
                    color="primary"
                    sx={item === "Chat" ? {
                      '& .MuiSvgIcon-root': {
                        fontSize: 26, // larger checkmark icon                        
                      },
                    } : {}}
                  />
                  <Box sx={item === "Chat" ? { fontWeight: "bold" } : {}}>
                    {item}
                  </Box>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: "2%", my: 2 }}>
        <Button
          variant="contained"
          className="edit-myaccount-button"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <LoadingButton
          size="medium"
          variant="contained"
          color="success"
          className="edit-myaccount-button"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
        </Box>
     </Container>
    </>
  );
};

export default AddEditUserPage;
