import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/searchico.svg";
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Box,
  InputAdornment,
  Typography,
  IconButton
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import PostRequest from "../../components/apiConnections/postRequest";
import GetRequest from "../../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import { CustomNoRowsOverlay } from "../Assessment";
function RequestsDocument() {
  const { enqueueSnackbar } = useSnackbar();
  const [requestList, setRequestList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filterSearch, setFilterSearch] = useState(null)
  const [activeDocumentDetails, setActiveDocumentDetails] = useState({ _id: null })
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      alert("Please upload a valid PDF file.");
      e.target.value = null;
    }
  }

  const handleSelectClick = (id) => {
    setActiveDocumentDetails({
      _id: id
    })
    setSelectedFile(null)
  }

  const handleConfirmUpload = (id) => {
    if (activeDocumentDetails._id !== id) {
      enqueueSnackbar("row mismatch", { variant: "error" });
      return;
    }
    if (!selectedFile) {
      enqueueSnackbar("please select valid file", { variant: "error" });
      return;
    }
    UploadSelectedDocument()
  }
  const columns = [
    { field: "id", flex: 0.5, headerName: "S. No", width: 100 },
    { field: "documentName", flex: 1, headerName: "Document Name", width: 200 },
    { field: "clientName", flex: 1, headerName: "Client Name", width: 100 },
    { field: "requestedBy", flex: 1, headerName: "Client Email", width: 80 },
    { field: "requestedAt", flex: 1, headerName: "Requested At", width: 100 },
    {
      field: "action", flex: 1.3, headerName: "Upload", width: 200,
      renderCell: (params) => {
        console.log(params.row)
        return <>
          <div>
            {(activeDocumentDetails?._id == params.row?._id) && selectedFile?.name ?
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={selectedFile.name}
                >
                  {selectedFile.name}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => handleConfirmUpload(params.row?._id)}
                  aria-label="confirm upload"
                >
                  <UploadIcon />
                </IconButton>
              </Box> :
              <>  <input
                type="file"
                id="file-input"
                accept="application/pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
                < label htmlFor="file-input">
                  <Button variant="contained" color="primary" component="span" onClick={() => handleSelectClick(params.row?._id)}>
                    Select File
                  </Button>
                </label>
              </>
            }
          </div >
        </>
      }
    }
  ];

  useEffect(() => {
    getRequestedDocuments(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getRequestedDocuments(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])

  const getRequestedDocuments = async (page, limit) => {
    setShowLoader(true);
    setRequestList([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REQUESTED_DOCUMENT_BY_VENDOR}?page=${page
          }&limit=${limit}&search=${filterSearch || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setRowCount(arrResponse?.total || 0);
            if (Array.isArray(arrResponse?.data)) setRequestList(
              arrResponse?.data?.length
                ? [
                  ...arrResponse?.data?.map((d, i) => ({
                    ...d,
                    id: ((page - 1) * limit) + (i + 1),
                    documentName: d.documentDetail?.name || "",
                    clientName: d.requestedBy?.firstName || "",
                    requestedBy: d.requestedBy?.emailId || "",
                    requestedAt: moment(d.createdAt)?.format("DD-MMM-YYYY")
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errVendor) => {
            // enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }

  const UploadSelectedDocument = async () => {
    setShowLoader(true);
    let formData = new FormData();
    formData.append("requestedId", activeDocumentDetails?._id)
    formData.append("vendorDocumentFiles", selectedFile)

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_REQUESTED_DOCUMENT_BY_VENDOR}`,
          formData
        )
          .then((resp) => {
            if (resp.data.code === 200) {
              getRequestedDocuments(paginationModel.page + 1, paginationModel.pageSize)
              enqueueSnackbar(resp.data.message, { variant: "success" });
              setSelectedFile(null)
              setActiveDocumentDetails({ _id: null })
            } else {
              enqueueSnackbar(resp.data.message, { variant: "error" });
            }
            setShowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  return (
    <>
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "6px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
              // value={paginationModel.pageSize}
              // onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>
          </div>
          <Box
            sx={{
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                minWidth: "100%",
                overflowX: "hidden",
              },
              "& .MuiDataGrid-virtualScroller": {
                minWidth: "100%",
                overflowX: "auto",
              },
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search…"
              value={filterSearch}
              onChange={handleSearch}
              className="searh-text-field"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="icon" />
                  </InputAdornment>
                ),
              }}
            />
            <DataGrid
              loading={showLoader}
              sx={{ border: "none", height: "409px", width: "100%" }}
              rows={requestList}
              rowCount={rowCount}
              columns={columns}
              // pageSize={pageSize}
              rowHeight={70}
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20]}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              disableSelectionOnClick
              autoHeight
              className="datagrid-table"
              autosizeOptions={{
                includeOutliers: true,
                includeHeaders: false,
              }}
              pageSizeOptions={[5, 10, 20]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </Box>
        </div>
      </Card>
    </>
  );
}

export default RequestsDocument;
