import React from 'react'
import PlusVendor from '../../../assets/plus-vendor.svg';
import HamburgerMenu from "../../../pages/HamburgerMenu.js";
import { Typography } from '@mui/material';
import AccountPopover from './AccountPopover';
import { useState } from 'react';
import DomainSearch from '../../../components/addVendorByClient/domainSearch';
import GlobalSearchBar from '../../../pages/globalSearchBar.js';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
function NewHeader({ titleName }) {
   const [showAddButton, setShowAddButton] = useState((localStorage.getItem("roleId") === "1") ? true : false);
   const { enqueueSnackbar } = useSnackbar();
   let subscriptionDetails = useSelector((state) => state.subscriptionDetails.subscriptionDetails) || [];
   const [modelOpen, setModelOpen] = useState(false);
   const handleModelOpen = () => {
      const details = subscriptionDetails?.find(sub => sub.id === 'vendors' ); 
      if(details?.total_added >=  details?.subscribtion_value){
         enqueueSnackbar("Sorry, you have complete the limit for this in subscription.", { variant: "error" });
         return; 
      }
      setModelOpen(true);
   }
   const handleModelClose = () => setModelOpen(false);
   let selector = useSelector((state) => state.permissionList.permissionList) || [];
   let roleId = window.localStorage.getItem('roleId');
   const checkPermission = (value) => {
      if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
         return true;
      }
      else if (selector.includes(value)) return true;
      else return false;
      // return Array.isArray(selector) && selector.includes(value);
   }
   return (
      <>
         <div className='newheader'>
            <Typography variant="h4" sx={{ mt: 0, mb: 0 }}>
               <span className="ml25 new-heading-front">
                  <HamburgerMenu />
                  <span>{titleName}</span></span>
            </Typography>


            <div style={{ display: 'flex', alignItems: 'center' }}>
               {showAddButton && checkPermission("Add Vendor") ?
                  <div className='add-vendor-button-header' onClick={handleModelOpen}>
                     <img src={PlusVendor} alt='icon' /> Add Vendor
                  </div> : null
               }
               {showAddButton && <GlobalSearchBar />}
               <AccountPopover />
            </div>



         </div>
         <DomainSearch
            open={modelOpen}
            handleClose={handleModelClose} />
      </>
   )
}

export default NewHeader