import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/searchico.svg";
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Box,
  InputAdornment,
  Typography,
  IconButton
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import PostRequest from "../../components/apiConnections/postRequest";
import GetRequest from "../../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import { CustomNoRowsOverlay } from "../Assessment";
function UploadDocuments() {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadList, setUploadList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filterSearch, setFilterSearch] = useState(null)

  const columns = [
    { field: "id", flex: 0.5, headerName: "S. No", width: 100 },
    { field: "documentName", flex: 1, headerName: "Document Name", width: 200 },
    { field: "clientName", flex: 1, headerName: "Client Name"},
    { field: "clientEmail", flex: 1, headerName: "Client Email"},
    { field: "uploadedBy", flex: 1, headerName: "Uploaded By", width: 100 },
    { field: "uploadedAt", flex: 1, headerName: "Uploaded At", width: 100 },
    {
      field: "action", flex: 1, headerName: "View", width: 100,
      renderCell: (params) => {
        return <>
          <div>
            <Button variant="contained" color="primary" component="span" onClick={() =>
              params.row?.fileName ? window.open(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row?.fileName}`,
                "_blank") : null} >
              View File
            </Button>
          </div >
        </>
      }
    }
  ];

  useEffect(() => {
    getUploadDocuments(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getUploadDocuments(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])

  const getUploadDocuments = async (page, limit) => {
    setShowLoader(true);
    setUploadList([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_UPLOADED_DOCUMENTS_BY_VENDOR}?page=${page
          }&limit=${limit}&search=${filterSearch || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setRowCount(arrResponse?.total || 0);
            if (Array.isArray(arrResponse?.data)) setUploadList(
              arrResponse?.data?.length
                ? [
                  ...arrResponse?.data?.map((d, i) => ({
                    ...d,
                    id: ((page - 1) * limit) + (i + 1),
                    fileName: d.documentName || "",
                    clientName: d.clientDetail?.firstName || "",
                    clientEmail: d.clientDetail?.emailId,
                    documentName: d.documentDetail?.name || "",
                    uploadedBy: d.uploadedBy?.emailId || "",
                    uploadedAt: moment(d.createdAt)?.format("DD-MMM-YYYY")
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errVendor) => {
            // enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  return (
    <>
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "6px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
              // value={paginationModel.pageSize}
              // onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>
          </div>
          <Box
            sx={{
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                minWidth: "100%",
                overflowX: "hidden",
              },
              "& .MuiDataGrid-virtualScroller": {
                minWidth: "100%",
                overflowX: "auto",
              },
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search…"
              value={filterSearch}
              onChange={handleSearch}
              className="searh-text-field"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="icon" />
                  </InputAdornment>
                ),
              }}
            />
            <DataGrid
              loading={showLoader}
              sx={{ border: "none", height: "409px", width: "100%" }}
              rows={uploadList}
              rowCount={rowCount}
              columns={columns}
              // pageSize={pageSize}
              rowHeight={70}
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20]}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              disableSelectionOnClick
              autoHeight
              className="datagrid-table"
              autosizeOptions={{
                includeOutliers: true,
                includeHeaders: false,
              }}
              pageSizeOptions={[5, 10, 20]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </Box>
        </div>
      </Card>
    </>
  );
}

export default UploadDocuments;
