import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  FormControl,
  Container,
  Grid,
  Stack,
  Card,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,IconButton,Menu,
} from "@mui/material";
import NewHeader from "../../layouts/dashboard/header/NewHeader";
import Breadcrumb from "../../pages/VendorDetail/Breadcrumb.js";
import Tab from "@mui/material/Tab";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EyeMenu from '../../assets/eye-menu.svg';
import Trash from '../../assets/trahs.svg';
import VulnerabilityManagementIssue from "./VulnerabilityManagementIssue.js";
import VulnerabilityManagementCVE from "./VulnerabilityManagementCVE.js";
import "../css/vulnerability.css";

function VulnerabilityManagement() {
  const [value, setValue] = React.useState("1");
  const breadcrumbs = [
    { label: "Ecosystem", path: "/" },
    { label: "Security Profile", path: "/" },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Vulnerability | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Vulnerability" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            {/* <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
              </div>
            </div> */}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TabContext value={value}>
              <Box className="first_tab">
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  className="tab-main-heading margin-mui-tabfix"
                  aria-label="lab API tabs example"
                  sx={{ mt: 0 }}
                >
                  <Tab
                    label="Issue"
                    value="1"
                    className="active-color-remove second-mui"
                  />
                  <Tab
                    label="CVE"
                    value="2"
                    className="active-color-remove second-mui"
                  />
                </TabList>
                <TabPanel value="1">
                    <VulnerabilityManagementIssue/>
                </TabPanel>
                <TabPanel value="2">
                 <VulnerabilityManagementCVE/>           
                </TabPanel>
               </Box>
             </TabContext>
           </Grid>
         </Grid>
      </Container>
    </>
  );
}

export default VulnerabilityManagement;
