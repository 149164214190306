import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import {
  Card,
  Container,
  Grid,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import "./css/Dashboard.css";
import ReportChart from "./ReportChart";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from "../components/apiConnections/getRequest";
import IconDashbaord from "../assets/icon-third-party.svg";
import UpIcon from "../assets/up-icon.svg";
import DownIcon from "../assets/downside-arrow.svg";
import SmallDownSide from "../assets/small-down-side.svg";
import Chart from "react-apexcharts";
import ScoreUp from "../assets/score-icon.svg";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import NewOverall from "../sections/@dashboard/app/NewOverall.js";
import defaultLogo from "../assets/companyLogo.jpg";
import { AppWebsiteVisits } from "../sections/@dashboard/app";
import { useTheme } from "@mui/material/styles";
import colors from "../utils/colorcode.js";
import { useSelector } from "react-redux";
import GaugeComponent from "react-gauge-component";
import SunbrustChart from "./SunburstChart.js";
import HeatMapChart from "./HeatMapChart.js";
import VerticalChartBarForIssue from "./VerticalChartBarForIssue.js";
import VerticalChartBarForCVE from "./VerticalChartBarForCVE.js";
const dummyThirdPartyRisk = [
  {
    id: "001",
    isVendorDetailAvailable: 1,
    logo: "https://logo.clearbit.com/apple.com",
    name: "apple.com",
    percentageChangeInScore: "2.50",
    previousScore: 88,
    score: 90,
    status: 1,
    target: "apple.com",
  },
  {
    id: "002",
    isVendorDetailAvailable: 1,
    logo: "https://logo.clearbit.com/tesla.com",
    name: "tesla.com",
    percentageChangeInScore: "-1.20",
    previousScore: 75,
    score: 74,
    status: 1,
    target: "tesla.com",
  },
  {
    id: "003",
    isVendorDetailAvailable: 1,
    logo: null, // No logo, should trigger fallback
    name: "spacex.com",
    percentageChangeInScore: "0.00",
    previousScore: 65,
    score: 65,
    status: 1,
    target: "spacex.com",
  },
  {
    id: "004",
    isVendorDetailAvailable: 1,
    logo: "https://logo.clearbit.com/nike.com",
    name: "nike.com",
    percentageChangeInScore: "1.80",
    previousScore: 60,
    score: 61,
    status: 1,
    target: "nike.com",
  },
  {
    id: "005",
    isVendorDetailAvailable: 1,
    logo: null, // No logo, should trigger fallback
    name: "adidas.com",
    percentageChangeInScore: "-3.50",
    previousScore: 70,
    score: 67,
    status: 1,
    target: "adidas.com",
  },
];

export default function DashboardAppPage() {
  let selector = useSelector((state) => state.permissionList.permissionList);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [totalApps, setTotalApps] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [completedAssessment, setCompletedAssessment] = useState(0);
  const [totalAssessments, setTotalAssessments] = useState(0);
  const [delayedAssessment, setDelayedAssessment] = useState(0);
  const [ongoingAssessment, setOngoingAssessment] = useState(0);
  // const [totalAssessment, setTotalAssessment] = useState();
  const [severityIssue, setSeverityIssue] = useState({});
  const [thirdPartyRisk, setThirdPartyRisk] = useState([]);
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([]);
  const [allAssessmentsCount, setAllAssessmentsCount] = useState([]);
  const [totalScore, setTotalScore] = useState([]);
  const [option, setOption] = useState(365);
  const [issueSeverityLoader, setIssueSeverityLoader] = useState(false);
  const [thirdPartyRiskLoader, setThirdPartyRiskLoader] = useState(false);
  const [chartDataLoader, setChartDataLoader] = useState(false);
  const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
  const [thirdPartyRiskConfig, setThirdPartyRiskConfig] = useState([]);
  const [percentageChangeInVendor, setPercentageChangeInVendor] = useState(0);
  const [submittedAssessment, setSubmittedAssessment] = useState(0);
  const [dashboardCardData, setDashboardCardData] = useState("");
  const [riskTitle, setRiskTitle] = useState("");
  const [heatMapData, setHeatMapData] = useState({})
  const [sunbrustChartData, setSunbrustChartData] = useState({});
  const [sunbrustChartLoader, setSunbrustChartLoader] = useState({});

  useEffect(() => {
    getChartData();
  }, [option]);

  useEffect(() => {
    getDashboardCardData();
    getIssueSeverityData();
    // getThirdPartyAtRiskData();
    getVendorDetails();
    getSunburstChartData();
  }, []);
  const totalIssuesChange = async (event) => {
    // 👇 Get input value from "event"
    setOption(event.target.value);
  };

  const getChartData = async () => {
    setChartDataLoader(true);
    const payload = {
      selectedDays: option,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_LAST_N_DAYS_ISSUES;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((resChart) => {
            let response = resChart.data.data;
            let allDates = [];
            let allIssues = [];
            if (response?.totalIssue) {
              response?.totalIssue.map((datas) => {
                allDates.push(datas.date);
                allIssues.push(datas.calculateIssue);
              });
              setGraphDates(allDates);
              setGraphValues(allIssues);
              // setGraphValues([10,4,5,7]);

              setChartDataLoader(false);
            } else {
              // enqueueSnackbar("No data found for monthly chart.", {
              //   variant: "error",
              // });
              setChartDataLoader(false);
            }

            if (response?.totalAssessment) {
              let allAssessment = [];
              const totalAssessments = response?.totalAssessment || [];
              totalAssessments.forEach((assessment) => {
                allAssessment.push(assessment.count);
              });
              setAllAssessmentsCount(allAssessment);
              // setAllAssessmentsCount([8,7,9,10]);
            }

            if (response?.totalScore) {
              let totalScore = [];
              const score = response?.totalScore || [];
              score &&
                score.length > 0 &&
                score.forEach((score) => {
                  totalScore.push(score.count);
                });
              setTotalScore(totalScore);
              // setAllAssessmentsCount([8,7,9,10]);
            }
          })
          .catch((errChart) => {
            // enqueueSnackbar(errChart.message, { variant: "error" });
            setChartDataLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setChartDataLoader(false);
    }
  };

  const getDashboardCardData = async () => {
    setDashboardDataLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_DASHBOARD_CARD_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          setDashboardCardData(resDashboard.data.data);
          const response = resDashboard?.data?.data;
          setTotalIssues(response?.totalIssues);
          setTotalApps(response?.totalApps);
          setPercentageChangeInVendor(response?.percentageChangeInVendor);
          setTotalAssessments(response?.totalAssessments || 0);
          setCompletedAssessment(response?.completedAssessment || 0);
          setDelayedAssessment(response?.delayedAssessment || 0);
          setOngoingAssessment(response?.ongoingAssessment || 0);
          setSubmittedAssessment(response?.submittedAssessment || 0);
          setDashboardDataLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setDashboardDataLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setDashboardDataLoader(false);
    }
  };

  const getIssueSeverityData = async () => {
    setIssueSeverityLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_ISSUE_SEVERITY_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setSeverityIssue(response);
          setIssueSeverityLoader(false);
        })
        .catch((errDashboard) => {
          // enqueueSnackbar(errDashboard.message, { variant: "error" });
          setIssueSeverityLoader(false);
        });
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setIssueSeverityLoader(false);
    }
  };

  const getSunburstChartData = async () => {
    setSunbrustChartLoader(true);
    try {
      // const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
      const endpoint = "get-sunburst-chart-data";

      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          console.log("endpoint", response);
          setSunbrustChartData(response);
          setSunbrustChartLoader(false);

        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setSunbrustChartLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setSunbrustChartLoader(false);
    }
  };

  // const getThirdPartyAtRiskData = async () => {
  //   setThirdPartyRiskLoader(true);
  //   try {
  //     const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
  //     await GetRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
  //     )
  //       .then((resDashboard) => {
  //         const response = resDashboard.data.data;
  //         setThirdPartyRisk(response);
  //         let arr = [
  //           { vendor: "Critical", score: 0 },
  //           { vendor: "High", score: 0 },
  //           { vendor: "Medium", score: 0 },
  //           { vendor: "Low", score: 0 },
  //         ];
  //         for (let item of response) {
  //           switch (true) {
  //             case item.score <= 25: arr[0].score++;
  //               break;
  //             case item.score <= 50: arr[1].score++;
  //               break;
  //             case item.score <= 75: arr[2].score++;
  //               break;
  //             case item.score <= 100: arr[3].score++;
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //         setThirdPartyRiskConfig([...arr]);
  //         setThirdPartyRiskLoader(false);
  //       })
  //       .catch((errDashboard) => {
  //         enqueueSnackbar(errDashboard.message, { variant: "error" });
  //         setThirdPartyRiskLoader(false);
  //       });
  //   } catch (error) {
  //     enqueueSnackbar(error.message, { variant: "error" });
  //     setThirdPartyRiskLoader(false);
  //   }
  // };

  const handleHeatMapData = (vendors) => {
    const vendorList = Array.isArray(vendors) ? vendors : [];
    let dataObj = {
      "low-low": 0,
      "low-medium": 0,
      "low-high": 0,
      "low-critical": 0,
      "medium-low": 0,
      "medium-medium": 0,
      "medium-high": 0,
      "medium-critical": 0,
      "high-low": 0,
      "high-medium": 0,
      "high-high": 0,
      "high-critical": 0,
      "critical-low": 0,
      "critical-medium": 0,
      "critical-high": 0,
      "critical-critical": 0,
    }
    let getCategory = (score) => {
      if (score <= 25) return "critical";
      else if (score <= 50) return "high";
      else if (score <= 75) return "medium";
      else if (score <= 100) return "low";
    }
    for (let vendor of vendorList) {
      const { score, relationshipRiskScore } = vendor;
      if (typeof score !== "number" || score < 0 || score > 100 ||
        typeof relationshipRiskScore !== "number" || relationshipRiskScore < 0 || relationshipRiskScore > 100) continue;
      dataObj[`${getCategory(vendor?.score)}-${getCategory(vendor?.relationshipRiskScore)}`]++;
    }
    setHeatMapData(dataObj)
  }

  const getVendorDetails = async (page, limit) => {
    setThirdPartyRiskLoader(true);
    // setVendorList([])
    const role = parseInt(window.localStorage.getItem("roleId"));
    const endpoint =
      role === 2
        ? process.env.REACT_APP_VENDORS_LIST
        : process.env.REACT_APP_THIRD_PARTY_AT_RISK_DASHBOARD;

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            const response = resVendors.data.data.data;

            if (response && response.length > 0) {
              handleHeatMapData(response)
              const updatedResponse = response.map((vendor, index) => {
                if (vendor.score && vendor.previousScore) {
                  // Ensure both scores exist to avoid NaN or division by zero
                  // console.log("Valid scores:", index,vendor.score, vendor.previousScore);
                  vendor.percentageChangeInScore = (
                    (Number(vendor.score - vendor.previousScore) /
                      Number(vendor.previousScore)) *
                    100
                  ).toFixed(2);
                } else {
                  // console.log("Invalid scores:", vendor.score, vendor.previousScore);
                  vendor.percentageChangeInScore = 0; // Default to 0 if scores are invalid
                }
                return vendor;
              });
              setThirdPartyRisk(updatedResponse);
            }

            let arr = [
              { vendor: "Critical", score: 0 },
              { vendor: "High", score: 0 },
              { vendor: "Medium", score: 0 },
              { vendor: "Low", score: 0 },
            ];
            for (let item of response) {
              switch (true) {
                case item.score <= 25:
                  arr[0].score++;
                  break;
                case item.score <= 50:
                  arr[1].score++;
                  break;
                case item.score <= 75:
                  arr[2].score++;
                  break;
                case item.score <= 100:
                  arr[3].score++;
                  break;
                default:
                  break;
              }
            }
            setThirdPartyRiskConfig([...arr]);
            // setRowCount(arrResponse?.total || 0);
            // if (Array.isArray(arrResponse?.data)) setVendorList(
            //   arrResponse?.data?.length
            //     ? [
            //       ...arrResponse?.data?.map((d, i) => ({
            //         ...d,
            //         id: i + 1,
            //         name:
            //           d.name
            //             ?.substring(0, d.name?.indexOf("."))
            //             .charAt(0)
            //             .toUpperCase() +
            //           d.name
            //             ?.substring(0, d.name?.indexOf("."))
            //             .substr(1)
            //             .toLowerCase(),
            //         risk:
            //           d.score == "-" ? "-" :
            //             d.score <= 25
            //               ? "Critical"
            //               : d.score <= 50
            //                 ? "High"
            //                 : d.score <= 75
            //                   ? "Medium"
            //                   : "Low",
            //       })),
            //     ]
            //     : []
            // );
            setThirdPartyRiskLoader(false);
          })
          .catch((errVendor) => {
            // enqueueSnackbar(errVendor.message, { variant: "error" });
            setThirdPartyRiskLoader(false);
          });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setThirdPartyRiskLoader(false);
    }
  };

  /*First chart*/
  const chartOptions = {
    chart: {
      type: "donut",
      width: "345",
      dropShadow: {
        enabled: true,
        color: "#000", // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25, // Opacity of the shadow
      },
    },
    labels: [
      `Critical ${severityIssue?.critical || 0}`,
      `High ${severityIssue?.high || 0}`,
      `Medium ${severityIssue?.medium || 0}`,
      `Low ${severityIssue?.low || 0}`,
    ],
    colors: [colors.RED, colors.ORANGE, colors.YELLOW, colors.GREEN],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
        breakpoint: 1200,
        options: {
          chart: {
            width: 280,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "78%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 20,
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Mona-Sans, sans-serif",
              color: "#fff",
              offsetY: -20,
            },
            total: {
              show: true,
              label: "Total",
              fontSize: "18px",
              color: "#fff",
              fontFamily: "Mona-Sans, sans-serif",
              formatter: () => totalIssues,
              style: {
                fontFamily: "Mona-Sans, sans-serif",
                fontSize: "28px",
                color: "#fff",
              },
              offsetY: 50,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Mona-Sans, sans-serif",
        colors: ["#fff"],
      },
    },
    legend: {
      position: "right",
      offsetY: 0,
      height: 160,
      markers: {
        width: 11, // Width of the square marker
        height: 12, // Height of the square marker
        shape: "square", // Set the shape to square
        radius: 0, // No border-radius for square
        offsetX: -5,
      },
    },
  };

  const chartSeries = [
    severityIssue?.critical || 0,
    severityIssue?.high || 0,
    severityIssue?.medium || 0,
    severityIssue?.low || 0,
  ];
  /*Close first chart*/

  /*First chart*/
  const chartOptions2 = {
    chart: {
      type: "donut",
      width: "345",
      dropShadow: {
        enabled: true,
        color: "#000", // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25, // Opacity of the shadow
      },
    },
    labels: [
      `In-Progress ${ongoingAssessment || 0}`,
      `Delayed ${delayedAssessment || 0}`,
      `Submitted ${submittedAssessment || 0}`,
      `Completed ${completedAssessment || 0}`,
    ],
    colors: [colors.RED, colors.ORANGE, colors.YELLOW, colors.GREEN],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
        breakpoint: 1200,
        options: {
          chart: {
            width: 280,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "78%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 20, // Move the name (category) label down
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Mona-Sans, sans-serif",
              color: "#fff",
              offsetY: -19, // Move the value label up
            },
            total: {
              show: true,
              label: "Total",
              fontSize: "16px",
              color: "#fff",
              fontFamily: "Mona-Sans, sans-serif",
              formatter: () => totalAssessments,
              style: {
                fontFamily: "Mona-Sans, sans-serif",
                fontSize: "28px",
                color: "#fff",
              },
              offsetY: 50, // Move total label down
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Mona-Sans, sans-serif",
        colors: ["#fff"],
      },
    },
    legend: {
      position: "right",
      offsetY: -10,
      height: 150,
      markers: {
        width: 11, // Width of the square marker
        height: 12, // Height of the square marker
        shape: "square", // Set the shape to square
        radius: 0, // No border-radius for square
        offsetX: -5,
      },
    },
  };

  const chartSeries2 = [
    ongoingAssessment || 0,
    delayedAssessment || 0,
    submittedAssessment || 0,
    completedAssessment || 0,
  ];
  /*Close first chart*/
  const isChartSeriesNullDonut = chartSeries.every((value) => value === 0);
  const isChartSeries2NullDonut = chartSeries2.every((value) => value === 0);

  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ["#292154", "#292154", "#292154", "#292154"], // Light color to show "empty" donut
    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Mona-Sans, sans-serif",
              color: "#fff",
              offsetY: 9, // Move the value label up
            },
            total: {
              show: true,
              label: "No Data",
              fontSize: "16px",
              color: "#fff", // Muted color for "No Data" text
              fontFamily: "Mona-Sans, sans-serif",
              style: {
                fontFamily: "Mona-Sans, sans-serif",
                fontSize: "28px",
                color: "#fff", // Muted color for total value
              },
              formatter: () => "0", // Show "0" for total when no data
              offsetY: 30, // Move total label down
            },
          },
        },
      },
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    },
  });

  function getLableImage(value) {
    let cssClass = "";
    let dynamicLabelData = "-";

    if (value) {
      if (value <= 25) {
        cssClass = "criticalthirdparty"; // Critical
        dynamicLabelData = "Critical";
      } else if (value <= 50) {
        cssClass = "highthirdparty"; // High
        dynamicLabelData = "High";
      } else if (value <= 75) {
        cssClass = "mediumthirdparty"; // Medium
        dynamicLabelData = "Medium";
      } else {
        cssClass = "lowthirdparty"; // Low
        dynamicLabelData = "Low";
      }

      return (
        <span className={`newhomeclass ${cssClass}`}>
          <span>{dynamicLabelData}</span>
        </span>
      );
    }

    return "-"; // Default fallback if no value
  }

  const handleRiskTitle = (score) => {
    if (score <= 25) {
      setRiskTitle("Critical");
    } else if (score <= 50) {
      setRiskTitle("High");
    } else if (score <= 75) {
      setRiskTitle("Medium");
    } else {
      setRiskTitle("Low");
    }
  };

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    } else if (selector.includes(value)) return true;
    else return false;
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Dashboard" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              {/* <div className="top-heading-dashboard blur-box-cover-area"> */}
              {checkPermission("Total Third Party Apps") ? (
                <div className="top-heading-dashboard">
                  <h2>Total Third Party Apps</h2>

                  {dashboardDataLoader || issueSeverityLoader ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "190px",
                        width: "100%",
                      }}
                    >
                      <CircularProgress className="loader-custom-class-new" />
                    </Box>
                  ) : (
                    <div className="image-third-party">
                      <img
                        src={IconDashbaord}
                        alt="icon"
                        className="image-third-party-icon"
                      />
                      <p>{totalApps?.toString()}</p>
                      {percentageChangeInVendor >= 0 ? (
                        <span className="number-up">
                          <img src={UpIcon} alt="up icon" />{" "}
                          {percentageChangeInVendor?.toFixed(2)?.toString()}%
                        </span>
                      ) : (
                        <span className="number-down">
                          <img src={DownIcon} alt="down icon" />{" "}
                          {percentageChangeInVendor?.toFixed(2)?.toString()}%
                        </span>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                // Blured Data
                <div className="top-heading-dashboard blur-box-cover-area">
                  <h2>Total Third Party Apps</h2>
                  <div className="image-third-party ">
                    <img
                      src={IconDashbaord}
                      alt="icon"
                      className="image-third-party-icon"
                    />
                    <p>{50}</p>
                    <span className="number-up">
                      <img src={UpIcon} alt="up icon" /> 20.20%
                    </span>
                  </div>
                </div>
              )}
            </Card>

            {/*<AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total Third Party Apps"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
          </Grid>

          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              {checkPermission("Total Issues") ? (
                <div className="top-heading-dashboard">
                  <h2>Total Issues</h2>
                  <div className="total-issues-chart legend-text-issue-legend">
                    {dashboardDataLoader || issueSeverityLoader ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "190px",
                          width: "100%",
                        }}
                      >
                        <CircularProgress className="loader-custom-class-new" />
                      </Box>
                    ) : (
                      <Chart
                        options={
                          isChartSeriesNullDonut
                            ? getEmptyDonutOptions(chartOptions)
                            : chartOptions
                        }
                        series={
                          isChartSeriesNullDonut ? [0, 0, 0, 1] : chartSeries
                        } // Show an empty donut if no data
                        type="donut"
                      />
                    )}
                  </div>
                </div>
              ) : (
                // Blured Data
                <div className="top-heading-dashboard blur-box-cover-area">
                  <h2>Total Issues</h2>
                  <div className="total-issues-chart legend-text-issue-legend">
                    {/* <div className ="blur-box-cover-area"style={{minHeight: "150px"}}> */}
                    <Chart
                      options={chartOptions2}
                      series={[10, 10, 10, 10]} // Show an empty donut if no data
                      type="donut"
                    />
                    {/* </div> */}
                  </div>
                </div>
              )}
            </Card>

            {/* <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total Third Party Apps"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
          </Grid>

          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              {checkPermission("Total Assessment") ? (
                <div className="top-heading-dashboard">
                  <h2>Total Assessment</h2>
                  <div className="total-issues-chart legend-text-issue-legend">
                    {dashboardDataLoader || !dashboardCardData ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          minHeight: "190px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress className="loader-custom-class-new" />
                      </Box>
                    ) : (
                      <Chart
                        options={
                          isChartSeries2NullDonut
                            ? getEmptyDonutOptions(chartOptions2)
                            : chartOptions2
                        }
                        series={
                          isChartSeries2NullDonut ? [0, 0, 0, 1] : chartSeries2
                        } // Show an empty donut if no data
                        type="donut"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="top-heading-dashboard blur-box-cover-area">
                  <h2>Total Assessment</h2>
                  <div className="total-issues-chart legend-text-issue-legend">
                    <div className="total-issues-chart legend-text-issue-legend">
                      <Chart
                        options={chartOptions2}
                        series={[10, 10, 10, 10]} // Show an empty donut if no data
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Card>

            {/* <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            className="fit-height"
            style={{ flexWrap: "wrap" }}
          >
            {/* <AppOverallScore title="Overall Risk Score" riskScore="0" /> */}

            {checkPermission("Overall Risk Score") ? (
              <Card sx={{ mb: 1 }} className="score-different-color">
                <div className="top-heading-dashboard bg-ellipsshape">
                  <h2>Overall Risk Score</h2>
                  <div className="total-issues-chart overall-chart-center">
                    {/* <GaugeChart /> */}
                    {/* <span className="number-up position-right-side-overall">
                        <img src={UpIcon} alt="up icon" /> 4.2%
                        </span> */}
                    <NewOverall riskTitle={handleRiskTitle} />
                  </div>
                </div>
              </Card>
            ) : (
              // Blured Data
              <Card
                sx={{ mb: 1 }}
                className="score-different-color blur-box-cover-area"
              >
                <div className="top-heading-dashboard bg-ellipsshape ">
                  <h2>Overall Risk Score</h2>
                  <div className="total-issues-chart overall-chart-center">
                    <div
                      className="gauge-semicircle gauge-container "
                      style={{ width: "500px", height: "200px" }}
                    >
                      <GaugeComponent
                        type="semicircle" // Using a semicircular gauge
                        arc={{
                          colorArray: [
                            colors.RED,
                            colors.ORANGE,
                            colors.YELLOW,
                            colors.GREEN,
                          ],
                          padding: 0.06, // Adjust padding for smoother arc transitions
                          subArcs: [
                            { limit: 25 }, // 25% of the total gauge
                            { limit: 50 }, // Next 25%
                            { limit: 75 }, // Next 25%
                            { limit: 100 }, // Last 25%
                          ],
                          arcWidth: 0.8, // Adjust arc thickness
                        }}
                        pointer={{
                          type: "blob",
                          animationDelay: 0,
                          color: "#ffffff",
                        }} // Similar to the circle in the image
                        value={50} // Adjust value as needed
                        label={{
                          display: true,
                          text: `${50}/100`,
                          style: {
                            fontSize: "25px",
                            fontWeight: "bold",
                            fill: "#ffffff",
                            fontFamily: "Mona-Sans",
                          },
                        }}
                      />
                      <p
                        className="last-scan"
                        style={{
                          textAlign: "center",
                          color: "#fff",
                          marginTop: "0px",
                        }}
                      >
                        Last scanned {32}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            )}

            {checkPermission("Overall Risk Score") ? (
              <div
                className={`${riskTitle.toLowerCase()}-risk-part risk-part border-gradient border-shape-color`}
              >
                {riskTitle && <p>{riskTitle} Risk</p>}
              </div>
            ) : (
              <div
                className={`medium-risk-part risk-part border-gradient border-shape-color blur-box-cover-area`}
              >
                <p className={`blur-box-cover-area`}>Risk</p>
                {/* <p>Medium Risk</p> */}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={7} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Heatmap</h2>
                <div className="legend-text-issue-legend padding-left-headmap">
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className="overall-midtoset-align"
                    >
                      <p>Overall Risk</p>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <HeatMapChart style={{ width: "100%" }} data={heatMapData} />
                      <p className="relationship-heading">Relationship Risk</p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>
          </Grid>
          {/* Sunburst Chart */}
          <Grid item xs={12} md={12} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Sunburst Chart</h2>
                <div className="total-issues-chart legend-text-issue-legend">
                {sunbrustChartLoader ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "190px",
                          width: "100%",
                        }}
                      >
                        <CircularProgress className="loader-custom-class-new" />
                      </Box>
                    ) : (
                        <SunbrustChart
                          data={sunbrustChartData}
                        />
                    )}
                </div>
              </div>
            </Card>
          </Grid>
 
       

          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Third Party Apps"
                total={totalApps?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Issues"
                total={totalIssues?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Ongoing Assessment"
                total={ongoingAssessment?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Completed Assessment"
                total={completedAssessment?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Delayed Assessment"
                total={delayedAssessment?.toString()}
              />
            </Card>
          </Grid> */}

          <Grid item xs={12} md={6} lg={6} className="flx-property">
            <VerticalChartBarForIssue/>
           </Grid>
          <Grid item xs={12} md={6} lg={6} className="flx-property">
           <VerticalChartBarForCVE/>
          </Grid>

          {/* <Grid item xs={12} md={4} lg={4} className="fit-height">
            <AppCurrentVisits
              loading={issueSeverityLoader}
              title="Third-Party Issue Severity"
              chartData={[
                { label: "Low", value: severityIssue?.low || 0 },
                { label: "Medium", value: severityIssue?.medium || 0 },
                { label: "High", value: severityIssue?.high || 0 },
                { label: "Critical", value: severityIssue?.critical || 0 },
              ]}
              chartColors={[
                "#6AA84F",
                "#FEB019",
                "#ff7300",
                "#d32f2f",
              ]}
              style={{}}
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            className="fit-height"
            sx={{ flexDirection: "column" }}
          >
            <Box
              component={Paper}
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              {checkPermission("Third Party at Risk") ? (
                <>
                  <div className="top-heading-dashboard">
                    <h2>Third Party at Risk</h2>
                  </div>
                  <TableContainer
                    className={
                      theme.palette?.mode === "light"
                        ? " third-party-risk-table-light"
                        : ` third-party-risk-table`
                    }
                  >
                    {thirdPartyRiskLoader ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "295px",
                          width: "100%",
                        }}
                      >
                        <CircularProgress className="loader-custom-class-new" />
                      </Box>
                    ) : (
                      <>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow className="head-th">
                              <TableCell className="min-width-s-number">
                                S.No.
                              </TableCell>
                              <TableCell>Vendor Name</TableCell>
                              <TableCell className="min-width">Score</TableCell>
                              <TableCell>Risk</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ maxHeight: "100px" }}>
                            {thirdPartyRisk &&
                              thirdPartyRisk.length > 0 &&
                              thirdPartyRisk?.map((row, index) => (
                                <TableRow hover key={row?.name} tabIndex={-1}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <div
                                      style={{
                                        textTransform: "capitalize",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <span className="logo-image-vendor">
                                        <img
                                          className="attack-surface-list-logo"
                                          src={`${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${row.name}`}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = defaultLogo;
                                          }}
                                        />
                                      </span>{" "}
                                      {(row?.name).split(".")[0]}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {row?.vendorCurrentStatus !== "Scanning" ? (
                                      <>
                                        {Math.floor(row?.score)}
                                        <span
                                          className={`${row?.percentageChangeInScore >= 0
                                            ? `score-value`
                                            : `low-score-value`
                                            }`}
                                        >
                                          <img
                                            src={`${row?.percentageChangeInScore >= 0
                                              ? ScoreUp
                                              : SmallDownSide
                                              }`}
                                            alt="icon"
                                          />{" "}
                                          {row?.percentageChangeInScore}
                                        </span>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <span className="third-party-table-button">
                                      {/* {row.vendorCurrentStatus!=="Scanning" ? <img src={getLableImage(row.score)} alt='icon'></img>:"-"} */}
                                      {row?.vendorCurrentStatus !== "Scanning"
                                        ? getLableImage(row?.score)
                                        : "-"}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {!thirdPartyRisk?.length ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            No Data Available
                          </Box>
                        ) : null}
                      </>
                    )}
                  </TableContainer>
                </>
              ) : (
                <div className="top-heading-dashboard blur-box-cover-area third-party-at-risk-blur">
                  <h2>Third Party at Risk</h2>

                  <TableContainer
                    className={
                      theme.palette?.mode === "light"
                        ? " third-party-risk-table-light"
                        : ` third-party-risk-table`
                    }
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className="head-th">
                          <TableCell className="min-width-s-number">
                            S.No.
                          </TableCell>
                          <TableCell>Vendor Name</TableCell>
                          <TableCell className="min-width">Score</TableCell>
                          <TableCell>Risk</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ maxHeight: "100px" }}>
                        {dummyThirdPartyRisk?.map((row, index) => (
                          <TableRow hover key={row?.name} tabIndex={-1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  textTransform: "capitalize",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <span className="logo-image-vendor">
                                  {row?.logo !== "null" ? (
                                    <img
                                      className="attack-surface-list-logo"
                                      src={row?.logo}
                                    />
                                  ) : (
                                    <img
                                      className="attack-surface-list-logo"
                                      src={`${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${row.name}`}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = defaultLogo;
                                      }}
                                    />
                                  )}
                                </span>{" "}
                                {(row?.name).split(".")[0]}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row?.vendorCurrentStatus !== "Scanning" ? (
                                <>
                                  {Math.floor(row?.score)}
                                  <span
                                    className={`${row?.percentageChangeInScore >= 0
                                      ? `score-value`
                                      : `low-score-value`
                                      }`}
                                  >
                                    <img
                                      src={`${row?.percentageChangeInScore >= 0
                                        ? ScoreUp
                                        : SmallDownSide
                                        }`}
                                      alt="icon"
                                    />{" "}
                                    {row?.percentageChangeInScore}
                                  </span>
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              <span className="third-party-table-button">
                                {/* {row.vendorCurrentStatus!=="Scanning" ? <img src={getLableImage(row.score)} alt='icon'></img>:"-"} */}
                                {row?.vendorCurrentStatus !== "Scanning"
                                  ? getLableImage(row?.score)
                                  : "-"}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={7} className="fit-height">
            <Card sx={{ mb: 1 }}>
              {checkPermission("Third Party Classification by Risk") ? (
                <div className="top-heading-dashboard">
                  <h2>Third Party Classification by Risk</h2>
                  {/*<Chart options={options} series={series} type="bar" height={350} /> */}
                  <div className="total-issues-chart in-chart-bar custom-line-third">
                    <ReportChart
                      loading={thirdPartyRiskLoader}
                      // title="Third Party Classification By Risk"
                      style={{}}
                      chartData={thirdPartyRiskConfig}
                    />
                  </div>
                </div>
              ) : (
                // Blured Data
                <div className="top-heading-dashboard blur-box-cover-area third-party-classification-blur">
                  <h2>Third Party Classification by Risk</h2>
                  <div className="total-issues-chart in-chart-bar custom-line-third ">
                    <ReportChart
                      // loading={thirdPartyRiskLoader}
                      // title="Third Party Classification By Risk"

                      style={{}}
                      chartData={[
                        { vendor: "Critical", score: 10 },
                        { vendor: "High", score: 10 },
                        { vendor: "Medium", score: 10 },
                        { vendor: "Low", score: 10 },
                      ]}
                    />
                  </div>
                </div>
              )}
            </Card>
          </Grid>


          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className="mbottom flex-property w100"
          >
            {/* <div class="total_issues_select_wrap">
              <select
                className="total_issues_select"
                onChange={totalIssuesChange}
              >
                <option value={30}>Last Month</option>
                <option value={90}>Last 3 Months</option>
                <option value={180}>Last 6 Months</option>
                <option value={365} selected="true">
                  Last Year
                </option>
              </select>
            </div> */}

            {checkPermission("Overall Analysis") ? (
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard">
                  <h2>Total Issues</h2>
                  <div className="total-issues-chart in-chart-bar charts-bar-label-design">
                    <AppWebsiteVisits
                      // title="Total Issues"
                      // subheader="Last 30 Days"
                      chartLabels={graphDates}
                      loading={chartDataLoader}
                      chartData={[
                        {
                          name: "Issues",
                          type: "area",
                          fill: "gradient",
                          data: graphValues,
                        },
                        {
                          name: "Assessments",
                          type: "area",
                          fill: "gradient",
                          data: allAssessmentsCount,
                        },
                        {
                          name: "Score",
                          type: "area",
                          fill: "gradient",
                          data: totalScore,
                        },
                      ]}
                      totalIssuesChange={totalIssuesChange}
                      option={option}
                    />
                  </div>
                </div>
              </Card>
            ) : (
              //Blured Data
              <Card sx={{ mb: 1 }}>
                <div className="top-heading-dashboard blur-box-cover-area third-party-classification-blur">
                  <h2>Total Issues</h2>
                  <div className="total-issues-chart in-chart-bar charts-bar-label-design ">
                    <AppWebsiteVisits
                      // title="Total Issues"
                      // subheader="Last 30 Days"
                      chartLabels={[
                        "Nov-24",
                        "Nov-23",
                        "Dec-23",
                        "Jan-24",
                        "Feb-24",
                        "Mar-24",
                        "Apr-24",
                        "May-24",
                        "Jun-24",
                        "Jul-24",
                        "Aug-24",
                        "Sep-24",
                        "Oct-24",
                      ]}
                      chartData={[
                        {
                          name: "Issues",
                          type: "area",
                          fill: "gradient",
                          data: [50, 0, 0, 0, 63, 0, 0, 56, 0, 0, 30, 74, 0],
                        },
                        {
                          name: "Assessments",
                          type: "area",
                          fill: "gradient",
                          data: [41, 0, 20, 10],
                        },
                        {
                          name: "Score",
                          type: "area",
                          fill: "gradient",
                          data: [150, 0, 100, 0],
                        },
                      ]}
                    // totalIssuesChange={totalIssuesChange}
                    // option={option}
                    />
                  </div>
                </div>
              </Card>
            )}
          </Grid>
          {/* <Link to="/dashboard/chat-app" className='chat-circle-right'><ChatIcon /></Link> */}
        </Grid>
      </Container>
    </>
  );
}
