// FaildeControl.js
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {  useNavigate, useParams } from "react-router-dom";
import {  InputAdornment, Select } from "@mui/material";
import { CustomNoRowsOverlay } from "../Assessment.js"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import GetRequest from "../../components/apiConnections/getRequest.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import CrossSelect from '../../assets/cross-select.svg';

import AILoader from "../../AILoader.js";
import Loader from "../../Loader.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 1,
  height: "568px",
  overflowY: "auto",
};

const FaildeControl = ({setLoader}) => {
  const data = useParams();
  const navigate = useNavigate();
  const [failedControlData, setFailedControlData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [loading, setLoading] = useState(false);
  const [sendloading, setSendLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(null);
  const [generateRemedyLoader, setRemediationLoader] = useState(false);
  const theme = useTheme();
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [contactPersonList, setContactPersonList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [deadline, setDeadline] = useState("");
  // const [openCreateRemediation, setOpenCreateRemediation] = React.useState(false);
  // const handleOpenCreateRemediation = () => setOpenCreateRemediation(true);
  // const handleCloseCreateRemediation = () => setOpenCreateRemediation(false);
  const [uniqueId, setUniqueId] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    getVendorFailedControl();
    getVendorContactPerson();
  }, []);

  const getVendorFailedControl = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_FAILED_CONTROL}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setFailedControlData(obj2);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          setshowLoader(false);
          setLoading(false);
          return false;
        });
    } catch (error) {
      // no data
      setshowLoader(false);
      return false;
    }
  };

  const getVendorContactPerson = async () => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  };

  const sendRemediation = () => {
    const contactPerson = JSON.parse(selectedContactPerson || "{}");

    if (deadline === "") {
      enqueueSnackbar("Please select deadline.", { variant: "error" });
      return;
    }
    if (!contactPerson?.email) {
      enqueueSnackbar("Please select contact person.", { variant: "error" });
      return;
    }

    setSendLoading(true);

    const payload = {
      // remediationPlan: modelOpen,
      remediationPlan:selectedQuestion,
      firstName: contactPerson?.firstName,
      lastName: contactPerson?.lastName,
      email: contactPerson?.email,
      deadline,
      createdBy: localStorage.getItem("userEmail"),
      url: contactPerson?.vendorDomain,
      remediationType:"forQuestionnaire",
      uniqueId:uniqueId
    };

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_REMEDIATION_BY_USER}`;

    PostRequest(url, payload)
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        setModelOpen(null);
        setSendLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  };

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value);
  };

  const generateRemediation = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_GENERATE_REMEDIATION}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setLoader(false);
          getVendorFailedControl();
        })
        .catch((err) => {
          // no data
          setLoader(false);
          enqueueSnackbar("Error with Generate remediation", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      setRemediationLoader(false);
      enqueueSnackbar("Error with Generate remediation", { variant: "error" });
      return false;
    }
  };
  const handleRowClick = (params) => {
    navigate(`/dashboard/failed-control-detail`, { state: { Data: params.row , vendorUrl: vendorUrl,   mainTabValue: 4, nestedTabValue: 1  }})
  };
  const filteredRows =
    (!failedControlData || !failedControlData.length) ? [] :
      failedControlData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
          text: val?.que?.text,
          score: "Failed",
          vulnerabilityType: val?.que?.vulnerabilityType,
          category: val?.category,
          riskMapping: val?.que?.riskMapping,
          set: val?.set,
          uniqueId: val?.que?.uniqueId || "--"
        }));
  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
         field: "uniqueId",
        headerName: "ID",
        flex: 1.1,
        minWidth: 170, 
        disableColumnMenu: true,
    },
    {
      field: "text",
      headerName: "Questions Name",
      flex: 1.1,
      minWidth: 125,
      disableColumnMenu: true,
    },
    {
      field: "score",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let score = params.value;
        return (
          <span className={`tagClass ${score}`}>{score}</span>
        );
      },
    },
    {
      field: "category",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "riskMapping",
      headerName: "Risk",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let riskValue = params.value;
    
        return riskValue ? (
          <span className={`tagClassapproval ${riskValue}`}>
            <span>{riskValue}</span>
          </span>
        ) : (
          "-"
        );
      }
    },
    {
      field: "set",
      headerName: "Assessment",
      flex: 0.9,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "createRemedation",
      headerName: "",
      flex: 1.4,
      minWidth: 100,
      align:"right",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.remediationPlan ? (
            <span onClick={(e) => {
              e.stopPropagation();
                  setModelOpen(params?.row.remediationPlan)
                  setUniqueId(params?.row?.uniqueId)
                  setSelectedQuestion(params?.row)
             }} 
             style={{fontSize:"13px",padding: "15px 12px"}}
             className="create-remediation-button">
            {" "}
            + Create Remediation
          </span>
            ) : <span className="no-remediation-button" style={{
              backgroundColor:"gray"
            }}>
              --
            </span>}
          </>
        );
      },
    },
  ];
  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <>
    {generateRemedyLoader && (
                <AILoader show={generateRemedyLoader} />
                )}
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "0px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
            {!showLoader && failedControlData.length > 0 && (
              <Button
                variant="contained"
                className="send-assessment"
                onClick={() => generateRemediation()}
              >
                Generate Remediation
                
              </Button>
            )}
          </div>

          <div className="issues-tab-table">
            <Box
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  minWidth: "100%",
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minWidth: "100%",
                  overflowX: "auto",
                },
              }}
            >

              <DataGrid
                loading={showLoader}
                rows={filteredRows}
                columns={columns}
                rowHeight={70}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                components={{
                  NoRowsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No data
                    </Box>
                  ),
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  columns: ["id", "firstName", "lastName"],
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className="datagrid-table click-hover-datagrid"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%", // Column headers take up full width
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%", // Rows take up full width
                  },
                }}
              onRowClick={handleRowClick}
              />
            </Box>
          </div>
        </div>
      </Card>
      <Modal
        open={modelOpen !== null}
        onClose={() => setModelOpen(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark"
            ? "dark-attack-modal modal-popup"
            : "light-attack-modal modal-popup"
        }
      >
        <Box sx={style} className="black-bg openai-response popup-remiadation scroll-design">
        <div class="pop-flex-remiadation"><h3>Create Remediation</h3>
        <img src={CrossSelect} onClick={() => setModelOpen(null)} alt="icon" style={{cursor:"pointer"}}/></div>
          <form onSubmit={(e) => false} className="full-form">
            <Grid container spacing={3}>
              <Grid item xs={6} md={6} lg={6}>
                <h3 className="relation" style={{ color: "#fff" , marginTop:"5px"}}>
                  Vendor's Contact Person 1
                </h3>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {!selectedContactPerson ? "Select Person" : ""}
                  </InputLabel>
                  <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                  >
                    {contactPersonList && contactPersonList.length>=0  &&  contactPersonList.map((item) => (
                      <MenuItem key={item._id} value={JSON.stringify(item)}>
                        {item.firstName} {item.lastName} {`(${item.email})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
              <h3 className="relation" style={{ color: "#fff" , marginTop:"5px"}}>
                Deadline 
                </h3>
                <FormControl fullWidth>
                  <TextField
                    style={{ color: "#ffff", fontWeight: 600 }}
                    type="date"
                    defaultValue=""
                    name="deadLine"
                    onChange={(e) => setDeadline(e.target.value)}
                    inputProps={{
                      min: getCurrentDate(new Date()),
                      onKeyDown: (e) => e.preventDefault(),
                      // Remove the default calendar icon from the input field
                      style: {
                      WebkitAppearance: 'none', // Remove on Chrome/Safari/Edge
                      MozAppearance: 'textfield', // Remove on Firefox
                      appearance: 'none', // General appearance removal
                      position: 'relative',
                      },
                  }}
                  sx={{ color: "white" }}
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                          {/* {/ Custom image for opening the date picker /} */}
                          <img
                            src={CalendarTodayIcon}
                            alt="calendar icon"
                            style={{ cursor: 'pointer' }}							 
                            onClick={() => {
                              document.getElementsByName('deadLine')[0].showPicker();
                            }}
                          />
                        </InputAdornment>
                      ),
                  }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="fialed-control-poup">
            <h3 className="relation" style={{ color: "#fff" }}>
             Remediation
            </h3>
             <div>
              <ul>
                <li>
                    <h4>Objective: </h4>
                    <span className="span-font-size">{modelOpen?.objective}</span>
                </li>
                <li>
                  {/* <h4>Action Items: </h4> */}
                  {modelOpen?.actionItems.map(
                    (item, index) => (
                      <ul key={index}>
                        <li>
                          <h4>Actions:</h4>
                          <ul className="multiple-heading-map">
                            <li>
                              <span>
                                <b>Responsible:</b>
                              </span>
                              <span className="span-font-size">{item.responsible}</span>
                            </li>
                            <li>
                              <span>
                                <b>Duration:</b>
                              </span>
                              <span className="span-font-size"> {item.duration}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    )
                  )}
                </li>

                <li>
                  <h4>Refrences:</h4>
                  {modelOpen?.references.map((item, index) => (
                    <ul key={index} className="multiple-heading-map">
                      <li>
                        <li>
                          <span>
                            <b>Refrence {index+1}:</b>
                          </span>
                          <span className="span-font-size">{item}</span>
                        </li>
                      </li>
                    </ul>
                  ))}
                </li>
                </ul>
                </div>
            </div>
        
          {/* </div> */}
          {/* </Container> */}

          <div className="remedy-send-btn" style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              className="executive-report-btn  Send-send-assessment"
              onClick={() => sendRemediation()}
            >
              Send
              {sendloading ? (
                    <CircularProgress size={12} sx={{ marginLeft: "0px" }} />
                  ) : null}
            </Button>
            </div>
            </form>
        </Box>
      </Modal>
    </>
  );
};

export default FaildeControl;
