import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import {
  Grid,
  Container,
  Card,
  Box,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import Loader from "../Loader";
import "./css/Vendors.css";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import Breadcrumb from "../pages/VendorDetail/Breadcrumb.js";

// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const data = useParams();
  const [domainName, setDomainName] = useState("");
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);

  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");

  const [currentIssuesName, setCurrentIssuesName] = useState("");
  const [currentIssuesDescription, setCurrentIssuesDescription] = useState("");
  const [currentIssuesEvidence, setCurrentIssuesEvidence] = useState("");
  const [currentIssuesRemediation, setCurrentIssuesRemediation] = useState("");
  const [currentIssuesScore, setCurrentIssuesScore] = useState("");
  const [currentIssuesSeverity, setCurrentIssuesSeverity] = useState("");
  const [currentIssuesReference, setCurrentIssuesReference] = useState("");
  const [valueForBreadcrub, setValueForBreadcrub] = useState("1");
  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            setshowLoader(false);

            let tempIssues = res.data.data?.attackInfo.Issues;
            let currentIssuesData = [];
            for (let index = 0; index < tempIssues.length; index++) {
              const element = tempIssues[index];
              if (element.Target === data.subdomain) {
                setDomainIssues(element.Issue);
                currentIssuesData = element.Issue;
              }
            }

            for (let index = 0; index < currentIssuesData.length; index++) {
              const element = currentIssuesData[index];
              if (element.Name == data.issueid) {
                setCurrentIssuesName(element.Name);
                setCurrentIssuesDescription(element.Info);
                setCurrentIssuesEvidence(element.Evidence);
                setCurrentIssuesRemediation(element.Remediation);
                setCurrentIssuesScore(element.Score);
                setCurrentIssuesSeverity(element.Severity);
                setCurrentIssuesReference(element.Reference);
              }
            }
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValueForBreadcrub(newValue)
  };

  const breadcrumbs = [
    { label: "Attack Surface", path: "/dashboard/attacks" },
    { label: "Assets", path:  `/${roleId == 2 ? "admin" : "dashboard"}/attack-detail/${data.url}`,mainTabValue:2},
    { label: "Issues", path:  `/${roleId == 2 ? "admin" : "dashboard"}/sub-domain-issues/${data.url}/${data.subdomain}` },
    { label: "Info" },
  ];
  const breadcrumbsForEvidence = [
    { label: "Attack Surface", path: "/dashboard/attacks" },
    { label: "Assets", path:  `/${roleId == 2 ? "admin" : "dashboard"}/attack-detail/${data.url}`,mainTabValue:2},
    { label: "Issues", path:  `/${roleId == 2 ? "admin" : "dashboard"}/sub-domain-issues/${data.url}/${data.subdomain}` },
    { label: "Evidence" },
  ];
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Attack Surface Detail" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
              {valueForBreadcrub==="1" ? <Breadcrumb crumbs={breadcrumbs} />
                :<Breadcrumb crumbs={breadcrumbsForEvidence} />}
                <h3>Vendor Info</h3>
              </div>
            </div>
          </Grid>
          {showLoader === true ? (
            <Loader show={showLoader} />
          ) : domainName !== "" && domainName ? (
            <Grid item xs={12} md={12} lg={12}>
              <div className="affected-full-part">
                <div className="affecteds-part">
                  <h3>Affected Assets:</h3>
                  <span className="subdomain-assets-text">
                    {data.subdomain}
                  </span>
                </div>
                <div className="affecteds-part">
                  <h3>Company:</h3>
                  <span className="subdomain-assets-text">{data.url}</span>
                </div>
                <div className="affecteds-part">
                  {currentIssuesSeverity === "CRITICAL" ? (
                    <span className="alert-Critical critical-color">
                      {currentIssuesSeverity}
                    </span>
                  ) : currentIssuesSeverity === "HIGH" ? (
                    <span className="alert-Critical high-color">
                      {currentIssuesSeverity}
                    </span>
                  ) : currentIssuesSeverity === "MEDIUM" ? (
                    <span className="alert-Critical medium-color">
                      {currentIssuesSeverity}
                    </span>
                  ) : (
                    <span className="alert-Critical success-critical">
                      {currentIssuesSeverity}
                    </span>
                  )}
                </div>
              </div>
              <Grid container spacing={2} sx={{ pt: 4 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <TabContext value={value}>
                    <Box className="first_tab">
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="tab-main-heading margin-mui-tabfix"
                        sx={{ mt: 0 }}
                      >
                        <Tab
                          label="Info"
                          value="1"
                          className="active-color-remove second-mui"
                        />
                        <Tab
                          label="Evidence"
                          value="2"
                          className="active-color-remove second-mui"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0 }}>
                      <div className="Assessment-box-attack subdomain">
                        <div className="box-Assessment-attack">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid item xs={12} className="cve-dynamic-number">
                                <h4>{currentIssuesName}</h4>
                              </Grid>
                              <Grid item xs={12} className="description-subdomain">
                                <h4>Description</h4>
                                <p>{currentIssuesDescription}</p>
                              </Grid>
                              <Grid item xs={12} className="description-subdomain">
                                <h4>Remediation</h4>
                                <p>{currentIssuesRemediation}</p>
                              </Grid>
                              <Grid item xs={12} className="description-subdomain">
                               <h4>Reference</h4>
                                <div  style={{marginTop:"13px"}}>
                                {currentIssuesReference?.map((item, index) => (
                                  <p className="dark-change-bg">
                                    <a
                                      href={`${currentIssuesReference[index]}`}
                                      style={{
                                        color: "#fff",
                                        display: "block",
                                        marginBottom: "1px",
                                        width: "fit-content",
                                        wordBreak: "break-all",
                                      }}
                                      target="_blank"
                                    >
                                      {currentIssuesReference[index]}
                                    </a>
                                  </p>
                                ))}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0 }}>
                      <div className="Assessment-box-attack subdomain">
                        <div className="box-Assessment-attack">
                          <Grid container spacing={2} >
                            <Grid item xs={12} className="cve-dynamic-number bg-removedynamic">
                              <h4>Evidence</h4>
                              </Grid>
                              <Grid item xs={12}>
                             {Array.isArray(currentIssuesEvidence) ? <>{currentIssuesEvidence?.map((key, index) => (
                                <pre data-lang="scss" className="prettyprint">
                                  <code>{currentIssuesEvidence[index]}</code>
                                </pre>
                                 ))}</> 
                                 : <pre data-lang="scss" className="prettyprint">
                                    <code>{currentIssuesEvidence}</code>
                                   </pre>
                              }
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Grid>
                <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                  <h4>No Record for this url</h4>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}
