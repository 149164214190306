import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vendorsJson: [],
};

const vendorsJsonSlice = createSlice({
    name: "vendorsJson",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload; // Set loading state
        },
        addVendorJsonList: (state, action) => {
            state.vendorsJson = action.payload;
            state.loading = false; // Turn off loading when data is added
        },
    },
});

export const { setLoading, addVendorJsonList } = vendorsJsonSlice.actions;

export default vendorsJsonSlice.reducer;