import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Box, Card, CardHeader, CircularProgress } from "@mui/material";
import GaugeChart from "react-gauge-chart";
import GaugeComponent from 'react-gauge-component';
import colors from "../../../utils/colorcode";
// utils
// components
import PostRequest from "../../../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router-dom";
// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
  text: {
    visibility: "hidden!important",
  },
}));

// ----------------------------------------------------------------------

AppOverallScore.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppOverallScore({
  title,
  subheader,
  ...other
}) {
  const data = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showChartLoader, setshowChartLoader] = useState(false);
  const [themename, setThemeName] = useState(
    window.localStorage.getItem("theme") === "Dark" ? "Dark" : "Light"
  );
  const [latestScanAt, setLatestScanAt] = useState();
  const [Overallscore, setOverallscore] = useState(0);

  const [option, setOption] = useState(90);
  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const updateOverallscore = async () => {
    setshowChartLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endpoint = "attack-details-day-wise";
        const daysPayload = {
          selectedDays: option,
          domainName: data.url
        };
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          daysPayload
        )
          .then((res) => {
            let total_score = 0;
            setLatestScanAt(moment(res.data?.data[0]?.createdAt).format("DD MMM YYYY"));
            if(res.data.data.length === 0)
            {
              setOverallscore(0);
            }
            else
            {
              Object.entries(res.data?.data)?.map((key,val)=>{
                total_score = total_score + Number(key[1].attackInfo?.score[0]?.Overall_score);
              })
              const lengthOfArray = res.data?.data.length;
              setOverallscore(((total_score) / lengthOfArray).toFixed(2));
            }        
          })
          .catch((err) => {
            enqueueSnackbar("Requested Url not find1", { variant: "error" });
            setshowChartLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowChartLoader(false);
      return false;
    }
    setshowChartLoader(false);
  };
  useEffect(() => {
    const themeName = localStorage.getItem("theme");
    setThemeName(themeName);
  }, []);

  useEffect(() => {
    updateOverallscore();
  }, [option]);

  useEffect(() => {
    if (Overallscore) {
      const displayValue = Math.round(Overallscore);
      const displayMax = 100;

      const valueTextElement = document.querySelector('.value-text text');
      if (valueTextElement) {
        valueTextElement.innerHTML = '';

        const scoreTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        scoreTspan.textContent = displayValue;
        scoreTspan.setAttribute('style', 'font-size: 40px; font-weight: bold; fill: white;');

        const maxTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        maxTspan.textContent = `/${displayMax}`;
        maxTspan.setAttribute('style', 'font-size: 20px; fill: white; margin-left: 5px;');

        valueTextElement.appendChild(scoreTspan);
        valueTextElement.appendChild(maxTspan);
      }
    }
  }, [Overallscore])

  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Card {...other}>
        <div className="card-headerpart">
          <CardHeader
            title={title}
            subheader={subheader}
            sx={{ p:"0", marginLeft: "0px", fontSize: "1.0rem" }}
          />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={option}
            onChange={handleChange}
            style={{ width: "140px", height: "30px", marginLeft: "0px", }}
          >
            <MenuItem selected value={7}>
              Last 7 days
            </MenuItem>
            <MenuItem value={14}>Last 14 days</MenuItem>
            <MenuItem value={21}>Last 21 days</MenuItem>
            <MenuItem value={28}>Last 28 days</MenuItem>
            <MenuItem value={90}>Last 90 days</MenuItem>
          </Select>
        </div>
        
        {/*New Graph implemented */ }
       {!showChartLoader ? (<div className='gauge-semicircle gauge-container' style={{ width: '400px', height: '200px' }}>
          <GaugeComponent
            type="semicircle" // Using a semicircular gauge
            arc={{
              colorArray: [colors.RED, colors.ORANGE, colors.YELLOW, colors.GREEN,],
              padding: 0.06, // Adjust padding for smoother arc transitions
              subArcs: [
                { limit: 25 },  // 25% of the total gauge
                { limit: 50 },  // Next 25%
                { limit: 75 },  // Next 25%
                { limit: 100 }, // Last 25%
              ],
              arcWidth: 0.8, // Adjust arc thickness
            }}

            pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }} // Similar to the circle in the image
            value={Overallscore} // Adjust value as needed
            label={{
              display: true,
              text: `${Overallscore}/100`,
              style: {
                fontSize: '25px',
                fontWeight: 'bold',
                fill: '#ffffff',
                fontFamily: 'Mona-Sans',
              },
            }}
          />
          <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
            {latestScanAt}
          </p>
        </div>):(
        <Box sx={{ display: "flex", justifyContent: "center",minHeight: "190px", alignItems: "center", width: "100%" }}>
          <CircularProgress className="loader-custom-class-new" />
        </Box>
        )}

        <StyledChartWrapper dir="ltr" sx={{ mt: 0 }} className="m0-graph" style={{display:"none"}}>
          <GaugeChart
            id="gauge-chart3"
            nrOfLevels={3}
            colors={["#d32f2f", "#ed6c02", "#2e7d32"]}
            arcWidth={0.1}
            arcPadding={0.05}
            cornerRadius={5}
            needleColor={
              Overallscore >= 0.66
                ? "#2e7d32"
                : Overallscore >= 0.33 && Overallscore < 0.66
                ? "#ed6c02"
                : "#d32f2f"
            }
            needleBaseColor={"rgb(144, 202, 249)"}
            textColor={
              Overallscore >= 0.66
                ? "#2e7d32"
                : Overallscore >= 0.33 && Overallscore < 0.66
                ? "#ed6c02"
                : "#d32f2f"
            }
            percent={Overallscore}
            // arcsLength={[0.3, 0.5,0.2]}
          />
          <span
            className="percentText"
            style={{
              color:
              Overallscore >= 0.66
              ? "#2e7d32"
              : Overallscore >= 0.33 && Overallscore < 0.66
              ? "#ed6c02"
              : "#d32f2f"
            }}
          >
            {Overallscore * 100}
            <small className="textSize">/100</small>
          </span>
          {/* <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={280} /> */}
        </StyledChartWrapper>
      </Card>
    </>
  );
}
