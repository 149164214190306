import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button,Card,Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
import { TwoFaForm } from '../sections/auth/twofa';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TwoFaPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> TwoFa | Genesis Platform  </title>
      </Helmet>
      <div class="login-screen f2a">
       <Container maxWidth={false}  className="login-right-padding max-widh-twofa">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
          <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {/* {mdUp && (
          <StyledSection>
            <Typography variant="h3">
              2FA Verification
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )} */}
          <StyledContent>
            <Card sx={{p:0}}>
            <TwoFaForm />          
            </Card>  
          </StyledContent>
           </StyledRoot>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
                <div className="bg-login-new">
                  <div className="fit-login-area">
                    <h2>We are glad to see you again!</h2>
                    <h1>Empowering You to Manage Third-Party Risks with Confidence</h1>
                  </div>
                </div>
              </Grid>
          </Grid>
          </Container>
        </div>
    </>
  );
}
