import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import NewHeader from "../../layouts/dashboard/header/NewHeader.js";
import SearchIcon from "../../assets/searchico.svg";
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Container,
  Card,
  Box,
  InputAdornment,
  Tabs,
  Tab,
} from "@mui/material";
import RequestsDocument from "./RequestsDocument.js";
import UploadsDocuments from "./UploadsDocuments.js";

function Documents() {
  const [mainTabValue, setMainTabValue] = useState(0);
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };



  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Documents" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <Tabs
                value={mainTabValue}
                onChange={handleMainTabChange}
                className="tab-main-heading margin-mui-tabfix"
                aria-label="basic tabs example"
              >
                <Tab className="active-color-remove" label="Requests" />
                <Tab className="active-color-remove" label="Uploads" />
              </Tabs>

              {mainTabValue === 0 && (
                <div>
                  <RequestsDocument />
                </div>
              )}
              {mainTabValue === 1 && <div><UploadsDocuments/></div>}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Documents;
