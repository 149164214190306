import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Attack.css";
import "./css/Vendors.css";
import CrossPop from '../assets/pop-cross.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import PlusVendor from '../assets/plus-vendor.svg';
import SearchIcon from '../assets/searchico.svg';
import defaultLogo from '../assets/companyLogo.jpg';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  // CircularProgress,
  Select,
  TableHead,
  IconButton, Menu, MenuItem, InputAdornment,
  CircularProgress
} from "@mui/material";
// mock
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import ModalDialog from "./ModalDialog.js";
import { useNavigate } from "react-router-dom";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { CustomNoRowsOverlay } from "./Assessment.js";
import { updateSubscriptionDetails } from "../redux/subscriptionDetailsSlice.js";
// import AttackSurfacedataTable from "./AttackSurfacedataTable.js";
// ----------------------------------------------------------------------
// Menu component for the three-dot menu
function RowMenu({ requestAttackUrl, openDeleteModal, status }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let selector = useSelector((state) => state.permissionList.permissionList);
  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)} // Removed the extra closing parenthesis here
        onClose={handleClose}
        className='rowmenu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {checkPermission("View Attack Detail") && status ? <MenuItem className='border-bot-line' onClick={() => navigate(`/dashboard/attack-detail/${requestAttackUrl}`)}>
          <img src={EyeMenu} alt="View" /> View
        </MenuItem> : null}
        {/* View Attack Detail */}
        {checkPermission("Delete Attack Surface") &&
          <MenuItem onClick={openDeleteModal}>
            <img src={Trash} alt="Delete" /> Remove
          </MenuItem>
        }
      </Menu>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#150d43",
  boxShadow: 24,
  textAlign: "center",
  borderRadius: "15px",
};

export default function UserPage() {

  let selector = useSelector((state) => state.permissionList.permissionList);
  const dispatch = useDispatch(); 
  const username = window.localStorage.getItem("userEmail")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);
  const [search, setSearch] = useState(null)
  const [totalAttack, setTotalAttack] = useState(0)
  useEffect(() => {
    setInterval(() => {
      const themeName =
        localStorage.getItem("theme") !== null
          ? localStorage.getItem("theme")
          : "Dark";
      setTheme(themeName);
    }, 2000);
  }, []);

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  const handleClose = () => {
    setOpenUser(false);
    setUserRemoveId(0);
  };

  useEffect(() => {
    getAttackDetails(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (search !== null) {
        getAttackDetails(1, rowsPerPage)
        setPage(1)
      }
    }, 500)
    return () => clearTimeout(time)
  }, [search])

  const getAttackDetails = async (page1, rowsPerPage1) => {
    setshowLoader(true);
    const endpoint = "get-all-attack-request-by-id";
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?page=${page1}&limit=${rowsPerPage1}&search=${search || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            console.log(arrResponse)
            setisNotFound(!(arrResponse.length > 0));
            setVendorsData(arrResponse?.data || []);
            setTotalAttack(arrResponse?.total || 0)
            setshowLoader(false);
          })
          .catch((errVendor) => {
            // enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeLink = async () => {
    const deleteId = userRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "delete-attack-by-user";
      const payload = {
        username,
        requestAttackUrl: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getAttackDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log("page", newPage)
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const [modelOpen, setModelOpen] = useState(false);
  let subscriptionDetails = useSelector((state) => state.subscriptionDetails.subscriptionDetails) || []; 
  const handleModelOpen = () => {
    const details = subscriptionDetails?.find(sub => sub.id === 'attackRequest' ); 
     if(details?.total_added >=  details?.subscribtion_value){
        enqueueSnackbar("Sorry, you have complete the limit for this in subscription.", { variant: "error" });
        return; 
     }
    setModelOpen(true)
  };
  const handleModelClose = () => setModelOpen(false);
  const [newDomain, setNewDomain] = useState("");
  const inputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newDomain") {
      setNewDomain(value);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    let regex = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
    );
    if (!regex.test(newDomain)) {
      enqueueSnackbar("Error in domain name.", { variant: "error" });
      setshowLoader(false);
      return false;
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        let endPoint = process.env.REACT_APP_REQUEST_ATTACK;
        const payload = {
          requestUrl: newDomain,
          logo: `https://logo.clearbit.com/${newDomain}`
        };

        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          payload
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            setNewDomain("")
            enqueueSnackbar("Successfully Requested.", {
              variant: "success",
            });
            setshowLoader(false);
            dispatch(updateSubscriptionDetails({id : "attackRequest" }));
          } else {
            enqueueSnackbar("Error in request.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
        getAttackDetails();
        setModelOpen(false);
      }
    } catch (e) {
      enqueueSnackbar(e.message || "Error in request.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments">
          Are you sure you want to remove assets ?
        </h3>
      </Typography>
    );
  };

  return (
    <>
      <Helmet>
        <title> Attacks | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Attack Surface" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div>
              {checkPermission("Add Attack Surface") &&
                <Modal
                  open={modelOpen}
                  onClose={handleModelClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className={
                    theme == "Dark"
                      ? "dark-attack-modal modal-popup"
                      : "light-attack-modal modal-popup"
                  }
                >
                  <Box sx={style}>
                    <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} lg={12}>
                            <div className="add-new-attack-heading">
                              <p>Add New</p>
                              <img onClick={handleModelClose} src={CrossPop} style={{ cursor: 'pointer' }} alt="icon" />
                            </div>
                            <FormControl className="attack-field" sx={{ width: "100%" }}>
                              <form onSubmit={(e) => formSubmit(e)}>
                                <Box>
                                  <label>Enter domain name</label>
                                  <Grid xs={12} md={12} lg={12} container>
                                    <TextField
                                      name="newDomain"
                                      value={newDomain || ""}
                                      placeholder="xyz.com"
                                      onChange={(e) => inputChange(e)}
                                      required={true}
                                      margin="normal"
                                      // helperText="Enter domain name!"
                                      id="full-width-text-field"
                                      // label="Domain"
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Box>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  component="button"
                                  type="submit"
                                  sx={{mb:3}}
                                  className="submit-domain-btn"
                                >
                                  Submit
                                </Button>
                              </form>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>
                </Modal>
              }
            </div>
            <Card className="attack-surface-data">
              <div className="cover-table">
                <div style={{ marginBottom: '25px', marginLeft: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {checkPermission("View Attack Surface") &&
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <FormControl className='select-entries' variant="outlined" size="small">
                        <Select
                          value={rowsPerPage}
                          onChange={handleChangeRowsPerPage}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </FormControl>
                      <span className='entries-per'>entries per page</span>
                    </div>
                  }

                  <div className='search-add-new'>
                    {checkPermission("Add Attack Surface") &&
                      <div className='add-new-attack' onClick={handleModelOpen}>
                        <img src={PlusVendor} alt='icon' /> Add New
                      </div>
                    }
                    {checkPermission("View Attack Surface") &&
                      <TextField
                        variant="outlined"
                        placeholder="Search…"
                        // value={searchText}
                        onChange={(e) => setSearch(e.target.value)}
                        className='searh-text-field attack-surface-search'
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  </div>
                </div>

                {checkPermission("View Attack Surface") &&
                  <TableContainer component={Paper}>
                    <Table style={{ width: "100%;" }}>
                      <TableHead>
                        <TableRow className="table-row">
                          <TableCell className="equal-width pl-40">#</TableCell>
                          <TableCell className="equal-width">Name</TableCell>
                          <TableCell className="equal-width">Fulfilled</TableCell>
                          <TableCell className="equal-width row-menu"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {!showLoader ? <>{Array.isArray(vendorsData) && vendorsData?.length ? vendorsData
                          .map((row, i) => {
                            const { _id, requestAttackUrl, userFileStatus, logo } = row;
                            return (
                              <TableRow key={i}>
                                <TableCell className="equal-width pl-40">{((page - 1) * (rowsPerPage)) + (i + 1)}</TableCell>
                                <TableCell className="equal-width">
                                  <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                    <span className="logo-image-vendor">
                                      <img className="attack-surface-list-logo" src={logo || ""} alt="Figma" onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = defaultLogo;
                                      }} />
                                    </span>
                                    <span>{requestAttackUrl}</span>
                                  </div>
                                </TableCell>
                                <TableCell className="equal-width">
                                  <span className={`statusClass ${userFileStatus === 0 ? "In.Progress" : "Onboarding"}`}> {userFileStatus === 0
                                    ? "Scanning"
                                    : "Scanned"}</span>
                                </TableCell>
                                <TableCell className="equal-width row-menu" onClick={() => setUserRemoveId(_id)} >
                                  <RowMenu requestAttackUrl={requestAttackUrl} openDeleteModal={() => setOpenUser(true)} status={userFileStatus} />
                                </TableCell>
                              </TableRow>
                            )
                          }) :
                          <>
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                <Box sx={{ textAlign: "center", padding: "16px" }}>
                                  {CustomNoRowsOverlay()}
                                </Box>
                              </TableCell>
                            </TableRow>
                          </>
                        } </> :
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
                             <CircularProgress className="loader-custom-class-new" />
                            </Box>
                          </TableCell>
                       </TableRow>
                       
                        }
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={totalAttack || 0}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>}
              </div>
            </Card>
          </Grid>
        </Grid>
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeLink}
          contentData={ModalContent()}
        />
      </Container>
    </>
  );
}
