import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import colors from "../utils/colorcode.js";
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        className="circularprogress-attack-surface"
        size="10rem"
        thickness={4.4}
        style={{
          zIndex: "9",
          color:
            props.title == "D"
              ? colors.RED
              : props.title == "C"
              ? colors.ORANGE
              : props.title == "B"
              ? colors.YELLOW
              : colors.GREEN,
        }}
        variant="determinate"
        {...props}
      />
      <CircularProgress
        className="bottom-circle-new"
        size="9rem"
        variant="determinate"
        value={100}
        thickness={4.4}
        style={{ color: `${props.title == "D" ? colors.RED:props.title == "C"? colors.ORANGE: props.title == "B"?colors.YELLOW:colors.GREEN}` }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          // className="pro-text-size"
          className={`circular-progress-new-class gradClass ${props.title}`}
        >
          {props.title}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired,
};

export default function CircularProgressBarSimple({ percent, title }) {
  const [progress, setProgress] = React.useState(percent);
  const [progressTitle, setProgressTitle] = React.useState(title);
  return <CircularProgressWithLabel value={progress} title={progressTitle} />;
}
