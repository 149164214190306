// component
import { useSelector } from "react-redux";
import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const  Navigation = () => {

  let selector = useSelector((state) => state.permissionList.permissionList || [] );

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector?.includes(value)) return true;
    else return false;
  }

  const navConfig = [
    {
      title: "Dashboard",
      path: "/dashboard/app",
      icon: icon("dashboard"),
      isVisible: checkPermission("Dashboard")?true:false,
    },
    {
      title: "Ecosystem",
      path: "/dashboard/vendors",
      icon: icon("ecosystem"),
      isVisible: checkPermission("Ecosystem")?true:false,
    },
    {
      title: "Attack Surface",
      path: "/dashboard/attacks",
      icon: icon("attack-surface"),
      isVisible: checkPermission("Attack Surface")?true:false,
    },
    {
      title: "Questionnaire",
      path: "/dashboard/questionnaire-library",
      icon: icon("questionnaire"),
      isVisible: checkPermission("Questionnaire")?true:false,
    },
    {
      title: "Assessment",
      path: "/dashboard/assessment",
      icon: icon("assesment"),
      isVisible: checkPermission("Assessment")?true:false,
    },
    {
      title: "chat",
      path: "/dashboard/clientChat",
      icon: icon("chat"),
      isVisible: checkPermission("Chat")?true:false,
    },
    {
      title: "My Account",
      path: "/dashboard/my-account",
      icon: icon("peoples"),
      isVisible: true,
    },
    {
      title: "Vulnerability",
      path: "/dashboard/vulnerability",
      icon: icon("vulnerability-icon"),
      isVisible: true,
    },
  ];
  const navConfigAdmin = [
    {
      title: "Upload Json",
      path: "/admin/addfile",
      icon: icon("ic_analytics"),
    },
    {
      title: "All Ecosystems",
      path: "/admin/vendors",
      icon: icon("peoples"),
    },
    {
      title: "All Attack",
      path: "/admin/attacks",
      icon: icon("attack-icon"),
    },
    {
      title: "All Requests",
      path: "/admin/requests",
      icon: icon("ic_file"),
    },
    {
      title: "All Attack Requests",
      path: "/admin/all-attack-requests",
      icon: icon("attack-icon"),
    },
    {
      title: "Questionnaire Import",
      path: "/admin/questionnaire-import",
      icon: icon("questionnaire-line"),
    },
    {
      title: "Clients Management",
      path: "/admin/clients-management",
      icon: icon("client-management"),
    },
    {
      title: "Send Notifications",
      path: "/admin/notification",
      icon: icon("bell-icon"),
    },
  ];

  const navConfigVendor = [
    {
      title: "Assessments",
      path: "/vendor/vendorAssessment",
      icon: icon("ic_file"),
    },
    {
      title: "Remediation",
      path: "/vendor/vendorRemediation",
      icon: icon("Group1810"),
    },
    {
      title: "Compliance",
      path: "compliance",
      icon: icon("ic_analytics"),
    },
    {
      title: "Documents",
      path: "documents",
      icon: icon("document"),
    },
    {
      title: "Chat",
      path: "vendorChat",
      icon: icon("chat-icon"),
    },
  ];

  return {
    navConfig,
    navConfigVendor,
    navConfigAdmin,
  };
};

export default Navigation;
