import * as React from "react";
import Drawer from "@mui/material/Drawer";
import CriticalImg from "../../assets/critical-image.svg";
import LowImage from '../../assets/low-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import HighImage from '../../assets/high-img.svg';
import redirectIcon from "../../assets/redirect-icon.svg";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

function CveSideBar({
  handleClose,
  open,
  cveData
}
) {
  const seveirtyIssue = () => {
    let image;
    const severity = cveData?.severity
    if (severity === 'low')
      image = LowImage
    if (severity === 'medium')
      image = MediumImage
    if (severity === 'critical')
      image = CriticalImg
    else if (severity === 'high')
      image = HighImage;  // You need to define this image or handle it
    return (<span>
      {image ? <img src={image} alt='icon'></img> : "-"}
    </span>)
  }
  const DrawerList = (
    <Box
      sx={{ width: 800 }}
      className="drawer-padding"
      role="presentation"
    >
      <TableContainer sx={{ p: 1 }} className="Drawer-popup-rightside">
        <Table
          sx={{ minWidth: 100 }}
          aria-label="simple table"
          className="table-attacks"
        >
          <TableHead sx={{ borderRadius: "10px 10px 0 0" }}>
            <TableRow>
              <TableCell>CVE</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Severity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{cveData?.name}</TableCell>
              <TableCell>{cveData?.score}</TableCell>
              <TableCell>{seveirtyIssue()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Technology</div>
        </div>
        <p>{cveData?.ele?.Technology}</p>
      </div>
      <div className="flexi-Cwe">
        <div className="cve-detail">
          <div className="cve-header-common">
            <div>CPE</div>
          </div>
          <p>{cveData?.ele?.CPE}</p>
        </div>
        <div className="cve-detail">
          <div className="cve-header-common">
            <div>CWE</div>
          </div>
          <p>
            {cveData?.ele?.CWE}
          </p>
        </div>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Description</div>
        </div>
        <p>{cveData?.ele?.Desc}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Remediation</div>
        </div>
        <p>{cveData?.ele?.Remediation}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Affected URLs</div>
        </div>
        <p><a style={{color:"white"}}
          href={
            cveData?.affectedUrl?.startsWith("http://") || cveData?.affectedUrl?.startsWith("https://")
              ? cveData.affectedUrl
              : `http://${cveData?.affectedUrl}`
          }
          target="_blank" 
          rel="noopener noreferrer" 
        >
          {cveData?.affectedUrl}
          <img src={redirectIcon} alt='icon'></img>
        </a></p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Advisory Links </div>
        </div>
        {cveData?.ele?.Links?.map((link, index) => (
          <p key={index} style={{ display: 'flex'}}>
            #{index + 1}.{"    "}
            <a href={link.startsWith("http://") || link.startsWith("https://")
              ? link : `http://${link}`
            }
          target="_blank" 
          rel="noopener noreferrer"  style={{ marginLeft: '30px', display: 'flex' ,color:"white", flexDirection: 'row', flexWrap: 'wrap',marginRight:'2%'}}>
              {link}
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))}
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}
        ModalProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default CveSideBar;
