import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import Loader from '../../Loader';
import { useSelector, useDispatch } from "react-redux";
import { addPermissionList } from '../../redux/permissionListSlice.js';
import { GetPermissionList } from '../../utils/getPermissionList.js';
import { GetVendorJsonList } from '../../utils/getVendorJsonList.js';
import { GetSubscriptionDetails } from '../../utils/getSubscriptionDetails.js';
import { addSubscriptionDetails } from '../../redux/subscriptionDetailsSlice.js';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
// ----------------------------------------------------------------------

export default function DashboardLayout({ toggleTheme, theme, isShowSearch }) {
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const dispatch = useDispatch()
  let selector = useSelector((state) => state.permissionList.permissionList);
  let vendorsJson = useSelector((state) => state.vendorsJson.vendorsJson);
  let subscriptionDetails = useSelector((state) => state.subscriptionDetails.subscriptionDetails);
  const test = async () => {
    if (!selector?.length && localStorage.getItem("icu")) {
      let permissionList = await GetPermissionList()
      if (!permissionList.error) dispatch(addPermissionList(permissionList.data))
    }
  }
  useEffect(() => {
    test()
  }, [])

  // const getVendorJsonList = async () => {
  //   let vendorList = await GetVendorJsonList()
  //   if (!vendorList.error) dispatch(addVendorJsonList(vendorList.data))
  // }

  const getSubscriptionDetails = async () => {
    let subscriptions = await GetSubscriptionDetails()
    if (!subscriptions.error) dispatch(addSubscriptionDetails(subscriptions.data))
  }

  useEffect(() => {
    if (localStorage.getItem("roleId") === "1" && (!vendorsJson || vendorsJson.length === 0)) {
      dispatch(GetVendorJsonList());

    }
    if (localStorage.getItem("roleId") === "1" && (!subscriptionDetails)) {
      getSubscriptionDetails();
    }
  }, [])

  return (
    <StyledRoot>
      <Loader show={isSearching} />
      <Header onOpenNav={() => setOpen(true)} isShowSearch={isShowSearch} setIsSearching={setIsSearching} />

      <Nav openNav={open} toggleTheme={() => toggleTheme()} onCloseNav={() => setOpen(false)} />

      <Main className={(theme === 'Dark') ? 'dark-mode-class' : 'light-mode-class padding-topheader'}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
