import React from "react";
import CircularProgressBarDividedIcon from "./CircularProgressBarDividedIcon";

export default function CircularProgressDividedIcon({ percent, title, attackTittle  }) {
  return (
    <>
      {(attackTittle) ? (
           <div className="attack-box">
             <h2 className="attack-tittle">{attackTittle}</h2>
              <CircularProgressBarDividedIcon percent={percent} />
           </div>
       ): (
        <div className="circular-progress-security">
           <div className="progress-part">
              <h2>{title}</h2>
              <CircularProgressBarDividedIcon percent={percent} />
             </div>
        </div>
      )}

    </>
  );
}
