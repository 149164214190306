import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  FormControl,
  Container,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import EyeMenu from "../../assets/eye-menu.svg";
import Trash from "../../assets/trahs.svg";
import Minus from "../../assets/icon-minus.svg";
import Plus from "../../assets/icon-plus.svg";
import { useNavigate } from 'react-router-dom';
import { CustomNoRowsOverlay } from "../Assessment.js";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";




// Menu component for the three-dot menu
function RowMenu({ data, handleRemove, handleView }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className="three-dots-btn">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="rowmenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleView} className="border-bot-line">
          {" "}
          <img src={EyeMenu} /> View
        </MenuItem>
        {/* <MenuItem onClick={handleRemove}>
          {" "}
          <img src={Trash} /> Remove
        </MenuItem> */}
      </Menu>
    </>
  );
}



function VulnerabilityManagementIssue() {
  const [activeTab1, setActiveTab1] = useState();
  const [activeTab2, setActiveTab2] = useState();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [issuesListData, setIssuesListData] = useState([]);
  const [vendorNameList, setVendorNameList] = useState([]);
  const [issuesNameList, setIssuesNameList] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]); // State for selected vendors
  const [selectedIssues, setSelectedIssues] = useState([]);   // State for selected issues
  // Fetch Vendor Json from Redus store 
  let { vendorsJson, loading } = useSelector((state) => state.vendorsJson);

  // function for calling the api and store data in redux  
  // const getVendorJsonList = async() => {
  //   let vendorList = await GetVendorJsonList()
  //   console.log("vendorList" , vendorList); 
  //   if (!vendorList.error) dispatch(addVendorJsonList(vendorList.data))
  // } 

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  // Handle Vendor Checkbox Change
  const handleVendorChange = (vendor) => {
    setSelectedVendors((prevSelected) =>
      prevSelected.includes(vendor)
        ? prevSelected.filter((item) => item !== vendor) // Remove if already selected
        : [...prevSelected, vendor] // Add if not selected
    );
  };

  // Handle Issue Checkbox Change
  const handleIssueChange = (issue) => {
    setSelectedIssues((prevSelected) =>
      prevSelected.includes(issue)
        ? prevSelected.filter((item) => item !== issue) // Remove if already selected
        : [...prevSelected, issue] // Add if not selected
    );
  };

  const columns = [
    { field: "_id", headerName: "#", flex: 0.5, disableColumnMenu: true },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      minwidth: 200,
      disableColumnMenu: true,
    },
    {
      field: "count",
      headerName: "Affected Vendors",
      flex: 1.2,
      minwidth: 150,
      disableColumnMenu: true,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let scoreTag = "-";
        const severity = params.row.severity
        if (severity === 'low')
          scoreTag = "Low";
        if (severity === 'medium')
          scoreTag = "Medium";
        if (severity === 'critical')
          scoreTag = "Critical";
        else if (severity === 'high')
          scoreTag = "High";

        return (
          scoreTag ? (
            <span className={`tagClassapproval blank-white ${scoreTag}`}>
              <span>{scoreTag}</span>
            </span>
          ) : (
            "-"
          )
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minwidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        const formatCategory = (value) => {
          if (value?.toLowerCase() === "dns") {
            return "DNS"; // Make "DNS" fully capitalized
          }
          return value.replace(/\b\w/g, (char) => char.toUpperCase());
        };
        return formatCategory(params.value);
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minwidth: 150,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <RowMenu
            // handleRemove={() => { setOpenRemoveModal(true); setActiveVendorId(params.row?.target); }}
            handleView={() => { handleRowClick(params) }}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    getAllVendorDetailsFromRedux();
    // getAllVendorDetails();
  }, [vendorsJson])

  const getAllVendorDetailsFromRedux = async () => {
    setShowLoader(true);
    try {
      setShowLoader(true);
      // console.log("vendorsJson", vendorsJson)
      await setIssueData(vendorsJson);
      setShowLoader(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };


  function issuesList(name, id, category, severity, view, ele, refs, urls, domain, vendorName) {
    return { name, id, category, severity, view, ele, refs, urls, domain, vendorName };
  };

  // function issuespatchingList(name, category, severity, view, ele, score, domain, vendorName) {
  //   return { name, category, severity, view, ele, score, domain, vendorName };
  // };

  // Get Data from the API 
  // const getAllVendorDetails = async () => {
  //   setShowLoader(true);

  //   try {
  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );
  //     if (refreshToken) {
  //       await GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ALL_VENDORS}`
  //       )
  //         .then(async (resVendors) => {

  //           let arrResponse = resVendors.data.data;
  //           await setIssueData(arrResponse);
  //           setShowLoader(false);
  //         })
  //         .catch((errVendor) => {
  //           enqueueSnackbar(errVendor.message, { variant: "error" });
  //           setShowLoader(false);
  //         });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error.message, { variant: "error" });
  //     setShowLoader(false);
  //   }
  // };

  const setIssueData = async (arrResponse) => {
    const dataMatch = [
      "breaches",
      "dns",
      "encryption",
      "misconfiguration",
      // "patching",
    ];
    let issuesRows = [];
    let vendorList = [];
    const issueNameSet = new Set();

    // Iterating the vendors json 
    arrResponse.map(vendor => {
      const vendorInfo = vendor?.vendorDetail?.vendorInfo;
      vendorList.push(vendorInfo?.domain.split('.')[0])

      // Mapping all issues of different vendors in a single object 
      Object.entries(vendorInfo).map((key, valuevendorInfo) => {
        if (dataMatch.includes(key[0])) {
          const dataArr = key[1];
          dataArr.map((value) => {
            if (key[0] === "dns" ||
              key[0] === "misconfiguration" ||
              key[0] === "breaches" ||
              key[0] === "encryption"
            ) {
              if (
                !(
                  !value.id ||
                  value.id === undefined ||
                  value.id === null ||
                  value.id === "" ||
                  value.id === "null" ||
                  value.Issue === null ||
                  value.Issue === "" ||
                  value.Issue === "null")
              ) {
                issuesRows.push(
                  issuesList(
                    !value.Issue ? "-" : value.Issue,
                    value.id,
                    key[0],
                    String(value?.Severity)
                      .replace(/<[^>]*>/g, "")
                      .replaceAll('"', "")
                      .trim()
                      .toLowerCase(),
                    "View",
                    value,
                    value?.Reference,
                    value?.URL || [],
                    vendor.requestUrl,
                    vendorInfo?.domain.split('.')[0]
                  )
                );
              }
            }
          });
        }


      });
      setVendorNameList(vendorList);

      // Reducing repeated Issue by id and adding count of each issue 
      const groupedData = issuesRows.reduce((acc, item) => {
        const existingItem = acc.find(entry => entry.id === item.id);
        // Vendor Info structure
        const vendorInfoEntry = {
          AffectedURLs: item?.urls,
          domain: item.domain,
          Source: item.ele.Source,
          vendorName: item.vendorName
        };

        //IF Issue ID already exists 
        if (existingItem) {
          existingItem.count += 1;
          const isVendorInfoExists = existingItem.vendorInfo?.some(
            (info) =>
              info.domains === vendorInfoEntry.domain &&
              info.AffectedURLs.includes(item.AffectedURL)
          );

          // Issue ID exists, but vendorinfo does not exist then add 
          if (!isVendorInfoExists) {
            existingItem.vendorInfo.push(vendorInfoEntry);
          }

        } else {
          issueNameSet.add(item.name);
          // Add the new object and initialize count
          acc.push({
            // ...item,
            count: 1,
            category: item?.category,
            ele: item.ele,
            id: item.id,
            name: item.name,
            refs: item.refs,
            severity: item.severity,
            view: item.view,
            vendorInfo: [vendorInfoEntry]
          });
        }
        return acc;
      }, []);

      setIssuesListData(groupedData);
      setIssuesNameList(Array.from(issueNameSet.values()));
      // console.log(" issuesListData", issuesListData)
    })
  }

  const filteredRows =
    ((!issuesListData || !issuesListData.length) ? [] :
      issuesListData
        ?.filter((issue) =>
          !selectedVendors || selectedVendors.length === 0
            ? true // Allow all issues if no vendors are selected
            : issue.vendorInfo.some((vendor) =>
              selectedVendors.includes(vendor.vendorName)
            )
        )
        ?.filter((issue) =>
          !selectedIssues || selectedIssues.length === 0
            ? true // Allow all issues if no issues are selected
            :
            selectedIssues.includes(issue.name)
        )
        ?.map((val, i) => ({
          ...val,
          _id: i + 1,
        }))
    );

  const handleRowClick = (params) => {
    console.log(params.row)
    navigate("/dashboard/vulnerability-detail", { state: { issueData: params?.row } });
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  return (
    <div className="filter-divide-two">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} lg={9} style={{ paddingTop: "0px" }}>
          <div className="cover-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <FormControl
                className="select-entries"
                variant="outlined"
                size="small"
              >
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) =>
                    handlePaginationModelChange({
                      page: 0,
                      pageSize: e.target.value,
                    })
                  }
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              </FormControl>
              <span className="entries-per">entries per page</span>
            </div>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={(row) => row._id} // Use the custom ID for each row
              loading={loading || showLoader}
              rowHeight={70}
              style={{ cursor: "pointer" }}
              onRowClick={handleRowClick}
              paginationMode="client"
              pageSizeOptions={[5, 10, 25]}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              autoHeight
              className='datagrid-table'
              // rowCount={rowCount}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                // loadingOverlay: DataGridLoader,
              }}
            // filterMode="server"
            // onFilterModelChange={onFilterChange}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} lg={3} style={{ paddingTop: "0px" }}>
          <div className="filter-right">
            <h3>Filters</h3>
            <div className="accordion-item">
              <h4
                onClick={() => setActiveTab1(!activeTab1)}
                className="accordion-header"
              >
                Vendor
                <span className="accordion-icon">
                  <img
                    src={activeTab1 ? Minus : Plus}
                    alt="toggle-icon"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  />
                </span>
              </h4>
              {activeTab1 && (
                <div className="accordion-content filter">
                  <div className="bg-backgrounds autoscroll" style={{ "maxHeight": "135px" }}>
                    {vendorNameList?.map((vendor) => (
                      <div className="inline-form-check checkbox-outline" key={vendor}>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`flexCheckDefault-${vendor}`}
                            checked={selectedVendors?.includes(vendor)}
                            onChange={() => handleVendorChange(vendor)}
                          />
                          <span className="checkmark"></span>
                          <span className="assessment-datacheck">{vendor?.replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <h4
                onClick={() => setActiveTab2(!activeTab2)}
                className="accordion-header"
              >
                Issue Name
                <span className="accordion-icon">
                  <img
                    src={activeTab2 === "tab2" ? Minus : Plus}
                    alt="toggle-icon"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  />
                </span>
              </h4>
              {activeTab2 && (
                <div className="accordion-content filter">
                  <div className="bg-backgrounds autoscroll" style={{ "maxHeight": "150px" }}>
                    {issuesNameList?.map((issue) => (
                      <div className="inline-form-check checkbox-outline" key={issue}>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`flexCheckDefault-${issue}`}
                            checked={selectedIssues?.includes(issue)}
                            onChange={() => handleIssueChange(issue)}
                          />
                          <span className="checkmark"></span>
                          <span className="assessment-datacheck">{issue?.replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default VulnerabilityManagementIssue;
