import React, { useEffect, useRef, useState } from "react";
import Sunburst from "sunburst-chart";
import { industryColors } from "../components/constant/industryConstant";




const SunburstChart = ({ data }) => {
  const defaultColor = "#150D43";
  const transformData = (data) => {
    return {
      name: "Third Party Risk",
      labelOrientation: "angular",
      color: { defaultColor }, // Default color
      children: data?.map((industryObj) => ({
        name: industryObj.industry,
        labelOrientation: "radial",
        color: industryColors[industryObj.industry] || { defaultColor },
        children: industryObj.subIndustries.map((subIndustryObj) => ({
          name: subIndustryObj.subIndustry,
          labelOrientation: "radial",
          color: industryColors[subIndustryObj.subIndustry] || { defaultColor },
          children: subIndustryObj.vendors.map((vendor) => ({
            name: vendor,
            labelOrientation: "radial",
            value: 1, // Each vendor gets a value (count)
          })),
        })),
      })),
    };
  };

  const chartContainerRef = useRef(null);
  useEffect(() => {
    const myData = transformData(data);
    const myChart = Sunburst();
    const approximateCharWidth = 7;
    myChart
      .data(myData)
      .radiusScaleExponent(1.6)
      .size("value")
      .maxLevels(3)
      .showLabels(true)
      // .labelOrientation((d) => d.labelOrientation ||  "radial" )
      .labelOrientation("auto")
      .label((d) => (d.name.length > 10 ? `${d.name.slice(0, 10)}...` : d.name))
      .handleNonFittingLabel((label, node) => {
        const maxRadius = node.radius; // Get the radius of the current node
        const availableWidth = 0.7 * maxRadius; // Calculate 70% of the radius for text space
        const approximateCharWidth = 8; // Average character width in pixels
        const maxCharacters = Math.floor(availableWidth / approximateCharWidth); // Determine max characters fitting in space
      
        if (label.length > maxCharacters) {
          return label.slice(0, maxCharacters) + "..."; // Truncate with ellipsis
        }
        return label; // Return full label if it fits
      })
      .color((d) => d.color || { defaultColor })

    // .color(d => colorScale(d.name)); 
    // .color((multicolor) => {
    //   switch (multicolor.name) {
    //     case "Retail and E-commerce":
    //       return "#3066AD";
    //     case "Healthcare":
    //       return "#4840E1";
    //     case "Hospitality":
    //       return "#269AD0";
    //     case "Telemedicine":
    //       return "#7A75D1";
    //     case "Education":
    //       return "#327AAA";
    //     case "Technology":
    //       return "#2B8793";
    //     case "FinTech":
    //       return "#86639F";
    //     case "Data Science":
    //       return "#798CAB";
    //     case "Robotics":
    //       return "#30559F";
    //     case "HealthTech":
    //       return "#1F6A72";
    //     case "EdTech":
    //       return "#38253A";
    //     case "EdTech":
    //       return "#38253A";
    //     case "Teleconsultation":
    //       return "#7C76E2";
    //     case "Telemonitoring":
    //       return "#7C76E2";
    //     case "Teletherapy":
    //       return "#7C76E2";
    //     case "Finance":
    //       return "#5A55B9";
    //     case "Insurance":
    //       return "#D060A5";
    //     case "Banking":
    //       return "#D06062";
    //     case "E-commerce":
    //       return "#445AFF91";
    //     case "Specialty Retail":
    //       return "#4A7FB7";
    //     case "Luxury Retail":
    //       return "#4A7FB7";
    //     case "Beverage Retail":
    //       return "#1D6F9D";
    //     case "Apparel Retail":
    //       return "#006F8E";
    //     case "Wellness Retail":
    //       return "#60A0D1";
    //     case "K-12 Education":
    //       return "#285F6A";
    //     case "Higher Education":
    //       return "#285F6A";
    //     case "Corporate Training":
    //       return "#63A776";
    //     case "Resorts":
    //       return "#39ADE3";
    //     case "Hotels":
    //       return "#39ADE3";
    //     case "Dental Care":
    //       return "#3E35DB";
    //     case "Palliative Care":
    //       return "#3E35DB";
    //     case "Nutraceuticals":
    //       return "#3E35DB";
    //     case "Pharmaceuticals":
    //       return "#3E35DB";
    //     case "Lorum ipson":
    //       return "#3E35DB";
    //     case "Health IT":
    //       return "#3E35DB";
    //     case "Physical Therapy":
    //       return "#3E35DB";
    //     case "Medical Devices":
    //       return "#3E35DB";
    //     case "Online Education":
    //       return "#236D8E";
    //     default:
    //       return {defaultColor};
    //   }
    // })
    myChart(chartContainerRef.current);

    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.innerHTML = "";
      }
    };
  }, [data]);

  return <div ref={chartContainerRef} className="sunburst-container"></div>;
};

export default SunburstChart;
