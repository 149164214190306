import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container,Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
import { ResetPasswordForm } from '../sections/auth/resetPassword';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const mdUp = useResponsive('up', 'md');
  const params = useParams();  

  return (
    <>
      <Helmet>
        <title> Reset | Genesis Platform  </title>
      </Helmet>
      <div className="login-screen">
       <Container maxWidth={false} className="login-right-padding">
         <Grid container spacing={3}>
           <Grid item xs={12} md={4} lg={4}>
            <StyledRoot>
              <StyledContent>                 
               <ResetPasswordForm urlToken={params}  />
                  </StyledContent>
                 </StyledRoot>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <div className="bg-login-new">
                  <div className="fit-login-area">
                    <h2>We are glad to see you again!</h2>
                    <h1>Empowering You to Manage Third-Party Risks with Confidence</h1>
                  </div>
                </div>
              </Grid>
           </Grid>
        </Container>
      </div>
    </>
  );
}
