import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Container,
  TextareaAutosize,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NewHeader from "../../../src/layouts/dashboard/header/NewHeader";
import { useLocation } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest";
import GetRequest from "../../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import { formateDate, getTwoDateDifference } from "../../utils/formatTime";
import Breadcrumb from "./Breadcrumb";
import { update } from "lodash";
function RemediationDetails() {

  const location = useLocation();
  const remediationInfo = location.state?.Data ?? 0;
  const panel = location.state?.panel;
  const tabValue = location.state?.tabValue;
  const [showLoader, setShowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [remediationDetail, setRemediationDetail] = useState();
  const [remediationComments, setRemediationComments] = useState();
  const [comment, setComment] = useState();
  const [url, setUrl] = useState();
  const role = localStorage.getItem("roleId");
  const [VendorName, setVendorName] = useState();
  const handleChange = (e) => {
    setComment(e.target.value); // Update state with the textarea value
  };

  useEffect(() => {
    getRemediationDetail()
    getRemediationCommentS()
  }, [])

  const updateStatusofRemedition = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_REMEDIATION_STATUS}?remediationId=${remediationInfo._id}`)
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setComment('');
            getRemediationCommentS();
            getRemediationDetail();
            setShowLoader(false);

          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  const submitComment = async () => {
    if (!comment) {
      enqueueSnackbar('Comment is required', { variant: 'error' });
      return;
    }
    let payload = {
      remediationId: remediationInfo._id,
      comment: comment,
      "toUserId": "67077e460df0312bb68131a6",
      to_email: remediationDetail.email
    }
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_GETREMEDIATION_SAVE_COMMENT}`,
          payload
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setComment('');
            getRemediationCommentS();
            setShowLoader(false);

          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }

  }

  const getRemediationCommentS = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_GETREMEDIATION_COMMENT_LIST}?remediationId=${remediationInfo._id}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setRemediationComments(data)
            setShowLoader(false);

          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  const getRemediationDetail = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_GETREMEDIATION_DETAIL}?remediationId=${remediationInfo._id}`,
          {}
        )
          .then((res) => {
            let data = res.data?.data;
            setRemediationDetail(data)
            setUrl(data.url);
            const name=(data?.url).split('.')[0]
            setVendorName(name.charAt(0).toUpperCase() + name.slice(1));
            setShowLoader(false);

          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }
  const breadcrumbsforClient = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: `${VendorName}`, path: `/dashboard/vendorDetails/${url}`, mainTabValue: 3 },
    { label: 'Remediation Details' }
  ];
  const breadcrumbsForVendor = [
    { label: 'Remediation', path: '/vendor/vendorRemediation', mainTabValue: tabValue },
    { label: 'Details' },
  ];

  const Risk = remediationDetail?.remediationType === 'forIssue' 
  ? remediationDetail?.issueRemediationPlan?.severity?.charAt(0).toUpperCase() + remediationDetail?.issueRemediationPlan?.severity?.slice(1).toLowerCase() ?? '--' 
  : remediationDetail?.remediationPlan?.que?.riskMapping?.charAt(0).toUpperCase() + remediationDetail?.remediationPlan?.que?.riskMapping?.slice(1).toLowerCase();
  const Status  = remediationDetail?.isPending ? 'Pending' : 'Compeleted'; 

  

  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        {panel !== 'vendor' ? <NewHeader titleName="Security Profile" />
          : <NewHeader titleName="Remediation" />
        }
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <p>
                  {!panel ? <Breadcrumb crumbs={breadcrumbsforClient} /> :
                    <Breadcrumb crumbs={breadcrumbsForVendor} />
                  }
                </p>
                <h3>Remediation Details</h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="remeidation-detail-box">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <div className="id-detail">
                    <p>ID:</p>
                    <span>{remediationDetail?.uniqueId ?? '--'}</span>
                  </div>
                  <div className="id-detail">
                    <p>Summary: </p>
                    <span>{remediationDetail?.remediationType == 'forIssue' ? remediationDetail?.issueRemediationPlan?.issueName ?? '--' : remediationDetail?.remediationPlan?.issueName ?? '--'}</span>
                  </div>
                  <div className="id-detail">
                    <p>Risk: </p>
              
                    <span><div className={`tagClass ${Risk}`}>{Risk}</div></span>
                  </div>
                  <div className="id-detail">
                    <p>Status: </p>
                    <span><div className={`tagClass ${Status}`}>{Status}</div></span>
                    {/* <span><div className="statusClass Extended_Deadline risk-critical">{remediationDetail?.isPending ? 'Pending' : 'Compeleted'}</div></span> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <div className="remediation-discription-right">
                    <h3>Description</h3>
                    <p>
                      {remediationDetail?.remediationType == 'forIssue' ? remediationDetail?.issueRemediationPlan?.description ?? '--' : remediationDetail?.remediationPlan?.objective ?? '--'}
                    </p>
                  </div>
                  <div className="flexie-age">
                    <div className="date-created-line">
                      <p>Date Created</p>
                      <span>{formateDate(remediationDetail?.createdDate)}</span>
                    </div>
                    <div className="date-created-line">
                      <p>Age</p>
                      <span>{getTwoDateDifference(remediationDetail?.createdDate, new Date().getTime() / 1000)} days</span>
                    </div>
                  </div>
                  <div className="flexie-age top-space">
                    <div className="date-created-line">
                      <p>Due Date</p>
                      <span>{formateDate(remediationDetail?.deadline)}</span>
                    </div>

                    <div className="date-created-line">
                      <p>Countdown</p>
                      <span>{getTwoDateDifference(new Date().getTime() / 1000, remediationDetail?.deadline)} days left</span>
                    </div>
                  </div>

                  {(role==="3" && remediationDetail?.isPending===true)?
                    <div>
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        component="button"
                        type=""
                        className="submit-btn-comment"
                        onClick={updateStatusofRemedition}
                      >Mark Remediation as Done</Button>
                    </div>
                    :null
                  }


                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="comments-part">
                    <h3>Comment</h3>
                    <TextareaAutosize
                      fullWidth
                      aria-label="empty textarea"
                      placeholder="Type your Comment Here........"
                      minRows={4}
                      className="textarea-comment"
                      onChange={handleChange}
                      value={comment}
                    />
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      component="button"
                      type=""
                      className="submit-btn-comment"
                      onClick={submitComment}
                    >Submit Your Comment</Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {remediationComments?.length ? remediationComments.map((item, index) => (
                    <div className="comment-box-section-bottom">
                      <div className="profile-picture-img">
                        <img
                          style={{
                            width: '70px',
                            height: '70px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                          src={
                            item.from_user.profilePic
                              ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${item?.from_user.profilePic}`
                              : `/assets/images/avatars/user.png`
                             } onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `/assets/images/avatars/user.png`;
                          }}alt="icon" />
                        {/* <IconButton
                        className='profile-bg-grey'
                          sx={{
                            p: 0,
                            ...(open && {
                              '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                              },
                            }),
                          }}
                          title="Profile Setting"
                        >
                          <Avatar src={ (!item?.from_user.profilePic) ? '/assets/images/avatars/user.png' :`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${item?.from_user.profilePic}`} alt="photoURL" />
                   
                        </IconButton> */}
                      </div>
                      <div className="comments-seprate" style={{ width: '100%' }}>
                        <div className="name-of-comment"> <h3>{item?.from_user.firstName} {item?.from_user.lastName}</h3> <span>{item.time_ago}</span></div>
                        <p>{item.comment}</p>
                      </div>
                    </div>
                  )) : <h3 className="no-comment-found-text">No Comment Found!</h3>}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default RemediationDetails;
