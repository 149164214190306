import React from "react";
import { Box, Grid } from "@mui/material";
import "./css/heatmap.css"; // Import the CSS file

const Heatmap = ({data}) => {
  return (
    <Box className="heatmap-container">
      <Grid container spacing={0} className="contain-box">
        {/* Row 1 */}
        <span className="critical">Critical</span>
        <Grid item xs={3} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["critical-low"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["critical-medium"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["critical-high"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["critical-critical"]}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 2 */}
        <span className="critical">High</span>
        <Grid item xs={3} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["high-low"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["high-medium"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["high-high"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="critical-shadow-background">
          <Box className={`heatmap-box heatmap-box-critical`}>Critical <span>{data["high-critical"]}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 3 */}
        <span className="critical">Medium</span>
        <Grid item xs={3} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["medium-low"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["medium-medium"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["medium-high"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="high-shadow-background">
          <Box className={`heatmap-box heatmap-box-high`}>High <span>{data["medium-critical"]}</span></Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="contain-box">
        {/* Row 4 */}
        <span className="critical">Low</span>
        <Grid item xs={3} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["low-low"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="green-shadow-background">
          <Box className={`heatmap-box heatmap-box-low`}>Low <span>{data["low-medium"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["low-high"]}</span></Box>
        </Grid>
        <Grid item xs={3} className="yellow-shadow-background">
          <Box className={`heatmap-box heatmap-box-medium`}>Medium <span>{data["low-critical"]}</span></Box>
        </Grid>
      </Grid>
      <div className="container-flex-low-high">
        <span>Low</span>
        <span>Medium</span>
        <span>High</span>
        <span>Critical</span>
      </div>
    </Box>
  );
};

export default Heatmap;