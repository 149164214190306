import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import unreadMessagesReducer from "./unreadMessagesSlice";
import activeTabReducer from "./activeTabSlice";
import permissionListReducer from './permissionListSlice';
import vendorJsonListReducer from './vendorsJsonSlice';
import subscriptionDetailsReducer from './subscriptionDetailsSlice';
const store = configureStore({
  reducer: {
    theme: themeReducer,
    unreadMessages: unreadMessagesReducer,
    activeTab: activeTabReducer,
    permissionList: permissionListReducer,
    vendorsJson: vendorJsonListReducer,
    subscriptionDetails: subscriptionDetailsReducer
  },
});

export default store;