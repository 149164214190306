import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box, Button, Card, Checkbox, CircularProgress, Container,
  Grid, IconButton, InputAdornment, Menu, MenuItem, Tab, Tabs, TextField, Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserProfile from "../assets/user-profile.svg";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Iconify from "../components/iconify"
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import ModalDialog from "./ModalDialog";
import VendorUploadModal from "./VendorUploadModal";
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import PutRequest from '../components/apiConnections/putRequest';
import ClientUserResetPassword from '../components/modal/ClientUserResetPassword';
import DatagridVendor from "./DatagridVendor";
import Subscriptions from "./add-client/subscriptions";
import SearchIcon from '../assets/searchico.svg';
import moment from "moment";
import { CustomNoRowsOverlay } from "./Assessment";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function DataGridLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const ClientsDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [userRows, setUserRows] = useState([])
  const [showUpdateSubscriptionModal, setShowUpdateSubscriptionModal] = useState(false)
  const [updateSubscriptionLoader, setUpdateSubscriptionLoader] = useState(false)
  const [bulkSubmitLoader, setBulkSubmitLoader] = useState(false)
  const [userColumns, setUserColumns] = useState([
    { field: "id", headerName: "No.", editable: false, maxWidth: 30 },
    {
      field: "logo",
      headerName: "Profile",
      maxWidth: 80,
      flex: 1,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <img
              src={
                params.row?.profilePic
                  ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${params.row?.profilePic}`
                  : UserProfile
              }
              // src={UserProfile}
              alt="logo"
              onError={(e) => e.target.src = UserProfile}
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      minWidth: 230,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => params.value || "-"
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "twoFAMethod",
      headerName: "2FA Method",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${status === 0 ? "#f9a825" : "green"}`,
            }}
          >
            {params.row.status === 1 ? "Active" : "In-Active"}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 20,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleUserActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ])

  const [subscriptionsData, setSubscriptionsData] = useState({
    deadline: null,
    numberOfVendors: 0,
    numberOfUsers: 0,
    numberOfWebsites: 0,
    isQuestionniareEnabled: 0,
    numberOfQuestionniare: 0,
    isAttackRequestEnabled: 0,
    numberOfAttackRequests: 0,
    isAssessmentEnabled: 0,
    numberOfAssessments: 0,
  });
  const [selectedUserRow, setSelectedUserRow] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] = useState(false);
  const [showVendorUploadModal, setShowVendorUploadModal] = useState(false);
  const [clientDetail, setClientDetail] = useState({
    company: "",
    name: "",
    logo: null
  })
  const [userLoader, setUserLoader] = useState(false)
  const [userSearch, setUserSearch] = useState(null)
  const [userPaginationModel, setUserPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [userRowCount, setUserRowCount] = useState(0);
  const [changeUserStatusLoader, SetChangeUserStatusLoader] = useState(false)
  const [showUserResetPasswordModal, setShowUserResetPasswordModal] = useState(false)
  const [vendorCount, setVendorCount] = useState(0)
  const [updateSubscriptionValues, setUpdateSubscriptionValues] = useState({})
  useEffect(() => {
    getClientData();
    if (location.state.tab == 1) setTabValue(1)
  }, [])
  useEffect(() => {
    if (clientDetail.email) getVendorCount()
  }, [clientDetail])
  useEffect(() => {
    getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
  }, [userPaginationModel])

  useEffect(() => {
    let time = setTimeout(() => {
      if (userSearch !== null) getClientUsers(1, userPaginationModel.pageSize);
    }, 500)
    return () => clearTimeout(time)
  }, [userSearch])

  const getClientData = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_DATA}?clientId=${id}`
        )
          .then((resChart) => {
            let userDetail = resChart.data?.data;
            if (userDetail?._id) {
              let obj = {
                company: userDetail.company,
                name: `${userDetail.firstName} ${userDetail.lastName}`,
                logo: userDetail.profilePic,
                email: userDetail.emailId,
                clientId: id
              };
              if (userDetail.clientDetail) {
                setSubscriptionsData({
                  ...userDetail.clientDetail,
                  deadline: userDetail.clientDetail?.deadline ? moment(userDetail.clientDetail?.deadline).format("YYYY-MM-DD") : null
                })
              } else setSubscriptionsData({ ...subscriptionsData, clientId: id })
              setClientDetail({ ...obj });
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
          });
      } else {
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const getClientUsers = async (page, limit) => {
    setUserLoader(true);
    setUserRows([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_USERS
          }?clientId=${id}&page=${page}&limit=${limit}&search=${userSearch || ""}`
        )
          .then((resChart) => {
            let data = resChart.data.data?.data;
            setUserRowCount(resChart.data.data?.total);
            setUserRows(
              data.length
                ? [
                  ...data?.map((d, i) => ({
                    ...d,
                    id: (page - 1) * limit + (i + 1),
                    name: `${d.firstName} ${d.lastName}`,
                    contact: d.mobile || "---",
                    email: d.emailId,
                    role: d.role
                  })),
                ]
                : []
            );
            setUserLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart?.message || errChart.data?.message, { variant: "error" });
            setUserLoader(false);
          });
      } else {
        setUserLoader(false);
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(error.message || "something went wrong", { variant: "error" });
      setUserLoader(false);
    }
  }

  const getVendorCount = async () => {
    const endpoint = `${process.env.REACT_APP_GET_CLIENT_VENDORS_LIST}/${clientDetail.email}`

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setVendorCount(arrResponse?.total || 0);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserActionClick = (event, row) => {
    setSelectedUserRow(row);
    setAnchorElUser(event.currentTarget);
  };

  const handleUserActionClose = () => {
    setSelectedUserRow(null);
    setAnchorElUser(null);
  };

  const changeUserStatusAPI = async (type) => {
    SetChangeUserStatusLoader(true)
    const payload = {
      clientUserId: selectedUserRow._id,
    };
    if (type === "status") payload.status = selectedUserRow.status ? 0 : 1;
    else if (type === "delete") payload.deletedAt = 1
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PutRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CLIENT_USER_STATUS}`,
          payload,
          {}
        )
          .then((resChart) => {
            SetChangeUserStatusLoader(false);
            setShowDeactivateAccountModal(false);
            setShowDeleteAccountModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
            SetChangeUserStatusLoader(false);
          });
      } else {
        SetChangeUserStatusLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      SetChangeUserStatusLoader(false);
    }
  };

  const ModalContent = (content) => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <h1>Are you sure? </h1>
        <h3 className="send-assessments">
          {content}
        </h3>
      </Typography>
    );
  };
  const handleUserPaginationModelChange = (newPaginationModel) => {
    setUserPaginationModel(newPaginationModel);
  }

  const onUserFilterChange = (value) => {
    console.log("value", value)
    let searchQuery = value.quickFilterValues[0] || ""
    setUserSearch(searchQuery)
  }
  const handleSearch = (e) => {
    setUserSearch(e.target.value)
  }
  const updateSubscription = async (data) => {
    console.log(data)
    setUpdateSubscriptionLoader(true)
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PutRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CLIENT_SUBSCRIPTION}`,
          data,
          {}
        )
          .then((resChart) => {
            setUpdateSubscriptionLoader(false);
            setShowUpdateSubscriptionModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getClientData();
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
            setUpdateSubscriptionLoader(false);
          });
      } else {
        setUpdateSubscriptionLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setUpdateSubscriptionLoader(false);
    }
  }

  const handleIndividualSubmit = async (payload) => {
    // check if this vendor is available for client    
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHECK_IF_VENDOR_EXIST_FOR_CLIENT}?clientEmail=${clientDetail.email}&requestedUrl=${payload.domain}`
        )
          .then((resChart) => {
            let data = resChart.data.data;
            if (data.vendorAlreadyExist) {
              enqueueSnackbar("Vendor already exist", { variant: "warning" });
            } else {
              navigate(`/admin/add-vendor`, {
                state: {
                  domain: payload.domain,
                  vendorStatus: payload.vendorStatus,
                  jsonAvailableData: false,
                  clientEmail: clientDetail.email,
                  clientId: id
                }
              });
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart?.message || errChart.data?.message, { variant: "error" });
            setUserLoader(false);
          });
      } else {
        setUserLoader(false);
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(error.message || "something went wrong", { variant: "error" });
      setUserLoader(false);
    }
  }

  const handleBulkSubmit = async (data) => {
    setBulkSubmitLoader(true)
    let success = false;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_BULK_VENDOR_OF_CLIENT}`,
          {
            clientEmail: clientDetail.email,
            vendorList: data
          },
          {}
        )
          .then((resChart) => {
            if (resChart.data.code === 200) {
              success = true;
              setBulkSubmitLoader(false);
              setShowVendorUploadModal(false);
              getVendorCount();
            }
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
            setBulkSubmitLoader(false);
          });
      } else {
        setBulkSubmitLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setBulkSubmitLoader(false);
    }
    return success;
  }
  return (
    <>
      {/* company Details */}
      <Grid container spacing={0.5} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                src={
                  typeof clientDetail.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${clientDetail?.logo}`
                    : UserProfile
                }
                alt="login"
                onError={(e) => e.target.src = UserProfile}
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              <h3 className="company">{clientDetail.company}</h3>
              <p>
                <PermIdentityIcon />
                {clientDetail.name}
              </p>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "2%" }}
        >
          <Button
            variant="contained"
            className="edit-myaccount-button"
            startIcon={<Iconify icon="material-symbols:arrow-back" />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {tabValue === 0 && (
            <Button
              variant="contained"
              className="edit-myaccount-button"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => navigate("../client-details/add-user", { state: clientDetail })}
            >
              Add User
            </Button>
          )}

          {tabValue === 1 && (
            <Button
              variant="contained"
              className="edit-myaccount-button"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setShowVendorUploadModal(true)}
            >
              Add Vendor
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
        <Box className="add-vendor-tabs" sx={{ mb: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={`Users (${userRowCount})`} {...a11yProps(0)} />
            <Tab label={`Vendors (${vendorCount})`} {...a11yProps(1)} />
            <Tab label={`Subscriptions`} {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Grid>

      {/* Card */}
      <div className="client-users-form">
        <Card sx={{ mt: 1, mb: 5, }}>
          <Container maxWidth={false}>
            <Grid item xs={12} md={12} lg={12}>
              <CustomTabPanel value={tabValue} index={0} sx={{ padding: 0 }}>
                <Box
                  sx={{
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%',
                      overflowX: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%',
                      overflowX: 'auto',
                    },
                    pt: 3
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={userSearch}
                    onChange={handleSearch}
                    className='searh-text-field topsearch-top-0'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <DataGrid
                    rowHeight={70}
                    rows={userRows}
                    columns={userColumns}
                    autosizeOptions={{
                      // columns: ["id", "firstName", "lastName"],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    paginationMode="server"
                    pageSizeOptions={[5, 10, 25]}
                    onPaginationModelChange={handleUserPaginationModelChange}
                    paginationModel={userPaginationModel}
                    rowCount={userRowCount}
                    slots={{
                      toolbar: GridToolbar,
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: DataGridLoader,
                    }}
                    loading={userLoader}
                    filterMode="server"
                    onFilterModelChange={onUserFilterChange}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    autoHeight
                    className='datagrid-table'
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                  />

                  {selectedUserRow && (
                    <Menu
                      id="actions-menu"
                      anchorEl={anchorElUser}
                      open={Boolean(anchorElUser)}
                      onClose={handleUserActionClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem>
                        <Button
                          onClick={() =>
                            navigate(`../client-details/edit-user/${selectedUserRow._id}`, {
                              state: clientDetail,
                            })
                          }
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                          Edit User
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            // setActiveUser(selectedRow.id);
                            setShowUserResetPasswordModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                          Reset Password
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            setShowDeactivateAccountModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                          {selectedUserRow.status ? "Deactivate Account" : "Activate Account"}
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            // setActiveUser(selectedRow.id);
                            setShowDeleteAccountModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                          Remove Account
                        </Button>
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              </CustomTabPanel>
            </Grid>

            <CustomTabPanel value={tabValue} index={1}>
              <Box sx={{ pt: 3 }} >
                <DatagridVendor clientEmail={clientDetail.email} vendorCount={(count) => setVendorCount(count)} updatedCount={vendorCount} />
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={2}>
              <div style={{ maxWidth: "100%" }}>
                <Subscriptions
                  props={{
                    client: subscriptionsData,
                    addClientDetails: (data) => { setUpdateSubscriptionValues(data); setShowUpdateSubscriptionModal(true) },
                  }}
                  origin="clientDetail"
                  loader={updateSubscriptionLoader}
                />
              </div>
            </CustomTabPanel>
          </Container>
        </Card>
      </div>

      <ModalDialog
        handleClose={() => setShowDeleteAccountModal(false)}
        open={showDeleteAccountModal}
        loader={changeUserStatusLoader}
        onSubmit={() => changeUserStatusAPI("delete")}
        contentData={ModalContent("Are you sure you want to remove this item ? This action can't be undone.")}
      />

      <ModalDialog
        handleClose={() => setShowDeactivateAccountModal(false)}
        open={showDeactivateAccountModal}
        loader={changeUserStatusLoader}
        onSubmit={() => changeUserStatusAPI("status")}
        contentData={ModalContent("Are you sure you want to change the status of this item ?")}
      />
      <ClientUserResetPassword
        handleClose={() => setShowUserResetPasswordModal(false)}
        open={showUserResetPasswordModal}
        userDetail={selectedUserRow}
        handleSuccess={() => {
          setShowUserResetPasswordModal(false); getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
        }}
      />
      <VendorUploadModal
        handleClose={() => setShowVendorUploadModal(false)}
        open={showVendorUploadModal}
        // loader={deleteLoader}
        onSubmit={() => console.log("call API")}
        handleIndividualSubmit={handleIndividualSubmit}
        handleBulkSubmit={handleBulkSubmit}
        bulkSubmitLoader={bulkSubmitLoader}
      />
      <ModalDialog
        handleClose={() => setShowUpdateSubscriptionModal(false)}
        open={showUpdateSubscriptionModal}
        onSubmit={() => updateSubscription(updateSubscriptionValues)}
        loader={updateSubscriptionLoader}
        contentData={ModalContent("Are you sure you want to update subscription of client ?")}
      />
    </>
  );
};

export default ClientsDetailPage;
