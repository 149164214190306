import { Box, Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CustomNoRowsOverlay } from "../Assessment.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function ClientRemediation({ remediationListData }) {
    const navigate = useNavigate();
    const [remediationType, setRemediationTypes] = useState([
        {
            key: 'ForIssue',
            value: "Isssues"
        },
        {
            key: 'ForQuestionnaire',
            value: "Failed Controls "
        },
        {
            key: 'ForGaps',
            value: "Gaps "
        }
    ]);
    const [selectedType, setSelectedType] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };
    const handleTypeChange = (event) => {
        console.log(event.target.value)
        setSelectedType(event.target.value)
    }

    // IF remediationType to be fetch from API 
    // useEffect(() => {
    //     const remediationTypeSet = new Set();
    //     remediationListData.map(remed => {
    //         remediationTypeSet.add(remed?.remediationType); 
    //     })
    //     setRemediationTypes(Array.from(remediationTypeSet.values()));
    // }, [remediationListData])

    const filteredRows =
        ((!remediationListData || !remediationListData.length) ? [] :
            remediationListData
                .filter((val, i) =>
                    !selectedType || val.remediationType.toLowerCase() === selectedType.toLowerCase()
                )
                ?.map((val, i) => ({
                    ...val,
                    id: i + 1,
                    _id: val.id,
                    name: `${val?.firstName} ${val?.lastName}`,
                    uniqueId: val.uniqueId || "--"
                }))
        );


    const columns = [
        {
            field: "id",
            headerName: "#",
            flex: 0.3,
            className: "serial-number",
            disableColumnMenu: true,
        },
        {
            field: "uniqueId",
            headerName: "ID",
            flex: 1.6,
            minWidth: 170,
            disableColumnMenu: true,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1.2,
            minWidth: 125,
            disableColumnMenu: true,
        },
        {
            field: "startDate",
            headerName: "Date",
            flex: 1,
            minWidth: 100,
            disableColumnMenu: true,
            valueFormatter: (params) => {
                return moment.unix(params.value).format("DD MMM YYYY");
            }
        },
        {
            field: "isPending",
            headerName: "Status",
            aling: "right",
            headerAlign: "center",
            flex: 0.5,
            minWidth: 100,
            disableColumnMenu: true,
            renderCell: (params) => {
                let status = params.value;
                return (
                    <div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
                        {status === true ?
                            <span className={`statusClass ${status}`}>{'Pending'}</span>
                            :
                            <span className={`statusClass Onboarding`}>{'Done'}</span>
                        }
                    </div>
                )
            },
        }
    ];

    const handleRowClick = (params) => {
        navigate(`/dashboard/remediation-details`, { state: { Data: params.row } })
    };

    return (
        <>
            <Card className="full-coverdesign margin-questionnaire">
                <div className="cover-table">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            top: "4px",
                            left: "5px",

                        }}
                    >
                        <FormControl
                            className="select-entries"
                            variant="outlined"
                            size="small"
                        >
                            <Select
                                value={paginationModel.pageSize}
                                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                        <span className="entries-per">entries per page</span>{" "}
                    </div>
                    <div className='document-buttons'>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id={`doc-type-label`}
                                shrink={selectedType !== ""}
                            >Remediation Type</InputLabel>
                            <Select
                                labelId="remediation-label"
                                id="remediation-label"
                                name="industry"
                                label="remediation-label"
                                value={selectedType}
                                onChange={handleTypeChange}
                                sx={{ height: '40px', padding: '4px' }} // Adjusted height and padding for Select

                            >
                                <MenuItem key="" value="">
                                    All
                                </MenuItem>
                                {remediationType?.length && remediationType?.map((type) => (
                                    <MenuItem key={type.key} value={type.key}>
                                        {type.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="issues-tab-table">
                        <Box
                            sx={{
                                width: "100%",
                                "& .MuiDataGrid-columnHeaders": {
                                    minWidth: "100%",
                                    overflowX: "hidden",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    minWidth: "100%",
                                    overflowX: "auto",
                                },
                            }}
                        >

                            <DataGrid
                                rows={filteredRows}
                                columns={columns}
                                rowHeight={70}
                                onPaginationModelChange={handlePaginationModelChange}
                                paginationModel={paginationModel}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                textAlign: "center",
                                                fontSize: "18px",
                                            }}
                                        >
                                            No data
                                        </Box>
                                    ),
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                    filter: {
                                        filterModel: {
                                            items: [],
                                            quickFilterValues: [],
                                        },
                                    },
                                }}
                                autosizeOptions={{
                                    columns: ["id", "firstName", "lastName"],
                                    includeOutliers: true,
                                    includeHeaders: false,
                                }}
                                pageSizeOptions={[5, 10, 25]}
                                slots={{
                                    toolbar: GridToolbar,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }}
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                disableColumnMenu
                                paginationMode="client"
                                disableSelectionOnClick
                                autoHeight
                                className="datagrid-table click-hover-datagrid"
                                sx={{
                                    "& .MuiDataGrid-columnHeaders": {
                                        minWidth: "100%", // Column headers take up full width
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        minWidth: "100%", // Rows take up full width
                                    },
                                }}
                                onRowClick={handleRowClick}
                            />
                        </Box>
                    </div>
                </div>
            </Card >
        </>
    );
}

export default ClientRemediation;