import React, { useState, useEffect } from "react";
import "./css/VendorInformation.css";
import { Helmet } from "react-helmet-async";
import FormGroup from "@mui/material/FormGroup";
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useNavigate, useParams, Link } from "react-router-dom";
import PostRequest from "../components/apiConnections/postRequest";
import { getLocalStoarge } from "../components/common/allFunctions";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import { useDispatch } from "react-redux";
import {
  Grid,
  Container,
  TextField,
  Button
} from "@mui/material";
import Loader from "../Loader";


export default function UserRemediationInformationPage() {
  const { enqueueSnackbar } = useSnackbar();
  const data = useParams();
  const dispatch = useDispatch();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [deadline, setDeadline] = useState('');
  const [userEmail, setUserEmail] = useState(getLocalStoarge("userEmail"));

  const handleValid = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "deadline") {
      setDeadline(value);
    }
  };


  const do_submit = async (e) => {
    setshowLoader(true);
    e.preventDefault();
    let currentTime = Date.now();
    let todayEpoch = new Date(currentTime).getFullYear() + "-" + ((new Date(currentTime).getMonth() + 1) < 10 ? ("0" + (new Date(currentTime).getMonth() + 1)) : (new Date(currentTime).getMonth() + 1)) + "-" + ((new Date(currentTime).getDate() < 10) ? ("0" + (new Date(currentTime).getDate())) : new Date(currentTime).getDate());
    if (firstName === '' || firstName === undefined || firstName === null || firstName?.trim()?.length < 3 || !(/^[A-Za-z\s]*$/.test(firstName))) {
      enqueueSnackbar('Please enter FirstName (minimum 3 character).', { variant: 'error' });
      setshowLoader(false);
      return false;
    }
    if (lastName === '' || lastName === undefined || lastName === null || lastName?.trim()?.length < 3 || !(/^[A-Za-z\s]*$/.test(lastName))) {
      enqueueSnackbar('Please enter LastName (minimum 3 character).', { variant: 'error' });
      setshowLoader(false);
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      enqueueSnackbar('Please enter valid email address.', { variant: 'error' });
      setshowLoader(false);
      return false;
    }
    if ((!deadline) || deadline === null || deadline === '' || (todayEpoch > deadline) || (deadline.length !== 10)) {
      enqueueSnackbar("Please select future date.", { variant: 'error' });
      setDeadline('');
      setshowLoader(false);
      return false;
    }

    let payload = {
      firstName,
      lastName,
      email,
      deadline,
      url: vendorUrl,
      createdBy: userEmail
    };
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_REMEDIATION_PLAN}`,
        payload
      )
        .then((resLink) => {
          enqueueSnackbar(resLink.data.message, { variant: "success" });
          setshowLoader(false);
          setFirstName('');
          setLastName('');
          setEmail('');
          setDeadline('');
          dispatch(changeEcosystemTab("5"));
          navigate(-1);
        })
        .catch((errLink) => {
          enqueueSnackbar(errLink.message, { variant: "error" });
          setshowLoader(false);
          return false;
        });
    }
  };


  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Remediation | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <div className="vendor-information">
          <h3 class="vendor-information-head"><span>1</span> Vendor Information</h3>
          <h3 className="relation">
            <PermContactCalendarOutlinedIcon></PermContactCalendarOutlinedIcon>Vendor's Contact Person</h3>
          <form onSubmit={(e) => { do_submit(e) }} >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="firstName"
                  value={firstName || ""}
                  placeholder="First Name"
                  // label ="First Name"            
                  onChange={(event) => handleValid(event)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName || ""}
                  onChange={(event) => handleValid(event)}
                // label ="Last Name"                     

                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="email"
                  placeholder="Email"
                  value={email || ""}
                  onChange={(event) => handleValid(event)}
                // label ="Email"                     

                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="deadline"
                  InputLabelProps={{ shrink: true, required: true }}
                  // placeholder= "deadline"
                  type="date"
                  value={deadline || ""}
                  title="Select Date"
                  label="Deadline"
                  onChange={(event) => handleValid(event)}
                  inputProps={{ 
                    min: new Date().toISOString().split('T')[0], 
                    onKeyDown: (e) => e.preventDefault()
                  }}
                />
              </Grid>
            </Grid>
            <FormGroup>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  {/* <div className="line-hr"></div>   */}
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="btn-right ">
                  <Button variant="contained" className="click-to-begin m-l cancel-btn" onClick={() => { navigate(-1); dispatch(changeEcosystemTab("5")) }}>Back </Button>
                  <Button type="submit" variant="contained" className="click-to-begin">
                    Submit
                  </Button>

                </Grid>
              </Grid>
            </FormGroup>
          </form>
        </div>
      </Container>
    </>
  );
}

